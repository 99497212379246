import React, { Component, Fragment } from "react";
import {
  AppBar,
  Button,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { LOGO_VERIFY } from "../../../config/config";
import { ValidateEmail, normalize } from "../../../config/utility";

import { Col, Row } from "react-bootstrap";
import SignatureCanvas from "react-signature-canvas";

import "../ClientRegister/registration_step.css";

import { registerBusinessDetails } from "../../../config/client_api_calls";

import HelperClass from "../../../config/helperClass";
import { tableLoader } from "../../ContentLoaders/table_loader";
import Skeleton from "@material-ui/lab/Skeleton";
import { Helmet, HelmetProvider } from "react-helmet-async";

import MyCustomTextField from "../../../components/InputFields/TextField";
import MyCustomDatePicker from "../../../components/InputFields/BasicDatePicker";
import MyCustomCheckBoxField from "../../../components/InputFields/CheckBox";
import MyCustomButton from "../../../components/InputFields/Button";
import MyCustomRadioField from "../../../components/InputFields/RadioField";

import "../ClientRegister/custom_radio.css";

const auth = new HelperClass();
export class Step2 extends Component {
  constructor(props) {
    console.log("props.jsonData.step_2: ", props.jsonData.step_2);
    super(props);
    this.state = {
      errorMessage: "",
      successMessage: "",
      disabled:
        props.jsonData.step_2.sub_steps[0].button_fields.continue.disabled,
    };
  }

  componentDidMount() {
    const { keys, values } = this.props.values;

    //console.log('componentDidMount', values);

    this.setState({
      [keys.legal_company_name]: values[keys.legal_company_name],
      [keys.date_of_incorporation]: values[keys.date_of_incorporation],
      [keys.other_trade_names]: values[keys.other_trade_names],
      [keys.main_telephone]: values[keys.main_telephone],
      [keys.fax_number]: values[keys.fax_number],
      [keys.website_url]: values[keys.website_url],
      [keys.company_linkedin]: values[keys.company_linkedin],
      [keys.entity_type]: values[keys.entity_type],
      [keys.address_1_address]: values[keys.address_1_address],
      [keys.address_1_address_line_2]: values[keys.address_1_address_line_2],
      [keys.address_1_city]: values[keys.address_1_city],
      [keys.address_1_state]: values[keys.address_1_state],
      [keys.address_1_zip]: values[keys.address_1_zip],
      [keys.address_1_country]: values[keys.address_1_country],
      [keys.address_type]: values[keys.address_type],
      [keys.address_mark_primary]: values[keys.address_mark_primary],
      [keys.address_mark_billing]: values[keys.address_mark_billing],
      [keys.address_2_address]: values[keys.address_2_address],
      [keys.address_2_city]: values[keys.address_2_city],
      [keys.address_2_state]: values[keys.address_2_state],
      [keys.address_2_zip]: values[keys.address_2_zip],
      [keys.address_mark_primary_2]: values[keys.address_mark_primary_2],
      [keys.address_mark_billing_2]: values[keys.address_mark_billing_2],
      [keys.billing_address_address]: values[keys.billing_address_address],
      [keys.billing_address_city]: values[keys.billing_address_city],
      [keys.billing_address_state]: values[keys.billing_address_state],
      [keys.billing_address_zip]: values[keys.billing_address_zip],
      [keys.foreign_entity_international_address]:
        values[keys.foreign_entity_international_address],
      [keys.foreign_entity_country]: values[keys.foreign_entity_country],
    });
  }

  continue = (data) => {
    //e.preventDefault();
    this.props.nextStep(data);
  };
  back = (e) => {
    //e.preventDefault();
    this.props.prevStep();
  };

  submitForms = (event) => {
    event.preventDefault();
    const { jsonData } = this.props;
    const { keys } = this.props.values;

    let legal_company_name_json = {
      required:
        jsonData.step_2.sub_steps[0].input_fields.legal_company_name.required,
      visible:
        jsonData.step_2.sub_steps[0].input_fields.legal_company_name.visible,
    };
    let date_of_incorporation_json = {
      required:
        jsonData.step_2.sub_steps[0].input_fields.date_of_incorporation
          .required,
      visible:
        jsonData.step_2.sub_steps[0].input_fields.date_of_incorporation.visible,
    };
    let other_trade_names_json = {
      required:
        jsonData.step_2.sub_steps[0].input_fields.other_trade_names.required,
      visible:
        jsonData.step_2.sub_steps[0].input_fields.other_trade_names.visible,
    };
    let main_telephone_json = {
      required:
        jsonData.step_2.sub_steps[0].input_fields.main_telephone.required,
      visible: jsonData.step_2.sub_steps[0].input_fields.main_telephone.visible,
    };
    let fax_number_json = {
      required: jsonData.step_2.sub_steps[0].input_fields.fax_number.required,
      visible: jsonData.step_2.sub_steps[0].input_fields.fax_number.visible,
    };
    let website_url_json = {
      required: jsonData.step_2.sub_steps[0].input_fields.website_url.required,
      visible: jsonData.step_2.sub_steps[0].input_fields.website_url.visible,
    };
    let company_linkedin_json = {
      required:
        jsonData.step_2.sub_steps[0].input_fields.company_linkedin.required,
      visible:
        jsonData.step_2.sub_steps[0].input_fields.company_linkedin.visible,
    };
    let entity_type_json = {
      required: jsonData.step_2.sub_steps[0].radio_fields.entity_type.required,
      visible: jsonData.step_2.sub_steps[0].radio_fields.entity_type.visible,
    };
    let address_1_json = {
      required:
        jsonData.step_2.sub_steps[0].input_fields.addresses.address_1.required,
      visible:
        jsonData.step_2.sub_steps[0].input_fields.addresses.address_1.visible,
    };
    let address_1_address_json = {
      required:
        jsonData.step_2.sub_steps[0].input_fields.addresses.address_1.address
          .required,
      visible:
        jsonData.step_2.sub_steps[0].input_fields.addresses.address_1.address
          .visible,
    };
    let address_1_address_line_2_json = {
      required:
        jsonData.step_2.sub_steps[0].input_fields.addresses.address_1
          .address_line_2.required,
      visible:
        jsonData.step_2.sub_steps[0].input_fields.addresses.address_1
          .address_line_2.visible,
    };
    let address_1_city_json = {
      required:
        jsonData.step_2.sub_steps[0].input_fields.addresses.address_1.city
          .required,
      visible:
        jsonData.step_2.sub_steps[0].input_fields.addresses.address_1.city
          .visible,
    };
    let address_1_state_json = {
      required:
        jsonData.step_2.sub_steps[0].input_fields.addresses.address_1.state
          .required,
      visible:
        jsonData.step_2.sub_steps[0].input_fields.addresses.address_1.state
          .visible,
    };
    let address_1_zip_json = {
      required:
        jsonData.step_2.sub_steps[0].input_fields.addresses.address_1.zip
          .required,
      visible:
        jsonData.step_2.sub_steps[0].input_fields.addresses.address_1.zip
          .visible,
    };
    let address_1_country_json = {
      required:
        jsonData.step_2.sub_steps[0].input_fields.addresses.address_1.country
          .required,
      visible:
        jsonData.step_2.sub_steps[0].input_fields.addresses.address_1.country
          .visible,
    };
    let address_1_address_type_json = {
      required:
        jsonData.step_2.sub_steps[0].input_fields.addresses.address_1
          .address_type.required,
      visible:
        jsonData.step_2.sub_steps[0].input_fields.addresses.address_1
          .address_type.visible,
    };
    let address_2_json = {
      required:
        jsonData.step_2.sub_steps[0].input_fields.addresses.address_2.required,
      visible:
        jsonData.step_2.sub_steps[0].input_fields.addresses.address_2.visible,
    };
    let address_2_address_json = {
      required:
        jsonData.step_2.sub_steps[0].input_fields.addresses.address_2.address
          .required,
      visible:
        jsonData.step_2.sub_steps[0].input_fields.addresses.address_2.address
          .visible,
    };
    let address_2_city_json = {
      required:
        jsonData.step_2.sub_steps[0].input_fields.addresses.address_2.city
          .required,
      visible:
        jsonData.step_2.sub_steps[0].input_fields.addresses.address_2.city
          .visible,
    };
    let address_2_state_json = {
      required:
        jsonData.step_2.sub_steps[0].input_fields.addresses.address_2.state
          .required,
      visible:
        jsonData.step_2.sub_steps[0].input_fields.addresses.address_2.state
          .visible,
    };
    let address_2_zip_json = {
      required:
        jsonData.step_2.sub_steps[0].input_fields.addresses.address_2.zip
          .required,
      visible:
        jsonData.step_2.sub_steps[0].input_fields.addresses.address_2.zip
          .visible,
    };
    let billing_address_json = {
      required:
        jsonData.step_2.sub_steps[0].input_fields.addresses.billing_address
          .required,
      visible:
        jsonData.step_2.sub_steps[0].input_fields.addresses.billing_address
          .visible,
    };
    let billing_address_address_json = {
      required:
        jsonData.step_2.sub_steps[0].input_fields.addresses.billing_address
          .address.required,
      visible:
        jsonData.step_2.sub_steps[0].input_fields.addresses.billing_address
          .address.visible,
    };
    let billing_address_city_json = {
      required:
        jsonData.step_2.sub_steps[0].input_fields.addresses.billing_address.city
          .required,
      visible:
        jsonData.step_2.sub_steps[0].input_fields.addresses.billing_address.city
          .visible,
    };
    let billing_address_state_json = {
      required:
        jsonData.step_2.sub_steps[0].input_fields.addresses.billing_address
          .state.required,
      visible:
        jsonData.step_2.sub_steps[0].input_fields.addresses.billing_address
          .state.visible,
    };
    let billing_address_zip_json = {
      required:
        jsonData.step_2.sub_steps[0].input_fields.addresses.billing_address.zip
          .required,
      visible:
        jsonData.step_2.sub_steps[0].input_fields.addresses.billing_address.zip
          .visible,
    };
    let foreign_entity_json = {
      required:
        jsonData.step_2.sub_steps[0].input_fields.addresses.foreign_entity
          .required,
      visible:
        jsonData.step_2.sub_steps[0].input_fields.addresses.foreign_entity
          .visible,
    };
    let foreign_entity_international_address_json = {
      required:
        jsonData.step_2.sub_steps[0].input_fields.addresses.foreign_entity
          .international_address.required,
      visible:
        jsonData.step_2.sub_steps[0].input_fields.addresses.foreign_entity
          .international_address.visible,
    };
    let foreign_entity_country_json = {
      required:
        jsonData.step_2.sub_steps[0].input_fields.addresses.foreign_entity
          .country.required,
      visible:
        jsonData.step_2.sub_steps[0].input_fields.addresses.foreign_entity
          .country.visible,
    };

    if (
      legal_company_name_json.visible &&
      legal_company_name_json.required &&
      this.state[keys.legal_company_name] === ""
    ) {
      this.setState({
        errorMessage: "Please enter legal company name.",
      });
      return;
    }
    if (
      date_of_incorporation_json.visible &&
      date_of_incorporation_json.required &&
      this.state[keys.date_of_incorporation] === ""
    ) {
      this.setState({
        errorMessage: "Please enter date of incorporation.",
      });
      return;
    }
    if (entity_type_json.visible && entity_type_json.required) {
      if (this.state[keys.entity_type] === "") {
        this.setState({
          errorMessage: "Please select entity type.",
        });
        return;
      } else if (this.state[keys.entity_type] === "entity_type_other") {
        if (
          other_trade_names_json.visible &&
          other_trade_names_json.required &&
          this.state[keys.other_trade_names] === ""
        ) {
          this.setState({
            errorMessage: "Please enter other trade names",
          });
          return;
        }
      }
    }
    if (
      main_telephone_json.visible &&
      main_telephone_json.required &&
      this.state[keys.main_telephone] === ""
    ) {
      this.setState({
        errorMessage: "Please enter main telephone number",
      });
      return;
    }
    if (
      fax_number_json.visible &&
      fax_number_json.required &&
      this.state[keys.fax_number] === ""
    ) {
      this.setState({
        errorMessage: "Please enter fax number",
      });
      return;
    }
    if (website_url_json.visible) {
      if (website_url_json.required && this.state[keys.website_url] === "") {
        this.setState({
          errorMessage: "Please enter website url",
        });
        return;
      } else if (!this.state[keys.website_url].includes("https://")) {
        this.setState({
          errorMessage: "Please enter a valid https website url",
        });
        return;
      }
    }
    if (company_linkedin_json.visible) {
      if (
        company_linkedin_json.required &&
        this.state[keys.company_linkedin] === ""
      ) {
        this.setState({
          errorMessage: "Please enter company linkedin url",
        });
        return;
      } else if (!this.state[keys.website_url].includes("https://")) {
        this.setState({
          errorMessage: "Please enter a valid https company linkedin url",
        });
        return;
      }
    }
    if (address_1_json.visible && address_1_json.required) {
      if (
        address_1_address_json.visible &&
        address_1_address_json.required &&
        this.state[keys.address_1_address] === ""
      ) {
        this.setState({
          errorMessage: "Please enter address in Address 1",
        });
        return;
      }
      if (
        address_1_address_line_2_json.visible &&
        address_1_address_line_2_json.required &&
        this.state[keys.address_1_address_line_2] === ""
      ) {
        this.setState({
          errorMessage: "Please enter address line 2",
        });
        return;
      }
      if (
        address_1_city_json.visible &&
        address_1_city_json.required &&
        this.state[keys.address_1_city] === ""
      ) {
        this.setState({
          errorMessage: "Please enter city in Address 1",
        });
        return;
      }
      if (
        address_1_state_json.visible &&
        address_1_state_json.required &&
        this.state[keys.address_1_state] === ""
      ) {
        this.setState({
          errorMessage: "Please enter state in Address 1",
        });
        return;
      }
      if (
        address_1_zip_json.visible &&
        address_1_zip_json.required &&
        this.state[keys.address_1_zip] === ""
      ) {
        this.setState({
          errorMessage: "Please enter zip code in Address 1",
        });
        return;
      }
      if (
        address_1_country_json.visible &&
        address_1_country_json.required &&
        this.state[keys.address_1_country] === ""
      ) {
        this.setState({
          errorMessage: "Please enter country",
        });
        return;
      }
      if (
        address_1_address_type_json.visible &&
        address_1_address_type_json.required &&
        this.state[keys.address_type] === ""
      ) {
        this.setState({
          errorMessage: "Please enter address type in Address 1",
        });
        return;
      }
    }
    if (address_2_json.visible && address_2_json.required) {
      if (this.state[keys.address_type] !== "physical") {
        if (
          address_2_address_json.visible &&
          address_2_address_json.required &&
          this.state[keys.address_2_address] === ""
        ) {
          this.setState({
            errorMessage: "Please enter address in Address 2",
          });
          return;
        }
        if (
          address_2_city_json.visible &&
          address_2_city_json.required &&
          this.state[keys.address_2_city] === ""
        ) {
          this.setState({
            errorMessage: "Please enter city in Address 2",
          });
          return;
        }
        if (
          address_2_state_json.visible &&
          address_2_state_json.required &&
          this.state[keys.address_2_state] === ""
        ) {
          this.setState({
            errorMessage: "Please enter state in Address 2",
          });
          return;
        }
        if (
          address_2_zip_json.visible &&
          address_2_zip_json.required &&
          this.state[keys.address_2_zip] === ""
        ) {
          this.setState({
            errorMessage: "Please enter zip code in Address 2",
          });
          return;
        }
      }
    }
    let billing_address_temp = {};
    if (billing_address_json.visible && billing_address_json.required) {
      if (
        !this.state[keys.address_mark_billing] &&
        !this.state[keys.address_mark_billing_2]
      ) {
        if (
          billing_address_address_json.visible &&
          billing_address_address_json.required &&
          this.state[keys.billing_address_address] === ""
        ) {
          this.setState({
            errorMessage: "Please enter billing address",
          });
          return;
        }
        if (
          billing_address_city_json.visible &&
          billing_address_city_json.required &&
          this.state[keys.billing_address_city] === ""
        ) {
          this.setState({
            errorMessage: "Please enter city in billing address",
          });
          return;
        }
        if (
          billing_address_state_json.visible &&
          billing_address_state_json.required &&
          this.state[keys.billing_address_state] === ""
        ) {
          this.setState({
            errorMessage: "Please enter state in billing address",
          });
          return;
        }
        if (
          billing_address_zip_json.visible &&
          billing_address_zip_json.required &&
          this.state[keys.billing_address_zip] === ""
        ) {
          this.setState({
            errorMessage: "Please enter zip code in billing address",
          });
          return;
        }
        billing_address_temp = {
          [keys.billing_address_address]:
            this.state[keys.billing_address_address],
          [keys.billing_address_city]: this.state[keys.billing_address_city],
          [keys.billing_address_state]: this.state[keys.billing_address_state],
          [keys.billing_address_zip]: this.state[keys.billing_address_zip],
        };
      }
      if (this.state[keys.address_mark_billing]) {
        this.setState({
          [keys.billing_address_address]: this.state[keys.address_1_address],
          [keys.billing_address_city]: this.state[keys.address_1_city],
          [keys.billing_address_state]: this.state[keys.address_1_state],
          [keys.billing_address_zip]: this.state[keys.address_1_zip],
        });
        billing_address_temp = {
          [keys.billing_address_address]: this.state[keys.address_1_address],
          [keys.billing_address_city]: this.state[keys.address_1_city],
          [keys.billing_address_state]: this.state[keys.address_1_state],
          [keys.billing_address_zip]: this.state[keys.address_1_zip],
        };
      } else if (this.state[keys.address_mark_billing_2]) {
        this.setState({
          [keys.billing_address_address]: this.state[keys.address_2_address],
          [keys.billing_address_city]: this.state[keys.address_2_city],
          [keys.billing_address_state]: this.state[keys.address_2_state],
          [keys.billing_address_zip]: this.state[keys.address_2_zip],
        });
        billing_address_temp = {
          [keys.billing_address_address]: this.state[keys.address_2_address],
          [keys.billing_address_city]: this.state[keys.address_2_city],
          [keys.billing_address_state]: this.state[keys.address_2_state],
          [keys.billing_address_zip]: this.state[keys.address_2_zip],
        };
      }
    }
    if (foreign_entity_json.visible && foreign_entity_json.required) {
      if (
        foreign_entity_international_address_json.visible &&
        foreign_entity_international_address_json.required &&
        this.state[keys.foreign_entity_international_address] === ""
      ) {
        this.setState({
          errorMessage: "Please enter international address",
        });
        return;
      }
      if (
        foreign_entity_country_json.visible &&
        foreign_entity_country_json.required &&
        this.state[keys.foreign_entity_country] === ""
      ) {
        this.setState({
          errorMessage: "Please enter country in foreign entity address",
        });
        return;
      }
    }
    this.setState({ errorMessage: "", successMessage: "", disabled: true });
    let date_of_incorporation = this.state[keys.date_of_incorporation];
    let address_type = this.state[keys.address_type];
    const step2_json = {
      keys: keys,
      values: {
        [keys.legal_company_name]: this.state[keys.legal_company_name],
        [keys.date_of_incorporation]: date_of_incorporation.includes("T")
          ? this.state[keys.date_of_incorporation].split("T")[0]
          : this.state[keys.date_of_incorporation],
        [keys.other_trade_names]: this.state[keys.other_trade_names],
        [keys.main_telephone]: this.state[keys.main_telephone],
        [keys.fax_number]: this.state[keys.fax_number],
        [keys.website_url]: this.state[keys.website_url],
        [keys.company_linkedin]: this.state[keys.company_linkedin],
        [keys.entity_type]: this.state[keys.entity_type],
        [keys.address_1_address]: this.state[keys.address_1_address],
        [keys.address_1_address_line_2]:
          this.state[keys.address_1_address_line_2],
        [keys.address_1_city]: this.state[keys.address_1_city],
        [keys.address_1_state]: this.state[keys.address_1_state],
        [keys.address_1_zip]: this.state[keys.address_1_zip],
        [keys.address_1_country]: this.state[keys.address_1_country],
        [keys.address_type]: this.state[keys.address_type],
        [keys.address_mark_primary]: this.state[keys.address_mark_primary],
        [keys.address_mark_billing]: this.state[keys.address_mark_billing],
        [keys.address_2_address]:
          address_type === "physical" ? "" : this.state[keys.address_2_address],
        [keys.address_2_city]:
          address_type === "physical" ? "" : this.state[keys.address_2_city],
        [keys.address_2_state]:
          address_type === "physical" ? "" : this.state[keys.address_2_state],
        [keys.address_2_zip]:
          address_type === "physical" ? "" : this.state[keys.address_2_zip],
        [keys.address_mark_primary_2]: this.state[keys.address_mark_primary_2],
        [keys.address_mark_billing_2]: this.state[keys.address_mark_billing_2],
        [keys.billing_address_address]:
          billing_address_temp[keys.billing_address_address],
        [keys.billing_address_city]:
          billing_address_temp[keys.billing_address_city],
        [keys.billing_address_state]:
          billing_address_temp[keys.billing_address_state],
        [keys.billing_address_zip]:
          billing_address_temp[keys.billing_address_zip],
        [keys.foreign_entity_international_address]:
          this.state[keys.foreign_entity_international_address],
        [keys.foreign_entity_country]: this.state[keys.foreign_entity_country],
      },
    };
    console.log("step_2_data", step2_json);
    this.submitBusinessDetails(step2_json);
    //this.continue(step2_json);
  };

  submitBusinessDetails = async (businessData) => {
    const { signup_hash, steps_completed, steps_allowed } = this.props;
    //console.log('client_email',this.props);
    if (signup_hash !== "") {
      const { keys } = this.props.values;
      try {
        let response = await registerBusinessDetails({
          token: process.env.REACT_APP_API_TOKEN,
          api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
          signup_hash: signup_hash,
          legal_company_name: businessData.values[keys.legal_company_name],
          date_of_incorporation:
            businessData.values[keys.date_of_incorporation],
          other_trade_names: businessData.values[keys.other_trade_names],
          main_telephone: businessData.values[keys.main_telephone],
          fax_number: businessData.values[keys.fax_number],
          website_url: businessData.values[keys.website_url],
          company_linkedin: businessData.values[keys.company_linkedin],
          entity_type: businessData.values[keys.entity_type],
          address_1_address: businessData.values[keys.address_1_address],
          address_1_address_line_2:
            businessData.values[keys.address_1_address_line_2],
          address_1_city: businessData.values[keys.address_1_city],
          address_1_state: businessData.values[keys.address_1_state],
          address_1_zip: businessData.values[keys.address_1_zip],
          address_1_country: businessData.values[keys.address_1_country],
          address_type: businessData.values[keys.address_type],
          address_mark_primary: businessData.values[keys.address_mark_primary],
          address_2_address: businessData.values[keys.address_2_address],
          address_2_city: businessData.values[keys.address_2_city],
          address_2_state: businessData.values[keys.address_2_state],
          address_2_zip: businessData.values[keys.address_2_zip],
          address_mark_primary_2: businessData.values[keys.address_mark_primary]
            ? false
            : businessData.values[keys.address_mark_primary_2],
          billing_address_address:
            businessData.values[keys.billing_address_address],
          billing_address_city: businessData.values[keys.billing_address_city],
          billing_address_state:
            businessData.values[keys.billing_address_state],
          billing_address_zip: businessData.values[keys.billing_address_zip],
          foreign_entity_international_address:
            businessData.values[keys.foreign_entity_international_address],
          foreign_entity_country:
            businessData.values[keys.foreign_entity_country],
        });
        console.log("submitBusinessDetails", response);
        if (response.data.status === 404 || response.data.status === 422) {
          if (response.data.message === "address_1_not_verified") {
            this.setState({
              errorMessage:
                "Address 1 is not verified. Please enter your verified address",
              successMessage: "",
              disabled: false,
            });
          } else if (response.data.message === "address_2_not_verified") {
            this.setState({
              errorMessage:
                "Address 2 is not verified. Please enter your verified address",
              successMessage: "",
              disabled: false,
            });
          } else if (response.data.message === "billing_address_not_verified") {
            this.setState({
              errorMessage:
                "Billing Address is not verified. Please enter your verified billing address",
              successMessage: "",
              disabled: false,
            });
          } else {
            this.setState({
              errorMessage: "Something went wrong. Please try again",
              successMessage: "",
              disabled: false,
            });
          }
        } else if (
          response.data.status === 200 &&
          response.data.message === "success"
        ) {
          if (steps_completed === steps_allowed - 1) {
            // this.setState({
            //   errorMessage: '',
            //   successMessage: "Successfully submitted data.",
            //   disabled: true,
            // });
            this.props.successHandler();
          } else {
            this.continue(businessData);
          }
        }
      } catch (err) {
        this.setState({
          errorMessage: "",
          successMessage: "",
          disabled: false,
        });
      }
    }
  };

  modalHideIns = () => {
    window.$("#instructionsModal").modal("hide");
  };

  handleChangeCheck = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
    console.log(name + " " + value);
  };

  render() {
    const {
      jsonData,
      mStepsUITitles,
      mStepsUIDesc,
      steps_completed,
      steps_allowed,
    } = this.props;
    const { values, keys } = this.props.values;

    console.log(
      "jsondata",
      jsonData.step_2.sub_steps[0].input_fields.addresses.address_1
        .address_line_2
    );

    return (
      <>
        <HelmetProvider>
          <Helmet>
            <link
              rel="stylesheet"
              href="../../assets/ui_latest/css/dashlite.css?ver=3.0.3"
            />
            <link
              id="skin-default"
              rel="stylesheet"
              href="../../assets/ui_latest/csstheme.css?ver=3.0.3"
            />
          </Helmet>
        </HelmetProvider>
        <div className="nk-block nk-block-lg" style={{ width: "100%" }}>
          <div
            className="card card-bordered"
            style={{
              border: "none",
              paddingTop: "2%",
              paddingLeft: "5%",
              paddingRight: "5%",
            }}
          >
            <form
              className="nk-stepper stepper-init is-alter nk-stepper-s1"
              action="#"
              onSubmit={this.submitForms}
              id="stepper-create-profile"
            >
              <div className="row g-0 col-sep col-sep-md col-sep-xl">
                <div className="col-md-4 col-xl-4">
                  <div className="card-inner" id="registration_div">
                    <ul className="nk-stepper-nav nk-stepper-nav-s1 stepper-nav is-vr">
                      <li
                        className={steps_completed === 0 ? "current" : "done"}
                      >
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">{mStepsUITitles[0]}</div>
                            <div className="sub-text">{mStepsUIDesc[0]}</div>
                          </div>
                        </div>
                      </li>
                      {steps_allowed >= 2 ? (
                        <li
                          className={
                            steps_completed === 1
                              ? "current"
                              : steps_completed > 1 && steps_completed <= 3
                              ? "done"
                              : ""
                          }
                        >
                          <div className="step-item">
                            <div className="step-text">
                              <div className="lead-text">
                                {mStepsUITitles[1]}
                              </div>
                              <div className="sub-text">{mStepsUIDesc[1]}</div>
                            </div>
                          </div>
                        </li>
                      ) : null}
                      {steps_allowed >= 3 ? (
                        <li
                          className={
                            steps_completed === 2
                              ? "current"
                              : steps_completed > 2 && steps_completed <= 4
                              ? "done"
                              : ""
                          }
                        >
                          <div className="step-item">
                            <div className="step-text">
                              <div className="lead-text">
                                {mStepsUITitles[2]}
                              </div>
                              <div className="sub-text">{mStepsUIDesc[2]}</div>
                            </div>
                          </div>
                        </li>
                      ) : null}
                      {steps_allowed >= 4 ? (
                        <li
                          className={
                            steps_completed === 3
                              ? "current"
                              : steps_completed > 3 && steps_completed <= 5
                              ? "done"
                              : ""
                          }
                        >
                          <div className="step-item">
                            <div className="step-text">
                              <div className="lead-text">
                                {mStepsUITitles[3]}
                              </div>
                              <div className="sub-text">{mStepsUIDesc[3]}</div>
                            </div>
                          </div>
                        </li>
                      ) : null}
                      {steps_allowed >= 5 ? (
                        <li
                          className={
                            steps_completed === 4
                              ? "current"
                              : steps_completed > 4 && steps_completed <= 6
                              ? "done"
                              : ""
                          }
                        >
                          <div className="step-item">
                            <div className="step-text">
                              <div className="lead-text">
                                {mStepsUITitles[4]}
                              </div>
                              <div className="sub-text">{mStepsUIDesc[4]}</div>
                            </div>
                          </div>
                        </li>
                      ) : null}
                      {steps_allowed >= 6 ? (
                        <li
                          className={
                            steps_completed === 5
                              ? "current"
                              : steps_completed > 5 && steps_completed <= 7
                              ? "done"
                              : ""
                          }
                        >
                          <div className="step-item">
                            <div className="step-text">
                              <div className="lead-text">
                                {mStepsUITitles[5]}
                              </div>
                              <div className="sub-text">{mStepsUIDesc[5]}</div>
                            </div>
                          </div>
                        </li>
                      ) : null}
                      {steps_allowed >= 7 ? (
                        <li
                          className={
                            steps_completed === 6
                              ? "current"
                              : steps_completed > 6 && steps_completed <= 8
                              ? "done"
                              : ""
                          }
                        >
                          <div className="step-item">
                            <div className="step-text">
                              <div className="lead-text">
                                {mStepsUITitles[6]}
                              </div>
                              <div className="sub-text">{mStepsUIDesc[6]}</div>
                            </div>
                          </div>
                        </li>
                      ) : null}
                      {steps_allowed >= 8 ? (
                        <li
                          className={
                            steps_completed === 7
                              ? "current"
                              : steps_completed > 7 && steps_completed <= 9
                              ? "done"
                              : ""
                          }
                        >
                          <div className="step-item">
                            <div className="step-text">
                              <div className="lead-text">
                                {mStepsUITitles[7]}
                              </div>
                              <div className="sub-text">{mStepsUIDesc[7]}</div>
                            </div>
                          </div>
                        </li>
                      ) : null}
                      {/* <li>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">Account Password</div>
                            <div className="sub-text">
                              Account password to access the portal
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">Preview Details</div>
                            <div className="sub-text">Review and Submit</div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">Completed</div>
                            <div className="sub-text">You are done.</div>
                          </div>
                        </div>
                      </li> */}
                    </ul>
                  </div>
                </div>
                <div className="col-md-8 col-xl-8">
                  <div className="card-inner">
                    <div className="nk-stepper-content">
                      <div className="nk-stepper-steps stepper-steps">
                        {this.state.errorMessage !== "" ? (
                          <div
                            className="example-alert"
                            style={{ marginBottom: "20px" }}
                          >
                            <div className="alert alert-pro alert-danger">
                              <div className="alert-text">
                                <h4>Error</h4>
                                <p>{this.state.errorMessage}</p>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div className="nk-stepper-step done"></div>
                        <div className="nk-stepper-step active">
                          <h4
                            className="title mb-3"
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {jsonData.step_2.sub_steps[0].title}
                          </h4>
                          <p
                            style={{
                              textAlign: "center",
                              marginLeft: "10%",
                              marginRight: "10%",
                            }}
                          >
                            {jsonData.step_2.sub_steps[0].description}
                          </p>
                          <div className="row g-3">
                            {jsonData.step_2.sub_steps[0].input_fields
                              .legal_company_name.visible ? (
                              <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                  <MyCustomTextField
                                    data={
                                      jsonData.step_2.sub_steps[0].input_fields
                                        .legal_company_name
                                    }
                                    onChange={this.handleChangeCheck}
                                    defaultValue={`${
                                      values[keys.legal_company_name]
                                    }`}
                                  />
                                </div>
                              </div>
                            ) : null}
                            {jsonData.step_2.sub_steps[0].input_fields
                              .date_of_incorporation.visible ? (
                              <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                  <MyCustomDatePicker
                                    data={
                                      jsonData.step_2.sub_steps[0].input_fields
                                        .date_of_incorporation
                                    }
                                    onChange={this.handleChangeCheck}
                                    defaultValue={`${
                                      values[keys.date_of_incorporation]
                                    }`}
                                    value={`${
                                      values[keys.date_of_incorporation]
                                    }`}
                                  />
                                </div>
                              </div>
                            ) : null}
                            {jsonData.step_2.sub_steps[0].radio_fields
                              .entity_type.visible ? (
                              <div className="col-12">
                                <div className="form-group">
                                  <label className="form-label">
                                    {
                                      jsonData.step_2.sub_steps[0].radio_fields
                                        .entity_type.label
                                    }
                                    {jsonData.step_2.sub_steps[0].radio_fields
                                      .entity_type.required ? (
                                      <sup style={{ color: "red" }}>*</sup>
                                    ) : null}
                                  </label>
                                  <ul className="custom-radio">
                                    {jsonData.step_2.sub_steps[0].radio_fields.entity_type.data.map(
                                      (result, index) => (
                                        <li
                                          key={`entity_type${index}`}
                                          className="col-lg-3"
                                        >
                                          <MyCustomRadioField
                                            data={result}
                                            onChange={this.handleChangeCheck}
                                            defaultChecked={
                                              result.id ===
                                              this.state[keys.entity_type]
                                                ? true
                                                : false
                                            }
                                          />
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </div>
                            ) : null}
                            {jsonData.step_2.sub_steps[0].input_fields
                              .other_trade_names.visible &&
                            this.state[keys.entity_type] ===
                              "entity_type_other" ? (
                              <div className="col-12">
                                <div className="form-group">
                                  <MyCustomTextField
                                    data={
                                      jsonData.step_2.sub_steps[0].input_fields
                                        .other_trade_names
                                    }
                                    onChange={this.handleChangeCheck}
                                    defaultValue={`${
                                      values[keys.other_trade_names]
                                    }`}
                                  />
                                </div>
                              </div>
                            ) : null}
                            {jsonData.step_2.sub_steps[0].input_fields
                              .main_telephone.visible ? (
                              <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                  {/* <label
                                  className="form-label"
                                  htmlFor="fv-phone"
                                >
                                  Phone
                                </label>
                                <div className="form-control-wrap">
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      <span
                                        className="input-group-text"
                                        id="fv-phone"
                                      >
                                        +1
                                      </span>
                                    </div>
                                    <input
                                      type="number"
                                      className="form-control"
                                      inputprops={{ maxLength: 14 }}
                                      defaultValue={values.phone}
                                      onChange={handleChange}
                                      name="phone"
                                      id="phone"
                                      maxLength="14"
                                      placeholder="2121111111"
                                      required=""
                                    />
                                  </div>
                                </div> */}
                                  <MyCustomTextField
                                    data={
                                      jsonData.step_2.sub_steps[0].input_fields
                                        .main_telephone
                                    }
                                    onChange={this.handleChangeCheck}
                                    defaultValue={`${
                                      values[keys.main_telephone]
                                    }`}
                                  />
                                </div>
                              </div>
                            ) : null}
                            {jsonData.step_2.sub_steps[0].input_fields
                              .fax_number.visible ? (
                              <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                  <MyCustomTextField
                                    data={
                                      jsonData.step_2.sub_steps[0].input_fields
                                        .fax_number
                                    }
                                    onChange={this.handleChangeCheck}
                                    defaultValue={`${values[keys.fax_number]}`}
                                  />
                                </div>
                              </div>
                            ) : null}
                            {jsonData.step_2.sub_steps[0].input_fields
                              .website_url.visible ? (
                              <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                  <MyCustomTextField
                                    data={
                                      jsonData.step_2.sub_steps[0].input_fields
                                        .website_url
                                    }
                                    onChange={this.handleChangeCheck}
                                    defaultValue={`${values[keys.website_url]}`}
                                  />
                                </div>
                              </div>
                            ) : null}
                            {jsonData.step_2.sub_steps[0].input_fields
                              .company_linkedin.visible ? (
                              <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                  <MyCustomTextField
                                    data={
                                      jsonData.step_2.sub_steps[0].input_fields
                                        .company_linkedin
                                    }
                                    onChange={this.handleChangeCheck}
                                    defaultValue={`${
                                      values[keys.company_linkedin]
                                    }`}
                                  />
                                </div>
                              </div>
                            ) : null}
                            {jsonData.step_2.sub_steps[0].input_fields.addresses
                              .address_1.visible ? (
                              <div className="col-12">
                                <div className="row">
                                  <h5
                                    className="title mb-3"
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    {
                                      jsonData.step_2.sub_steps[0].input_fields
                                        .addresses.address_1.label
                                    }
                                  </h5>
                                </div>
                                {jsonData.step_2.sub_steps[0].input_fields
                                  .addresses.address_1.address.visible ? (
                                  <>
                                    <div className="row">
                                      <div className="col-12">
                                        <div className="row">
                                          <div className="form-group">
                                            <MyCustomTextField
                                              data={
                                                jsonData.step_2.sub_steps[0]
                                                  .input_fields.addresses
                                                  .address_1.address
                                              }
                                              onChange={this.handleChangeCheck}
                                              defaultValue={`${
                                                values[keys.address_1_address]
                                              }`}
                                            />
                                          </div>
                                        </div>
                                        {jsonData.step_2.sub_steps[0]
                                          .input_fields.addresses.address_1
                                          .address_line_2.visible ? (
                                          <>
                                            <div
                                              className="row"
                                              style={{
                                                marginTop: "3%",
                                              }}
                                            >
                                              <div className="form-group">
                                                <MyCustomTextField
                                                  data={
                                                    jsonData.step_2.sub_steps[0]
                                                      .input_fields.addresses
                                                      .address_1.address_line_2
                                                  }
                                                  onChange={
                                                    this.handleChangeCheck
                                                  }
                                                  defaultValue={`${
                                                    values[
                                                      keys
                                                        .address_1_address_line_2
                                                    ]
                                                  }`}
                                                />
                                              </div>
                                            </div>
                                          </>
                                        ) : null}
                                        <div
                                          className="row"
                                          style={{
                                            marginTop: "3%",
                                          }}
                                        >
                                          <div className="col-4">
                                            <div className="form-group">
                                              <MyCustomTextField
                                                data={
                                                  jsonData.step_2.sub_steps[0]
                                                    .input_fields.addresses
                                                    .address_1.city
                                                }
                                                onChange={
                                                  this.handleChangeCheck
                                                }
                                                defaultValue={`${
                                                  values[keys.address_1_city]
                                                }`}
                                              />
                                            </div>
                                          </div>
                                          <div className="col-4">
                                            <div className="form-group">
                                              <MyCustomTextField
                                                data={
                                                  jsonData.step_2.sub_steps[0]
                                                    .input_fields.addresses
                                                    .address_1.state
                                                }
                                                onChange={
                                                  this.handleChangeCheck
                                                }
                                                defaultValue={`${
                                                  values[keys.address_1_state]
                                                }`}
                                              />
                                            </div>
                                          </div>
                                          <div className="col-4">
                                            <div className="form-group">
                                              <MyCustomTextField
                                                data={
                                                  jsonData.step_2.sub_steps[0]
                                                    .input_fields.addresses
                                                    .address_1.zip
                                                }
                                                onChange={
                                                  this.handleChangeCheck
                                                }
                                                defaultValue={`${
                                                  values[keys.address_1_zip]
                                                }`}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        {jsonData.step_2.sub_steps[0]
                                          .input_fields.addresses.address_1
                                          .country.visible ? (
                                          <div
                                            className="row"
                                            style={{
                                              marginTop: "3%",
                                            }}
                                          >
                                            <div className="form-group">
                                              <MyCustomTextField
                                                data={
                                                  jsonData.step_2.sub_steps[0]
                                                    .input_fields.addresses
                                                    .address_1.country
                                                }
                                                onChange={
                                                  this.handleChangeCheck
                                                }
                                                defaultValue={`${
                                                  values[keys.address_1_country]
                                                }`}
                                              />
                                            </div>
                                          </div>
                                        ) : null}
                                      </div>
                                      {jsonData.step_2.sub_steps[0].input_fields
                                        .addresses.address_1.address_mark
                                        .visible ? (
                                        <div
                                          className="row"
                                          style={{
                                            marginTop: "3%",
                                          }}
                                        >
                                          <div className="col-12">
                                            <div className="form-group">
                                              <ul className="custom-control-group custom-control-vertical custom-control-stacked w-100">
                                                {jsonData.step_2.sub_steps[0].input_fields.addresses.address_1.address_mark.data.map(
                                                  (result, index) => (
                                                    <li
                                                      key={`address_mark${index}`}
                                                    >
                                                      <MyCustomCheckBoxField
                                                        data={result}
                                                        onChange={
                                                          this.handleChangeCheck
                                                        }
                                                      />
                                                      <span className="ms-2" />
                                                    </li>
                                                  )
                                                )}
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>
                                  </>
                                ) : null}
                                {jsonData.step_2.sub_steps[0].input_fields
                                  .addresses.address_1.address_type.visible ? (
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="form-group">
                                        <label className="form-label">
                                          {
                                            jsonData.step_2.sub_steps[0]
                                              .input_fields.addresses.address_1
                                              .address_type.label
                                          }
                                        </label>
                                        <ul className="custom-radio">
                                          {jsonData.step_2.sub_steps[0].input_fields.addresses.address_1.address_type.data.map(
                                            (result, index) => (
                                              <li
                                                key={`address_type${index}`}
                                                className="col-lg-3"
                                              >
                                                <MyCustomRadioField
                                                  data={result}
                                                  onChange={
                                                    this.handleChangeCheck
                                                  }
                                                  defaultChecked={
                                                    result.id ===
                                                    this.state[
                                                      keys.address_type
                                                    ]
                                                      ? true
                                                      : false
                                                  }
                                                />
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            ) : null}
                            {jsonData.step_2.sub_steps[0].input_fields.addresses
                              .address_2.visible &&
                            this.state[keys.address_type] !== "physical" ? (
                              <div className="col-12">
                                <div className="row">
                                  <h5
                                    className="title mb-3"
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    {
                                      jsonData.step_2.sub_steps[0].input_fields
                                        .addresses.address_2.label
                                    }
                                  </h5>
                                </div>
                                {jsonData.step_2.sub_steps[0].input_fields
                                  .addresses.address_2.address.visible ? (
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="row">
                                        <div className="form-group">
                                          <MyCustomTextField
                                            data={
                                              jsonData.step_2.sub_steps[0]
                                                .input_fields.addresses
                                                .address_2.address
                                            }
                                            onChange={this.handleChangeCheck}
                                            defaultValue={`${
                                              values[keys.address_2_address]
                                            }`}
                                          />
                                        </div>
                                      </div>
                                      <div
                                        className="row"
                                        style={{
                                          marginTop: "3%",
                                        }}
                                      >
                                        <div className="col-4">
                                          <div className="form-group">
                                            <MyCustomTextField
                                              data={
                                                jsonData.step_2.sub_steps[0]
                                                  .input_fields.addresses
                                                  .address_2.city
                                              }
                                              onChange={this.handleChangeCheck}
                                              defaultValue={`${
                                                values[keys.address_2_city]
                                              }`}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-4">
                                          <div className="form-group">
                                            <MyCustomTextField
                                              data={
                                                jsonData.step_2.sub_steps[0]
                                                  .input_fields.addresses
                                                  .address_2.state
                                              }
                                              onChange={this.handleChangeCheck}
                                              defaultValue={`${
                                                values[keys.address_2_state]
                                              }`}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-4">
                                          <div className="form-group">
                                            <MyCustomTextField
                                              data={
                                                jsonData.step_2.sub_steps[0]
                                                  .input_fields.addresses
                                                  .address_2.zip
                                              }
                                              onChange={this.handleChangeCheck}
                                              defaultValue={`${
                                                values[keys.address_2_zip]
                                              }`}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {jsonData.step_2.sub_steps[0].input_fields
                                  .addresses.address_2.address_mark.visible ? (
                                  <div
                                    className="row"
                                    style={{
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div className="col-12">
                                      <div className="form-group">
                                        <ul className="custom-control-group custom-control-vertical custom-control-stacked w-100">
                                          {jsonData.step_2.sub_steps[0].input_fields.addresses.address_2.address_mark.data.map(
                                            (result, index) => (
                                              <li key={`address_mark${index}`}>
                                                <MyCustomCheckBoxField
                                                  data={result}
                                                  onChange={
                                                    this.handleChangeCheck
                                                  }
                                                />
                                                <span className="ms-2" />
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            ) : null}
                            {jsonData.step_2.sub_steps[0].input_fields.addresses
                              .billing_address.visible &&
                            ((this.state[keys.address_type] === "physical" &&
                              !this.state[keys.address_mark_billing]) ||
                              (this.state[keys.address_type] !== "physical" &&
                                !this.state[keys.address_mark_billing] &&
                                !this.state[keys.address_mark_billing_2])) ? (
                              <div className="col-12">
                                <div className="row">
                                  <h5
                                    className="title mb-3"
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    {
                                      jsonData.step_2.sub_steps[0].input_fields
                                        .addresses.billing_address.label
                                    }
                                  </h5>
                                </div>
                                {jsonData.step_2.sub_steps[0].input_fields
                                  .addresses.billing_address.address.visible ? (
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="row">
                                        <div className="form-group">
                                          <MyCustomTextField
                                            data={
                                              jsonData.step_2.sub_steps[0]
                                                .input_fields.addresses
                                                .billing_address.address
                                            }
                                            onChange={this.handleChangeCheck}
                                            defaultValue={`${
                                              values[
                                                keys.billing_address_address
                                              ]
                                            }`}
                                          />
                                        </div>
                                      </div>
                                      <div
                                        className="row"
                                        style={{
                                          marginTop: "3%",
                                        }}
                                      >
                                        <div className="col-4">
                                          <div className="form-group">
                                            <MyCustomTextField
                                              data={
                                                jsonData.step_2.sub_steps[0]
                                                  .input_fields.addresses
                                                  .billing_address.city
                                              }
                                              onChange={this.handleChangeCheck}
                                              defaultValue={`${
                                                values[
                                                  keys.billing_address_city
                                                ]
                                              }`}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-4">
                                          <div className="form-group">
                                            <MyCustomTextField
                                              data={
                                                jsonData.step_2.sub_steps[0]
                                                  .input_fields.addresses
                                                  .billing_address.state
                                              }
                                              onChange={this.handleChangeCheck}
                                              defaultValue={`${
                                                values[
                                                  keys.billing_address_state
                                                ]
                                              }`}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-4">
                                          <div className="form-group">
                                            <MyCustomTextField
                                              data={
                                                jsonData.step_2.sub_steps[0]
                                                  .input_fields.addresses
                                                  .billing_address.zip
                                              }
                                              onChange={this.handleChangeCheck}
                                              defaultValue={`${
                                                values[keys.billing_address_zip]
                                              }`}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            ) : null}
                            {jsonData.step_2.sub_steps[0].input_fields.addresses
                              .foreign_entity.visible ? (
                              <div className="col-12">
                                <div className="row">
                                  <h5
                                    className="title mb-3"
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    {
                                      jsonData.step_2.sub_steps[0].input_fields
                                        .addresses.foreign_entity.label
                                    }
                                  </h5>
                                </div>
                                {jsonData.step_2.sub_steps[0].input_fields
                                  .addresses.foreign_entity
                                  .international_address.visible ? (
                                  <div className="row gy-1">
                                    <div className="col-lg-6 col-md-6">
                                      <div className="form-group">
                                        <MyCustomTextField
                                          data={
                                            jsonData.step_2.sub_steps[0]
                                              .input_fields.addresses
                                              .foreign_entity
                                              .international_address
                                          }
                                          onChange={this.handleChangeCheck}
                                          defaultValue={`${
                                            values[
                                              keys
                                                .foreign_entity_international_address
                                            ]
                                          }`}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                      <div className="form-group">
                                        <MyCustomTextField
                                          data={
                                            jsonData.step_2.sub_steps[0]
                                              .input_fields.addresses
                                              .foreign_entity.country
                                          }
                                          onChange={this.handleChangeCheck}
                                          defaultValue={`${
                                            values[keys.foreign_entity_country]
                                          }`}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="nk-stepper-step"></div>
                        <div className="nk-stepper-step"></div>
                        <div className="nk-stepper-step"></div>
                        <div className="nk-stepper-step text-center"></div>
                      </div>
                      <div className="row mt-5 gx-1 gy-1">
                        {jsonData.step_2.sub_steps[0].button_fields.back
                          .visible && steps_completed > 0 ? (
                          <div className="col-lg-6">
                            <MyCustomButton
                              data={
                                jsonData.step_2.sub_steps[0].button_fields.back
                              }
                              onClick={this.back}
                            />
                          </div>
                        ) : null}
                        {jsonData.step_2.sub_steps[0].button_fields.continue
                          .visible && steps_completed < steps_allowed - 1 ? (
                          <div
                            className={
                              steps_completed === 0 ? "col-lg-12" : "col-lg-6"
                            }
                          >
                            <MyCustomButton
                              data={
                                jsonData.step_2.sub_steps[0].button_fields
                                  .continue
                              }
                              //onClick={this.submitForms}
                              disabled={this.state.disabled}
                            />
                          </div>
                        ) : null}
                        {jsonData.step_2.sub_steps[0].button_fields.submit
                          .visible && steps_completed === steps_allowed - 1 ? (
                          <div className="col-lg-6">
                            <MyCustomButton
                              data={
                                jsonData.step_2.sub_steps[0].button_fields
                                  .submit
                              }
                              //onClick={this.submitForms}
                              disabled={this.state.disabled}
                            />
                          </div>
                        ) : null}
                      </div>

                      {/* <ul className="nk-stepper-pagination pt-4 gx-4 gy-2 stepper-pagination">                        
                        {jsonData.step_2.sub_steps[0].button_fields.back
                          .visible ? (
                          <li className="step-prev">
                            <MyCustomButton
                              data={
                                jsonData.step_2.sub_steps[0].button_fields.back
                              }
                              onClick={this.back}
                            />
                          </li>
                        ) : null}                        
                        {jsonData.step_2.sub_steps[0].button_fields.continue
                          .visible ? (
                          <li className="step-next">
                            <MyCustomButton
                              data={
                                jsonData.step_2.sub_steps[0].button_fields
                                  .continue
                              }
                              //onClick={this.submitForms}
                              disabled={this.state.disabled}
                            />
                          </li>
                        ) : null}                        
                      </ul> */}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default Step2;
