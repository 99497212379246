import { React } from "react";
import { Component } from "react";
import axios from "axios";
import { axiosConfig } from "../../../config/utility";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  defaultShortlinkSettings,
  addSocialLinks,
  checkAvailability,
  addSharelink,
  getUserAboutMe,
  getServices,
} from "./../../../config/shortlink_api_calls";

import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";

import HelperClass from "./../../../config/helperClass";
import { tableLoaderListing } from "../../../components/ContentLoaders/table_loader_listing";
import { tableLoaderBlockImage } from "../../../components/ContentLoaders/table_loader_imageblock";
import { APP_LIVE_URL } from "./../../../config/config";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";

import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import MutextField from "@material-ui/core/TextField";
import { Editor } from "@tinymce/tinymce-react";

import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import Radio from "@material-ui/core/Radio";
import Divider from "@material-ui/core/Divider";

const auth = new HelperClass();

class SharelinkSettings extends Component {
  constructor() {
    super();

    this.state = {
      serviceData: [],
      about_me_short: "",
      about_me: "",
      errorMessage: "",
      instagram_link: "",
      facebook_link: "",
      twitter_link: "",
      linkedin_link: "",
      youtube_link: "",
      successMessage: "",
      tableLoader: true,
      tableLoaderListing: true,
      tableLoaderBlockImage: true,
      tableLoaderBlockService: true,
      logo: "",
      theme: "",
      theme_selected_icon: "",
      disabled: false,
      errorMessageEdit: "",
      successMessageEdit: "",
      status: "1",
      about_me_settings: true,
      contact_settings: true,
      social_settings: true,
      picture: "",
      pictureName: "",
      title: "",
      logo_view: "",
      imageData: [],
      seo_settings: false,
      meta_description: "",
      meta_title: "",
      contact_address: auth.getAddress(),
      contact_email: auth.getEmail(),
      contact_phone: auth.getPhone(),
      select_logo: false,
      select_avatar: false,
      selected_logo: "",
      selected_avatar: "",
      avatarData: [],
      logoData: [],
      themesList: [],
      link_identifier: "",
      link_available: "0",
      loadingButton: false,
      selected_service: [],
      availability_button: false,
    };
  }
  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };
  serviceSigning = async (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[6]);
  };

  async componentDidMount() {
    const servicesResponce = await defaultShortlinkSettings(
      auth.getAccount(),
      auth.getToken()
    );
    let logo_array = [];
    let avatar_array = [];
    if (servicesResponce.data.images) {
      logo_array = this.groupBy(servicesResponce.data.images, "logo");
    }
    if (servicesResponce.data.images) {
      avatar_array = this.groupBy(servicesResponce.data.images, "image");
    }
    console.log("defaultSharelinkSettings: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        tableLoaderBlockImage: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        about_me_short: servicesResponce.data.data[0]["about_me_short"],
        about_me: servicesResponce.data.data[0]["about_me"],
        instagram_link: servicesResponce.data.data[0]["instagram_link"],
        facebook_link: servicesResponce.data.data[0]["facebook_link"],
        twitter_link: servicesResponce.data.data[0]["twitter_link"],
        linkedin_link: servicesResponce.data.data[0]["linkedin_link"],
        youtube_link: servicesResponce.data.data[0]["youtube_link"],
        setting_id: servicesResponce.data.data[0]["id"],
        logoData: logo_array,
        avatarData: avatar_array,
        themesList: servicesResponce.data.themes,
        tableLoaderBlockImage: false,
      });
    } else {
      this.setState({
        tableLoaderBlockImage: false,
      });
      //window.location.replace("/error");
    }
  }

  getServices = async () => {
    const getResponce = await getServices(auth.getAccount(), auth.getToken());
    if (
      getResponce.data.status === 403 ||
      getResponce.data.errors === "authentication missing" ||
      getResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (getResponce.data.status === 404) {
      this.setState({
        tableLoaderBlockService: false,
      });
      //window.location.replace("/error");
    } else if (
      getResponce.data.status === 200 &&
      getResponce.data.message === "success"
    ) {
      this.setState({
        serviceData: getResponce.data.data,
        tableLoaderBlockService: false,
      });
    } else {
      this.setState({
        tableLoaderBlockService: false,
      });
      //window.location.replace("/error");
    }
  };

  groupBy = (arr, property) => {
    let intArray = arr;
    let evenArray = [];
    intArray.map((x) => {
      if (x.images_type === property) {
        evenArray.push(x);
      }
    });

    return evenArray;
  };

  saveSharelink = async () => {
    const {
      theme,
      selected_logo,
      selected_avatar,
      title,
      link_identifier,
      link_available,
      instagram_link,
      facebook_link,
      twitter_link,
      linkedin_link,
      youtube_link,
      about_me_short,
      about_me,
      meta_description,
      meta_title,
      contact_address,
      contact_email,
      contact_phone,
      status,
    } = this.state;

    this.setState({
      loadingButton: true,
    });

    let is_validated = true;

    let about_me_settings = "0";
    if (this.state.about_me_settings === true) {
      about_me_settings = "1";
    }

    let contact_settings = "0";
    if (this.state.contact_settings === true) {
      contact_settings = "1";
    }

    let social_settings = "0";
    if (this.state.social_settings === true) {
      social_settings = "1";
    }

    if (
      theme === "" &&
      selected_logo === "" &&
      selected_avatar === "" &&
      title === "" &&
      link_identifier === ""
    ) {
      is_validated = false;
    }

    if (link_available !== "1") {
      is_validated = false;
    }

    if (!this.state.selected_service) {
      is_validated = false;
    }

    if (is_validated) {
      const addResponce = await addSharelink(
        auth.getAccount(),
        auth.getToken(),
        theme,
        selected_logo,
        selected_avatar,
        title,
        link_identifier,
        link_available,
        instagram_link,
        facebook_link,
        twitter_link,
        linkedin_link,
        youtube_link,
        about_me_short,
        this.state.about_me,
        meta_description,
        meta_title,
        contact_address,
        contact_email,
        contact_phone,
        status,
        about_me_settings,
        contact_settings,
        social_settings,
        this.state.selected_service
      );

      //console.log("contact_phone: ", addResponce.data);

      if (
        addResponce.data.status === 403 ||
        addResponce.data.errors === "authentication missing" ||
        addResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        addResponce.data.status === 404 &&
        addResponce.data.message === "not_available"
      ) {
        // window.location.replace("/error");

        this.setState({
          errorMessage:
            "Custom link ID is not available. Please choose new ID.",
          successMessage: "",
          disabled: false,
        });
      } else if (
        addResponce.data.status === 200 &&
        addResponce.data.message === "success"
      ) {
        this.setState({
          errorMessage: "",
          successMessage: "Sharelink added successfully.",
          disabled: false,
          logo: "",
          avatar: "",
          theme: "",
          title: "",
          link_identifier: "",
          link_available: "0",
        });
      } else {
        this.setState({
          errorMessage: "There is some error while adding the sharelink.",
          successMessage: "",
          disabled: false,
        });
        //window.location.replace("/error");
      }
    } else {
      this.setState({
        errorMessage: "Please enter the required fields.",
        successMessage: "",
      });
    }
    document
      .getElementById("start_container")
      ?.scrollIntoView({ top: 0, behavior: "smooth" });
    setTimeout(() => {
      this.setState({
        successMessage: "",
        errorMessage: "",
        loadingButton: false,
      });
    }, 5000);
  };

  saveAndPreview = async () => {
    const {
      theme,
      selected_logo,
      selected_avatar,
      title,
      link_identifier,
      link_available,
      instagram_link,
      facebook_link,
      twitter_link,
      linkedin_link,
      youtube_link,
      about_me_short,
      about_me,
      meta_description,
      meta_title,
      contact_address,
      contact_email,
      contact_phone,
      status,
    } = this.state;

    this.setState({
      loadingButton: true,
    });

    let is_validated = true;

    let about_me_settings = "0";
    if (this.state.about_me_settings === true) {
      about_me_settings = "1";
    }

    let contact_settings = "0";
    if (this.state.contact_settings === true) {
      contact_settings = "1";
    }

    let social_settings = "0";
    if (this.state.social_settings === true) {
      social_settings = "1";
    }

    if (
      theme === "" &&
      selected_logo === "" &&
      selected_avatar === "" &&
      title === "" &&
      link_identifier === ""
    ) {
      is_validated = false;
    }

    if (link_available !== "1") {
      is_validated = false;
    }

    if (!this.state.selected_service) {
      is_validated = false;
    }

    if (is_validated) {
      const addResponce = await addSharelink(
        auth.getAccount(),
        auth.getToken(),
        theme,
        selected_logo,
        selected_avatar,
        title,
        link_identifier,
        link_available,
        instagram_link,
        facebook_link,
        twitter_link,
        linkedin_link,
        youtube_link,
        about_me_short,
        this.state.about_me,
        meta_description,
        meta_title,
        contact_address,
        contact_email,
        contact_phone,
        status,
        about_me_settings,
        contact_settings,
        social_settings,
        this.state.selected_service
      );

      //console.log("contact_phone: ", addResponce.data);

      if (
        addResponce.data.status === 403 ||
        addResponce.data.errors === "authentication missing" ||
        addResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        addResponce.data.status === 404 &&
        addResponce.data.message === "not_available"
      ) {
        // window.location.replace("/error");

        this.setState({
          errorMessage:
            "Custom link ID is not available. Please choose new ID.",
          successMessage: "",
          disabled: false,
        });
      } else if (
        addResponce.data.status === 200 &&
        addResponce.data.message === "success"
      ) {
        this.setState({
          errorMessage: "",
          successMessage:
            "Sharelink added successfully. You will be redirected to the sharelink preview.",
          disabled: false,
          loadingButton: false,
          logo: "",
          avatar: "",
          theme: "",
          title: "",
          link_identifier: "",
          link_available: "0",
        });
        document
          .getElementById("start_container")
          ?.scrollIntoView({ top: 0, behavior: "smooth" });
        setTimeout(() => {
          window.open(addResponce.data.link, "_blank", "noopener,noreferrer");
        }, 3000);
      } else {
        this.setState({
          errorMessage: "There is some error while adding the sharelink.",
          successMessage: "",
          disabled: false,
        });
        //window.location.replace("/error");
      }
    } else {
      this.setState({
        errorMessage: "Please enter the required fields.",
        successMessage: "",
      });
    }
    document
      .getElementById("start_container")
      ?.scrollIntoView({ top: 0, behavior: "smooth" });
    setTimeout(() => {
      this.setState({
        successMessage: "",
        errorMessage: "",
        loadingButton: false,
      });
    }, 5000);
  };

  formSaveSocial = async () => {
    window.scrollTo(0, 0);
    const {
      instagram_link,
      facebook_link,
      twitter_link,
      linkedin_link,
      youtube_link,
    } = this.state;

    const saveResponce = await addSocialLinks(
      auth.getAccount(),
      auth.getToken(),
      instagram_link,
      facebook_link,
      twitter_link,
      linkedin_link,
      youtube_link
    );
    //console.log("ADD Social: ", saveResponce.data);
    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "fail" &&
      saveResponce.data.note !== ""
    ) {
      // window.location.replace("/error");
      this.setState({
        errorMessage: saveResponce.data.note,
        successMessage: "",
        disabled: false,
      });
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        errorMessage: "",
        successMessage: "Social media links added successfully.",
        disabled: false,
      });
    } else {
      this.setState({
        errorMessage: saveResponce.data.message,
        successMessage: "",
        disabled: false,
      });
      //window.location.replace("/error");
    }
    setTimeout(() => {
      this.setState({ successMessage: "", errorMessage: "" });
    }, 5000);
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  hide_messages = () => {
    this.setState({
      errorMessage: "",
      successMessage: "",
      errorMessageEdit: "",
      successMessageEdit: "",
      disabled: false,
    });
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;

    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleChangeRadio = async (event) => {
    const { target } = event;
    const value = target.type === "radio" ? target.checked : target.value;

    const { name } = target;

    if (name === "select_logo") {
      this.setState({
        [name]: value,
        selected_logo: target.value,
      });
    }
    if (name === "select_avatar") {
      this.setState({
        [name]: value,
        selected_avatar: target.value,
      });
    }
    //console.log("handleChangeRadio:", name + " " + value + " " + target.value);
  };

  setTheme = (id) => {
    //console.log("Theme: ", id);
    this.setState({
      theme: id,
    });
  };

  setLogo = (id) => {
    //console.log("Logo: ", id);
    this.setState({
      selected_logo: id,
    });
  };

  setAvatar = (id) => {
    //console.log("Avatar: ", id);
    this.setState({
      selected_avatar: id,
    });
  };

  setThemeClass = (id) => {
    if (this.state.theme === id) {
      return "read active";
    } else {
      return "read";
    }
  };

  setThemeClassMain = (id) => {
    if (this.state.theme === id) {
      return "pricingTable active2";
    } else {
      return "pricingTable";
    }
  };

  setLogoClass = (id) => {
    if (this.state.selected_logo === id) {
      return "read active";
    } else {
      return "read";
    }
  };

  setLogoClassMain = (id) => {
    if (this.state.selected_logo === id) {
      return "pricingTable active2";
    } else {
      return "pricingTable";
    }
  };

  setAvatarClass = (id) => {
    if (this.state.selected_avatar === id) {
      return "read active";
    } else {
      return "read";
    }
  };

  setAvatarClassMain = (id) => {
    if (this.state.selected_avatar === id) {
      return "pricingTable active2";
    } else {
      return "pricingTable";
    }
  };

  setService = (id) => {
    if (this.state.selected_service.includes(id)) {
      var checked = this.state.selected_service;
      var values = checked.indexOf(id);
      checked.splice(values, 1);
      this.setState({ selected_service: checked });
    } else {
      this.setState({
        selected_service: [...this.state.selected_service, id],
      });
    }
  };

  setServiceClass = (id) => {
    if (this.state.selected_service.includes(id)) {
      return "read active";
    } else {
      return "read";
    }
  };

  setServiceClassMain = (id) => {
    if (this.state.selected_service.includes(id)) {
      return "pricingTable active2";
    } else {
      return "pricingTable";
    }
  };

  handleEditorChange = (content, editor) => {
    this.setState({
      about_me: content,
    });
    //console.log("Content was updated:", content);
  };

  handleFile = async (event) => {
    this.setState({
      picture: event.target.files[0],
      pictureName: event.target.files[0].name,
    });
  };

  calculatePercent = (percent, num) => {
    return (percent * (num / 100)).toFixed(4);
  };

  modalHide = () => {
    window.$("#modalAlert").modal("hide");
    // this.props.agreementSigned();
  };

  open_add_image = () => {
    window.$("#modalAddImage").modal("show");
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  checkNumberChar = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  checkAvailability = async () => {
    const { link_identifier } = this.state;
    if (link_identifier === "") {
      this.setState({
        errorMessage:
          "Please enter custom link ID without spaces and special characters. ",
        successMessage: "",
        link_available: "0",
        disabled: true,
        availability_button: true,
      });
    } else {
      const saveResponce = await checkAvailability(
        auth.getAccount(),
        auth.getToken(),
        link_identifier
      );
      //console.log("checkAvailability: ", saveResponce.data);
      if (
        saveResponce.data.status === 403 ||
        saveResponce.data.errors === "authentication missing" ||
        saveResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        saveResponce.data.status === 404 &&
        saveResponce.data.message === "fail" &&
        saveResponce.data.note !== ""
      ) {
        // window.location.replace("/error"); 2022-07-01
        this.setState({
          errorMessage: "There is some error while checking availability.",
          successMessage: "",
          link_available: "2",
          disabled: true,
          availability_button: false,
        });
      } else if (
        saveResponce.data.status === 404 &&
        saveResponce.data.message === "not_available"
      ) {
        // window.location.replace("/error");
        this.setState({
          errorMessage: "Custom link ID is not available. Please try another.",
          successMessage: "",
          link_available: "2",
          disabled: true,
          availability_button: false,
        });
      } else if (
        saveResponce.data.status === 200 &&
        saveResponce.data.message === "available"
      ) {
        this.setState({
          errorMessage: "",
          successMessage: "",
          link_available: "1",
          disabled: false,
          availability_button: false,
        });
      } else {
        this.setState({
          errorMessage: "",
          successMessage: "",
          link_available: "2",
          disabled: true,
          availability_button: false,
        });
        //window.location.replace("/error");
      }
    }
    setTimeout(() => {
      this.setState({ successMessage: "", errorMessage: "" });
    }, 5000);
  };

  open_service_tab = () => {};

  render() {
    return (
      <div className="nk-content " id="Services_Block">
        <div className="container-fluid" id="start_container">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}

          {/* MSA WARNING END */}
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    to="#"
                    color="inherit"
                    onClick={() => this.addMenuHistory("/")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <em className="icon ni ni-home-fill"></em>
                    {"   "} Home
                  </Link>

                  <Link
                    to="#"
                    color="inherit"
                    onClick={() => this.addMenuHistory("/sharelinks")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    {"   "} Sharelink
                  </Link>

                  <Typography color="textPrimary" className="">
                    Add New Sharelink
                  </Typography>
                </Breadcrumbs>
                {/*<h3 className="nk-block-title page-title">
                  <PageTitle name="Add New Services" />
                </h3>*/}
              </div>
            </div>
          </div>
          <div className="row g-4">
            <div className="col-md-12">
              <div className="card card-bordered">
                <div className="card-inner">
                  {this.state.errorMessage !== "" ? (
                    <div className="example-alert">
                      <div className="alert alert-pro alert-danger">
                        <div className="alert-text">
                          <h4>Error</h4>
                          <p>{this.state.errorMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {this.state.successMessage !== "" ? (
                    <div className="example-alert">
                      <div className="alert alert-pro alert-success">
                        <div className="alert-text">
                          <h4>Success</h4>
                          <p>{this.state.successMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <br />
                  <div className="row g-4">
                    {/*<div className="card-head  col-md-12">
                      <h5 className="card-title">
                        Enter your sharelink details below
                      </h5>
                    </div>
                    <div className="form-group col-md-12">
                      <span className="form-note">
                        Specify the name of your website.
                      </span>
                  </div>*/}

                    <div className="form-group col-md-5">
                      <MutextField
                        required
                        id="title"
                        name="title"
                        type="text"
                        label="Sharelink Title"
                        inputProps={{ maxLength: 100 }}
                        defaultValue={this.state.title}
                        onChange={this.handleChange}
                        helperText="Enter the title here."
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^a-z0-9 ]/gi,
                            ""
                          );
                        }}
                        variant="outlined"
                        fullWidth
                      />
                    </div>
                    <div className="form-group col-md-5">
                      <MutextField
                        required
                        id="link_identifier"
                        name="link_identifier"
                        type="text"
                        label="Custom Link ID"
                        inputProps={{ maxLength: 100 }}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^a-z0-9]/gi,
                            ""
                          );
                        }}
                        defaultValue={this.state.link_identifier}
                        onChange={this.handleChange}
                        helperText="Custom link ID without spaces and special characters. Please check availability."
                        variant="outlined"
                        fullWidth
                      />
                    </div>
                    <div className="form-group col-md-2">
                      {this.state.availability_button === false ? (
                        <a
                          className="btn btn-dim btn-outline-success"
                          style={{ padding: "16px" }}
                          onClick={() => this.checkAvailability()}
                        >
                          {this.state.link_available === "1" ? (
                            <em className="icon ni ni-done"></em>
                          ) : null}
                          {"  "}
                          Check Availability
                        </a>
                      ) : (
                        <button
                          className="btn btn-dim btn-outline-success"
                          style={{ marginRight: "15px" }}
                          disabled
                        >
                          <em className="icon ni ni-swap"></em> Check
                          Availability
                          <div
                            className="spinner-grow spinner-grow-sm"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </button>
                      )}
                    </div>
                    <div className="form-group col-md-5">
                      <MutextField
                        id="status"
                        name="status"
                        select
                        label="Status"
                        defaultValue={this.state.status}
                        onChange={this.handleChange}
                        SelectProps={{
                          native: true,
                        }}
                        helperText="e.g Active or Inactive"
                        variant="outlined"
                        fullWidth
                      >
                        <option key="status1" value="1">
                          Active
                        </option>
                        <option key="status0" value="0">
                          Inactive
                        </option>
                      </MutextField>
                    </div>
                    <div
                      className="col-lg-12 col-md-12 col-xl-12"
                      style={{ padding: "20px", paddingTop: "0px" }}
                      id="sharelink_div"
                    >
                      <div id="accordion-2" className="accordion">
                        <div className="accordion-item">
                          <a
                            href="#"
                            className="accordion-head "
                            data-toggle="collapse"
                            data-target="#accordion-item-2-1"
                          >
                            <span className="accordion-icon-icon">
                              {this.state.theme ? (
                                <em className="icon ni ni-done"></em>
                              ) : (
                                <em className="icon ni ni-dashboard"></em>
                              )}
                            </span>
                            <h6 className="title ">
                              Select Theme <sup style={{ color: "red" }}>*</sup>{" "}
                              {"  "}
                            </h6>
                            <span className="accordion-icon"></span>
                          </a>
                          <div
                            className="accordion-body collapse show"
                            id="accordion-item-2-1"
                            data-parent="#accordion-2"
                          >
                            <div className="accordion-inner">
                              <div
                                className="col-lg-12 col-md-12 col-xl-12"
                                style={{
                                  marginLeft: "30px",
                                }}
                              >
                                {this.state.tableLoaderBlockImage === true ? (
                                  tableLoaderBlockImage()
                                ) : (
                                  <>
                                    {this.state.themesList ? (
                                      <div className="card card-bordered">
                                        <div className="card-inner">
                                          <div
                                            className="row g-4"
                                            style={{ padding: "0px" }}
                                          >
                                            {this.state.themesList.map(
                                              (themes) => (
                                                <div
                                                  className="col-md-3 col-sm-6"
                                                  key={`theme_key${themes.id}`}
                                                >
                                                  <div
                                                    className={this.setThemeClassMain(
                                                      themes.id
                                                    )}
                                                  >
                                                    <div className="pricingTable-header">
                                                      <img
                                                        src={`../../assets/images/${themes.theme_image}`}
                                                      />
                                                    </div>

                                                    <a
                                                      onClick={() =>
                                                        this.setTheme(themes.id)
                                                      }
                                                      className={this.setThemeClass(
                                                        themes.id
                                                      )}
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      {this.state.theme ===
                                                      themes.id ? (
                                                        <em className="icon ni ni-done"></em>
                                                      ) : null}
                                                      {"  "}
                                                      Choose Theme
                                                      <i className="fa fa-angle-right"></i>
                                                    </a>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                            {/*<div
                                              className="col-md-12 col-sm-12"
                                              style={{
                                                float: "right",
                                                textAlign: "right",
                                              }}
                                            >
                                              <a
                                                href="#"
                                                onClick={() => {
                                                  this.open_service_tab();
                                                }}
                                                className="btn btn-success"
                                              >
                                                <span>Next</span>
                                                <em className="icon ni ni-arrow-right"></em>
                                              </a>
                                              </div>*/}
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="accordion-item">
                          <a
                            href="#"
                            className="accordion-head collapsed"
                            data-toggle="collapse"
                            data-target="#accordion-item-2-7"
                            onClick={() => this.getServices()}
                          >
                            <span className="accordion-icon-icon">
                              {this.state.selected_service.length > 0 ? (
                                <em className="icon ni ni-done"></em>
                              ) : (
                                <em className="icon ni ni-list-round"></em>
                              )}
                            </span>
                            <h6 className="title ">
                              Select Services{" "}
                              <sup style={{ color: "red" }}>*</sup> {"  "}
                            </h6>
                            <span className="accordion-icon"></span>
                          </a>
                          <div
                            className="accordion-body collapse "
                            id="accordion-item-2-7"
                            data-parent="#accordion-2"
                          >
                            <div className="accordion-inner">
                              <div
                                className="col-lg-12 col-md-12 col-xl-12"
                                style={{
                                  marginLeft: "30px",
                                }}
                              >
                                {this.state.tableLoaderBlockService === true ? (
                                  tableLoaderBlockImage()
                                ) : (
                                  <>
                                    {this.state.serviceData ? (
                                      <div className="card card-bordered">
                                        <div className="card-inner">
                                          <div
                                            className="row g-4"
                                            style={{ padding: "0px" }}
                                          >
                                            {this.state.serviceData.map(
                                              (services) => (
                                                <div
                                                  className="col-md-3 col-sm-6"
                                                  key={`service_key${services.id}`}
                                                >
                                                  <div
                                                    className={this.setServiceClassMain(
                                                      services.id
                                                    )}
                                                  >
                                                    <div
                                                      className="pricingTable-header"
                                                      style={{
                                                        color: "#ffffff",
                                                        background: "#1ee0ac",
                                                        padding: "40px 0",
                                                      }}
                                                    >
                                                      <h3 className="heading">
                                                        {services.service_title}
                                                      </h3>
                                                      <span
                                                        className="subtitle"
                                                        style={{
                                                          textOverflow:
                                                            "ellipsis",
                                                          overflow: "hidden",
                                                          whiteSpace: "nowrap",
                                                          paddingLeft: "20px",
                                                          paddingRight: "20px",
                                                        }}
                                                      >
                                                        {services.product_desc}
                                                      </span>
                                                      <div className="price-value">
                                                        {services.service_type !==
                                                        "sms" ? (
                                                          <>
                                                            {services.rate &&
                                                            services.rate > 0
                                                              ? services.rate.toFixed(
                                                                  2
                                                                )
                                                              : "0.00"}
                                                          </>
                                                        ) : null}
                                                        {services.service_type ===
                                                        "sms" ? (
                                                          <>
                                                            {services.sms_in &&
                                                            services.sms_in > 0
                                                              ? services.sms_in.toFixed(
                                                                  2
                                                                )
                                                              : "0.00"}
                                                          </>
                                                        ) : null}
                                                        <span className="currency">
                                                          $
                                                        </span>
                                                        <span className="month">
                                                          /mo
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <ul
                                                      className="pricing-content"
                                                      style={{
                                                        minHeight: "160px",
                                                      }}
                                                    >
                                                      {services.service_type !==
                                                      "sms" ? (
                                                        <>
                                                          <li>
                                                            $
                                                            {services.nrc &&
                                                            services.nrc > 0
                                                              ? services.nrc.toFixed(
                                                                  4
                                                                )
                                                              : "0.00"}{" "}
                                                            NRC
                                                          </li>
                                                          <li>
                                                            $
                                                            {services.mrc &&
                                                            services.mrc > 0
                                                              ? services.mrc.toFixed(
                                                                  4
                                                                )
                                                              : "0.00"}{" "}
                                                            MRC
                                                          </li>
                                                          <li>
                                                            $
                                                            {services.rate &&
                                                            services.rate > 0
                                                              ? services.rate.toFixed(
                                                                  4
                                                                )
                                                              : "0.00"}{" "}
                                                            Rate/Min
                                                          </li>
                                                        </>
                                                      ) : null}
                                                      {services.service_type ===
                                                      "sms" ? (
                                                        <>
                                                          <li>
                                                            $
                                                            {services.sms_in &&
                                                            services.sms_in > 0
                                                              ? services.sms_in.toFixed(
                                                                  4
                                                                )
                                                              : "0.00"}{" "}
                                                            SMS In
                                                          </li>
                                                          <li>
                                                            $
                                                            {services.sms_out &&
                                                            services.sms_out > 0
                                                              ? services.sms_out.toFixed(
                                                                  4
                                                                )
                                                              : "0.00"}{" "}
                                                            SMS Out
                                                          </li>
                                                        </>
                                                      ) : null}
                                                      <li>
                                                        $
                                                        {services.cancellation_fee &&
                                                        services.cancellation_fee >
                                                          0
                                                          ? services.cancellation_fee.toFixed(
                                                              4
                                                            )
                                                          : "0.00"}{" "}
                                                        Cancellation Fee
                                                      </li>
                                                    </ul>
                                                    <a
                                                      onClick={() =>
                                                        this.setService(
                                                          services.id
                                                        )
                                                      }
                                                      className={this.setServiceClass(
                                                        services.id
                                                      )}
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      {this.state.selected_service.includes(
                                                        services.id
                                                      ) ? (
                                                        <em className="icon ni ni-done"></em>
                                                      ) : null}
                                                      {"  "}
                                                      Choose Service
                                                      <i className="fa fa-angle-right"></i>
                                                    </a>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="accordion-item">
                          <a
                            href="#"
                            className="accordion-head collapsed"
                            data-toggle="collapse"
                            data-target="#accordion-item-2-6"
                          >
                            <span className="accordion-icon-icon">
                              {this.state.selected_logo &&
                              this.state.selected_avatar ? (
                                <em className="icon ni ni-done"></em>
                              ) : (
                                <em className="icon ni ni-camera"></em>
                              )}
                            </span>
                            <h6 className="title">
                              Select Logo and Avatar{" "}
                              <sup style={{ color: "red" }}>*</sup>
                            </h6>
                            <span className="accordion-icon"></span>
                          </a>
                          <div
                            className="accordion-body collapse"
                            id="accordion-item-2-6"
                            data-parent="#accordion-2"
                          >
                            <div className="accordion-inner">
                              <div
                                className="col-lg-12 col-md-12 col-xl-12"
                                style={{
                                  marginLeft: "27px",
                                }}
                              >
                                <div className="card card-bordered">
                                  <div className="card-inner">
                                    <div
                                      className="row g-4"
                                      style={{ padding: "30px" }}
                                    >
                                      {this.state.logoData ? (
                                        <div className="col-lg-12 border-bottom">
                                          <h6 style={{ marginBottom: "20px" }}>
                                            Select Logo
                                          </h6>
                                          {this.state.logoData.map((images) => (
                                            <div
                                              className="col-md-3 col-lg-3 col-xxl-3 col-sm-6"
                                              style={{ float: "left" }}
                                              key={`logos${images.id}`}
                                            >
                                              <div
                                                className={this.setLogoClassMain(
                                                  images.id
                                                )}
                                              >
                                                <div
                                                  className="pricingTable-header"
                                                  style={{ height: "120px" }}
                                                >
                                                  <img
                                                    style={{
                                                      verticalAlign: "middle",
                                                      display: "inline-block",
                                                      height: "100%",
                                                    }}
                                                    src={`${APP_LIVE_URL}files_data/sharelinks/${images.picture}`}
                                                  />
                                                </div>

                                                <a
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() =>
                                                    this.setLogo(images.id)
                                                  }
                                                  className={this.setLogoClass(
                                                    images.id
                                                  )}
                                                >
                                                  {this.state.selected_logo ===
                                                  images.id ? (
                                                    <em className="icon ni ni-done"></em>
                                                  ) : null}
                                                  {"  "}
                                                  Choose Logo
                                                  <i className="fa fa-angle-right"></i>
                                                </a>
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      ) : null}

                                      {this.state.avatarData ? (
                                        <div className="col-lg-12">
                                          <h6 style={{ marginBottom: "20px" }}>
                                            Select Avatar
                                          </h6>
                                          {this.state.avatarData.map(
                                            (avatar) => (
                                              <div
                                                className="col-md-3 col-lg-3 col-xxl-3 col-sm-6"
                                                style={{ float: "left" }}
                                                key={`avatar${avatar.id}`}
                                              >
                                                <div
                                                  className={this.setAvatarClassMain(
                                                    avatar.id
                                                  )}
                                                >
                                                  <div
                                                    className="pricingTable-header"
                                                    style={{
                                                      minHeight: "150px",
                                                    }}
                                                  >
                                                    <img
                                                      style={{ width: "300px" }}
                                                      src={`${APP_LIVE_URL}files_data/sharelinks/${avatar.picture}`}
                                                    />
                                                  </div>

                                                  <a
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    className={this.setAvatarClass(
                                                      avatar.id
                                                    )}
                                                    onClick={() =>
                                                      this.setAvatar(avatar.id)
                                                    }
                                                  >
                                                    {this.state
                                                      .selected_avatar ===
                                                    avatar.id ? (
                                                      <em className="icon ni ni-done"></em>
                                                    ) : null}
                                                    Choose Avatar
                                                    <i className="fa fa-angle-right"></i>
                                                  </a>
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <a
                            href="#"
                            className="accordion-head collapsed"
                            data-toggle="collapse"
                            data-target="#accordion-item-2-2"
                          >
                            <span className="accordion-icon-icon">
                              {this.state.about_me_settings ? (
                                <em className="icon ni ni-done"></em>
                              ) : (
                                <em className="icon ni ni-align-justify"></em>
                              )}
                            </span>
                            <h6 className="title">Select About Me Settings</h6>
                            <span className="accordion-icon"></span>
                          </a>
                          <div
                            className="accordion-body collapse"
                            id="accordion-item-2-2"
                            data-parent="#accordion-2"
                          >
                            <div className="accordion-inner">
                              <div
                                className="col-lg-12 col-md-12 col-xl-12"
                                style={{
                                  marginLeft: "27px",
                                }}
                              >
                                <div className="card card-bordered">
                                  <div className="card-inner">
                                    <FormControlLabel
                                      style={{ marginBottom: "0px" }}
                                      control={
                                        <Checkbox
                                          checked={this.state.about_me_settings}
                                          onChange={this.handleChange}
                                          name="about_me_settings"
                                          id="about_me_settings"
                                          color="primary"
                                        />
                                      }
                                      label="About Me Selection"
                                    />
                                    {this.state.about_me_settings ? (
                                      <div
                                        className="row g-4"
                                        style={{ padding: "30px" }}
                                      >
                                        <div className="col-lg-12">
                                          <h6>Short Description</h6>
                                        </div>
                                        <div className="col-lg-12">
                                          <TextareaAutosize
                                            maxrows={4}
                                            name="about_me_short"
                                            id="about_me_short"
                                            aria-label=""
                                            placeholder=""
                                            defaultValue={
                                              this.state.about_me_short
                                            }
                                            onChange={this.handleChange}
                                            style={{
                                              width: "100%",
                                              height: "100px",
                                              borderColor:
                                                "rgba(0, 0, 0, 0.125)",
                                              borderRadius: "4px",
                                              padding: "5px",
                                            }}
                                            variant="outlined"
                                          />
                                        </div>
                                        <div className="col-lg-12">
                                          <h6>About Me</h6>
                                        </div>

                                        <div className="col-lg-12">
                                          <div className="form-group">
                                            <Editor
                                              id="about_me"
                                              name="about_me"
                                              apiKey="nb3xxzwletiuxjj0vxvl62020cjdnjddx0qmuck99x1knvbt"
                                              value={this.state.about_me}
                                              selector="textarea#full-featured"
                                              init={{
                                                height: 500,
                                                menubar: true,
                                                plugins: [
                                                  "code print preview importcss tinydrive searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                                                ],
                                                toolbar:
                                                  "code undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor casechange removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl | showcomments addcomment",
                                              }}
                                              onEditorChange={
                                                this.handleEditorChange
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <a
                            href="#"
                            className="accordion-head collapsed"
                            data-toggle="collapse"
                            data-target="#accordion-item-2-3"
                          >
                            <span className="accordion-icon-icon">
                              {this.state.contact_settings ? (
                                <em className="icon ni ni-done"></em>
                              ) : (
                                <em className="icon ni ni-contact"></em>
                              )}
                            </span>
                            <h6 className="title">Select Contact Settings</h6>
                            <span className="accordion-icon"></span>
                          </a>
                          <div
                            className="accordion-body collapse"
                            id="accordion-item-2-3"
                            data-parent="#accordion-2"
                          >
                            <div className="accordion-inner">
                              <div
                                className="col-lg-12 col-md-12 col-xl-12"
                                style={{
                                  marginLeft: "27px",
                                }}
                              >
                                <div className="card card-bordered">
                                  <div className="card-inner">
                                    <FormControlLabel
                                      style={{ marginBottom: "0px" }}
                                      control={
                                        <Checkbox
                                          checked={this.state.contact_settings}
                                          onChange={this.handleChange}
                                          name="contact_settings"
                                          id="contact_settings"
                                          color="primary"
                                        />
                                      }
                                      label="Contact Settings"
                                    />
                                    {this.state.contact_settings ? (
                                      <div className="row g-4">
                                        <div className="col-lg-12">
                                          <h4>Contact Details</h4>
                                        </div>

                                        <div className="col-lg-12">
                                          <FormControl
                                            fullWidth
                                            variant="outlined"
                                          >
                                            <InputLabel htmlFor="contact_email">
                                              Email Address
                                            </InputLabel>
                                            <OutlinedInput
                                              id="contact_email"
                                              name="contact_email"
                                              value={this.state.contact_email}
                                              onChange={this.handleChange}
                                              startAdornment={
                                                <InputAdornment position="start">
                                                  <em className="icon ni ni-emails"></em>
                                                </InputAdornment>
                                              }
                                              labelWidth={110}
                                            />
                                          </FormControl>
                                        </div>

                                        <div className="col-lg-12">
                                          <FormControl
                                            fullWidth
                                            variant="outlined"
                                          >
                                            <InputLabel htmlFor="contact_phone">
                                              Phone Number
                                            </InputLabel>
                                            <OutlinedInput
                                              id="contact_phone"
                                              name="contact_phone"
                                              type="number"
                                              inputProps={{ maxLength: 14 }}
                                              value={this.state.contact_phone}
                                              onChange={this.handleChange}
                                              startAdornment={
                                                <InputAdornment position="start">
                                                  <em className="icon ni ni-call-alt"></em>
                                                </InputAdornment>
                                              }
                                              labelWidth={115}
                                            />
                                          </FormControl>
                                        </div>

                                        <div className="col-lg-12">
                                          <FormControl
                                            fullWidth
                                            variant="outlined"
                                          >
                                            <InputLabel htmlFor="contact_address">
                                              Address
                                            </InputLabel>
                                            <OutlinedInput
                                              id="contact_address"
                                              name="contact_address"
                                              value={this.state.contact_address}
                                              onChange={this.handleChange}
                                              startAdornment={
                                                <InputAdornment position="start">
                                                  <em className="icon ni ni-home"></em>
                                                </InputAdornment>
                                              }
                                              labelWidth={70}
                                            />
                                          </FormControl>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="accordion-item">
                          <a
                            href="#"
                            className="accordion-head collapsed"
                            data-toggle="collapse"
                            data-target="#accordion-item-2-4"
                          >
                            <span className="accordion-icon-icon">
                              {this.state.social_settings ? (
                                <em className="icon ni ni-done"></em>
                              ) : (
                                <em className="icon ni ni-link-group"></em>
                              )}
                            </span>
                            <h6 className="title">Add Social Media Settings</h6>
                            <span className="accordion-icon"></span>
                          </a>
                          <div
                            className="accordion-body collapse"
                            id="accordion-item-2-4"
                            data-parent="#accordion-2"
                          >
                            <div className="accordion-inner">
                              <div
                                className="col-lg-12 col-md-12 col-xl-12"
                                style={{
                                  marginLeft: "27px",
                                }}
                              >
                                <div className="card card-bordered">
                                  <div className="card-inner">
                                    <FormControlLabel
                                      style={{ marginBottom: "0px" }}
                                      control={
                                        <Checkbox
                                          checked={this.state.social_settings}
                                          onChange={this.handleChange}
                                          name="social_settings"
                                          id="social_settings"
                                          color="primary"
                                        />
                                      }
                                      label="Social Media Links"
                                    />
                                    {this.state.social_settings ? (
                                      <div className="row g-4">
                                        <div className="col-lg-12">
                                          <h4>Social Media Links</h4>
                                        </div>

                                        <div className="col-lg-12">
                                          <FormControl
                                            fullWidth
                                            variant="outlined"
                                          >
                                            <InputLabel htmlFor="instagram_link">
                                              Instagram Link
                                            </InputLabel>
                                            <OutlinedInput
                                              id="instagram_link"
                                              name="instagram_link"
                                              value={this.state.instagram_link}
                                              onChange={this.handleChange}
                                              startAdornment={
                                                <InputAdornment position="start">
                                                  <em className="icon ni ni-instagram"></em>
                                                </InputAdornment>
                                              }
                                              labelWidth={110}
                                            />
                                          </FormControl>
                                        </div>

                                        <div className="col-lg-12">
                                          <FormControl
                                            fullWidth
                                            variant="outlined"
                                          >
                                            <InputLabel htmlFor="facebook_link">
                                              Facebook Link
                                            </InputLabel>
                                            <OutlinedInput
                                              id="facebook_link"
                                              name="facebook_link"
                                              value={this.state.facebook_link}
                                              onChange={this.handleChange}
                                              startAdornment={
                                                <InputAdornment position="start">
                                                  <em className="icon ni ni-facebook-fill"></em>
                                                </InputAdornment>
                                              }
                                              labelWidth={110}
                                            />
                                          </FormControl>
                                        </div>

                                        <div className="col-lg-12">
                                          <FormControl
                                            fullWidth
                                            variant="outlined"
                                          >
                                            <InputLabel htmlFor="twitter_link">
                                              Twitter Link
                                            </InputLabel>
                                            <OutlinedInput
                                              id="twitter_link"
                                              name="twitter_link"
                                              value={this.state.twitter_link}
                                              onChange={this.handleChange}
                                              startAdornment={
                                                <InputAdornment position="start">
                                                  <em className="icon ni ni-twitter-round"></em>
                                                </InputAdornment>
                                              }
                                              labelWidth={90}
                                            />
                                          </FormControl>
                                        </div>

                                        <div className="col-lg-12">
                                          <FormControl
                                            fullWidth
                                            variant="outlined"
                                          >
                                            <InputLabel htmlFor="linkedin_link">
                                              Linkedin Link
                                            </InputLabel>
                                            <OutlinedInput
                                              id="linkedin_link"
                                              name="linkedin_link"
                                              value={this.state.linkedin_link}
                                              onChange={this.handleChange}
                                              startAdornment={
                                                <InputAdornment position="start">
                                                  <em className="icon ni ni-linkedin-round"></em>
                                                </InputAdornment>
                                              }
                                              labelWidth={100}
                                            />
                                          </FormControl>
                                        </div>

                                        <div className="col-lg-12">
                                          <FormControl
                                            fullWidth
                                            variant="outlined"
                                          >
                                            <InputLabel htmlFor="youtube_link">
                                              Youtube Link
                                            </InputLabel>
                                            <OutlinedInput
                                              id="youtube_link"
                                              name="youtube_link"
                                              value={this.state.youtube_link}
                                              onChange={this.handleChange}
                                              startAdornment={
                                                <InputAdornment position="start">
                                                  <em className="icon ni ni-youtube"></em>
                                                </InputAdornment>
                                              }
                                              labelWidth={110}
                                            />
                                          </FormControl>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="accordion-item">
                          <a
                            href="#"
                            className="accordion-head collapsed"
                            data-toggle="collapse"
                            data-target="#accordion-item-2-5"
                          >
                            <span className="accordion-icon-icon">
                              {this.state.meta_title !== "" &&
                              this.state.meta_description !== "" ? (
                                <em className="icon ni ni-done"></em>
                              ) : (
                                <em className="icon ni ni-list-round"></em>
                              )}
                            </span>
                            <h6 className="title">SEO Settings</h6>
                            <span className="accordion-icon"></span>
                          </a>
                          <div
                            className="accordion-body collapse"
                            id="accordion-item-2-5"
                            data-parent="#accordion-2"
                          >
                            <div className="accordion-inner">
                              <div
                                className="col-lg-12 col-md-12 col-xl-12"
                                style={{
                                  marginLeft: "30px",
                                }}
                              >
                                <div className="card card-bordered">
                                  <div className="card-inner">
                                    <div
                                      className="row g-4"
                                      style={{ padding: "30px" }}
                                    >
                                      <div className="form-group col-md-12 col-lg-12 col-xxl-12">
                                        <MutextField
                                          required
                                          id="meta_title"
                                          name="meta_title"
                                          type="text"
                                          label="Sharelink SEO Title"
                                          inputProps={{ maxLength: 100 }}
                                          defaultValue={this.state.meta_title}
                                          onChange={this.handleChange}
                                          helperText="Enter the SEO title here."
                                          variant="outlined"
                                          fullWidth
                                        />
                                      </div>
                                      <div className="col-lg-12">
                                        <h6>Meta Description</h6>
                                      </div>
                                      <div className="col-lg-12">
                                        <TextareaAutosize
                                          maxrows={4}
                                          name="meta_description"
                                          id="meta_description"
                                          aria-label=""
                                          placeholder=""
                                          defaultValue={
                                            this.state.meta_description
                                          }
                                          onChange={this.handleChange}
                                          style={{
                                            width: "100%",
                                            height: "100px",
                                            borderColor: "rgba(0, 0, 0, 0.125)",
                                            borderRadius: "4px",
                                            padding: "15px",
                                          }}
                                          variant="outlined"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group text-right">
                        {this.state.loadingButton === true ? (
                          <button
                            className="btn btn-lg btn-primary"
                            type="button"
                            disabled
                          >
                            <span
                              className="spinner-grow spinner-grow-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span> Loading...</span>
                          </button>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-lg btn-primary"
                              onClick={this.saveAndPreview}
                              disabled={this.state.disabled}
                              style={{ marginRight: "10px" }}
                            >
                              <em className="icon ni ni-note-add"></em>
                              {"  "}Save & Preview
                            </button>
                            <button
                              type="button"
                              className="btn btn-lg btn-primary"
                              onClick={this.saveSharelink}
                              disabled={this.state.disabled}
                            >
                              <em className="icon ni ni-note-add"></em>
                              {"  "}Save Share Link
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* DETAIL MODAL End */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SharelinkSettings);
