import TextField from '@material-ui/core/TextField';
export default function BasicDatePicker(props) {

    return (
      <div className="form-group">
        <label className="form-label">
          {props.data.label}
          {props.data.required ? <sup style={{ color: "red" }}>*</sup> : null}
        </label>
        <div className="form-control-wrap">
          <input
            type={props.data.type}
            className={props.data.className}
            id={props.data.id}
            name={props.data.name}
            onChange={props.onChange}
            defaultValue={props.defaultValue !== "" && props.defaultValue !== null && props.defaultValue !== undefined ? new Date(props.defaultValue).toISOString().split('T')[0] : props.defaultValue}
            placeholder={props.data.placeholder}
            disabled={props.data.disabled}
            required={props.data.required}
          />
        </div>
      </div>
    );
}