import axios from "axios";

import $ from "jquery";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import { axiosConfig } from "./utility";

export const accountVerification = async (token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "kyc_client_portal/emailUserVerification",
          JSON.stringify({
            emailToken: token,
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
          }),
          axiosConfig
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const registerProfileInformation = async (data) => {
  console.log('registerProfileInformation',data);
    var res = await new Promise((resolve, reject) => {
      try {
        axios
          .post(
            process.env.REACT_APP_API_URL +
              "kyc_client_portal/registration_customer_profile_information",
            JSON.stringify(data),
            axiosConfig
          )
          .then((response) => {
            resolve(response);
          });
      } catch (error) {
        console.log("catch : ", error);
      }
    });

  return res;
};

export const registerBusinessDetails = async (data) => {
  //console.log('registerBusinessDetails',data);
    var res = await new Promise((resolve, reject) => {
      try {
        axios
          .post(
            process.env.REACT_APP_API_URL +
              "kyc_client_portal/registration_business_details",
            JSON.stringify(data),
            axiosConfig
          )
          .then((response) => {
            resolve(response);
          });
      } catch (error) {
        console.log("catch : ", error);
      }
    });

  return res;
};

export const registerFccUsfTaxDetails = async (data) => {
  //console.log('registerFccUsfTaxDetails',data);
    var res = await new Promise((resolve, reject) => {
      try {
        axios
          .post(
            process.env.REACT_APP_API_URL +
              "kyc_client_portal/registration_fcc_usf_tax_details",
            JSON.stringify(data),
            axiosConfig
          )
          .then((response) => {
            resolve(response);
          });
      } catch (error) {
        console.log("catch : ", error);
      }
    });

  return res;
};

export const registerBusinessReferences = async (data) => {
  //console.log('registerFccUsfTaxDetails',data);
    var res = await new Promise((resolve, reject) => {
      try {
        axios
          .post(
            process.env.REACT_APP_API_URL +
              "kyc_client_portal/registration_business_references",
            JSON.stringify(data),
            axiosConfig
          )
          .then((response) => {
            resolve(response);
          });
      } catch (error) {
        console.log("catch : ", error);
      }
    });

  return res;
};

export const registerBusinessCompliance = async (data) => {
  //console.log('registerFccUsfTaxDetails',data);
    var res = await new Promise((resolve, reject) => {
      try {
        axios
          .post(
            process.env.REACT_APP_API_URL +
              "kyc_client_portal/registration_compliance_and_legal",
            JSON.stringify(data),
            axiosConfig
          )
          .then((response) => {
            resolve(response);
          });
      } catch (error) {
        console.log("catch : ", error);
      }
    });

  return res;
};

export const registerOtherInformations = async (data) => {
  //console.log('registerFccUsfTaxDetails',data);
    var res = await new Promise((resolve, reject) => {
      try {
        axios
          .post(
            process.env.REACT_APP_API_URL +
              "kyc_client_portal/registration_other_information",
            JSON.stringify(data),
            axiosConfig
          )
          .then((response) => {
            resolve(response);
          });
      } catch (error) {
        console.log("catch : ", error);
      }
    });

  return res;
};

export const registerUploadDocuments = async (data) => {
  //console.log('registerUploadDocuments',data);
    var res = await new Promise((resolve, reject) => {
      try {
        axios
          .post(
            process.env.REACT_APP_API_URL +
              "kyc_client_portal/registration_upload_documents",
            JSON.stringify(data),
            axiosConfig
          )
          .then((response) => {
            resolve(response);
          });
      } catch (error) {
        console.log("catch : ", error);
      }
    });

  return res;
};

export const registerBusinessPurpose = async (data) => {
  //console.log('registerFccUsfTaxDetails',data);
    var res = await new Promise((resolve, reject) => {
      try {
        axios
          .post(
            process.env.REACT_APP_API_URL +
              "kyc_client_portal/registration_purpose_of_business",
            JSON.stringify(data),
            axiosConfig
          )
          .then((response) => {
            resolve(response);
          });
      } catch (error) {
        console.log("catch : ", error);
      }
    });

  return res;
};

export const registerClient = async (data) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "kyc_client_portal/register",
          JSON.stringify(data),
          axiosConfig
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const checkAgentAccount = async (data) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "kyc_client_portal/check_agent_account",
          JSON.stringify(data),
          axiosConfig
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const client_registration_data = async (data) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "kyc_client_portal/client_registration_data",
          JSON.stringify(data),
          axiosConfig
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};



export const unsignedAgreements = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/unsigned_agreements",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const checkLogin = async (email, password) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/login",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            email: email,
            password: password
          }),
          axiosConfig
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const forgotEmailSend = async (emailUser) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/forgotPassword",
          JSON.stringify({
            email: emailUser,
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
          }),
          axiosConfig
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const setNewPassword = async (email, password, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/setNewPassword",
          JSON.stringify({
            email: email,
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            password: password,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const getAgreementData = async (accountno, token, pdf_path) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agreements/get_agreement_data",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            pdf_path: pdf_path,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const saveGeneralAgreement = async (
  accountno,
  token,
  signature,
  content,
  agreement_id,
  agreement_accountno,
  vendor_signature
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agreements/clientAgreementSigning",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            signature: signature,
            agreement_content: content,
            vendor_signature: vendor_signature,
            agreement_id: agreement_id,
            agreement_accountno: agreement_accountno
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const getAccountData = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/dashboard",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const signedAgreements = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agreements/list_client_agreements",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const profileUpdate = async (
  accountno,
  token,
  name,
  title,
  comp_name,
  phone,
  address,
  city,
  states,
  zip,
  password
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/profile_update",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            name: name,
            title: title,
            comp_name: comp_name,
            phone: phone,
            address: address,
            city: city,
            states: states,
            zip: zip,
            password: password,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const listServices = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "services/list_client_services",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getWelcomeContent = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/get_welcome_content",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const serviceRatesUpdate = async (
  accountno,
  token,
  nrc,
  mrc,
  rate,
  serviceId,
  service_title,
  service_type,
  sms_in,
  sms_out,
  agent_mrc_comission,
  agent_nrc_comission,
  agent_rate_comission,
  default_mrc,
  default_rate,
  default_nrc,
  sms_in_comission,
  sms_out_comission,
  product_desc
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "services/update_service_rates",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            nrc: nrc,
            mrc: mrc,
            rate: rate,
            serviceId: serviceId,
            service_title: service_title,
            service_type: service_type,
            sms_in: sms_in,
            sms_out: sms_out,
            agent_mrc_comission,
            agent_nrc_comission: agent_nrc_comission,
            agent_rate_comission: agent_rate_comission,
            default_mrc: default_mrc,
            default_rate: default_rate,
            default_nrc: default_nrc,
            sms_in_comission: sms_in_comission,
            sms_out_comission: sms_out_comission,
            product_desc: product_desc,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const serviceDefaultUpdate = async (
  accountno,
  token,
  id,
  service_id,
  service_type,
  nrc,
  mrc,
  rate
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "services/update_service_default",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            id: id,
            service_id: service_id,
            service_type: service_type,
            nrc: nrc,
            mrc: mrc,
            rate: rate,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const getServiceDetails = async (accountno, token, service_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "services/get_service_detail",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            service_id: service_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const addNewService = async (
  accountno,
  token,
  nrc,
  mrc,
  rate,
  serviceId,
  select_service,
  service_type,
  title,
  service_title,
  service_desc,
  nrc_type,
  mrc_type,
  min_sms_in_type,
  min_sms_out_type,
  sms_in,
  sms_out,
  agent_mrc_comission,
  agent_nrc_comission,
  agent_rate_comission,
  defaultMrc,
  defaultRate,
  defaultNrc,
  sms_in_comission,
  sms_out_comission
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "services/add_new_service",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            nrc: nrc,
            mrc: mrc,
            rate: rate,
            serviceId: serviceId,
            select_service: select_service,
            service_type: service_type,
            title: title,
            service_title: service_title,
            service_desc: service_desc,
            nrc_type: nrc_type,
            mrc_type: mrc_type,
            min_sms_in_type: min_sms_in_type,
            min_sms_out_type: min_sms_out_type,
            sms_in: sms_in,
            sms_out: sms_out,
            agent_mrc_comission: agent_mrc_comission,
            agent_nrc_comission: agent_nrc_comission,
            agent_rate_comission: agent_rate_comission,
            defaultMrc: defaultMrc,
            defaultRate: defaultRate,
            defaultNrc: defaultNrc,
            sms_in_comission: sms_in_comission,
            sms_out_comission: sms_out_comission,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const addCsvFile = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "uploads/list_pages",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8;",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const listDefaultServices = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "services/list_default_services_client",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getDashboardCounts = async (
  accountno,
  token,
  start_date,
  end_date
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/count",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            start_date: start_date,
            end_date: end_date,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};
