import React, { Component, Fragment } from "react";
import {
  AppBar,
  Button,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { LOGO_VERIFY } from "../../../config/config";

import HelperClass from "../../../config/helperClass";
import { tableLoader } from "../../ContentLoaders/table_loader";
import Skeleton from "@material-ui/lab/Skeleton";
import { Helmet, HelmetProvider } from "react-helmet-async";

import "../ClientRegister/registration_step.css";

import { registerBusinessCompliance } from "../../../config/client_api_calls";




import MyCustomTextField from "../../../components/InputFields/TextField";
import MyCustomRadioField from "../../../components/InputFields/RadioField";
import MyCustomCheckBoxField from "../../../components/InputFields/CheckBox";
import MyCustomButton from "../../../components/InputFields/Button";

import "../ClientRegister/custom_radio.css";


const auth = new HelperClass();
export class Step6 extends Component {
  continue = (data) => {
    //e.preventDefault();
    this.props.nextStep(data);
  };
  first = (e) => {
    e.preventDefault();
    this.props.firstStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  submitForms = (e) => {
    e.preventDefault();
    const { jsonData } = this.props;
    const { keys } = this.props.values;

    let subpoena_complaint_detail_json = {      
      "required": jsonData.step_6.sub_steps[0].input_fields.subpoena_complaint_detail.required,
      "visible": jsonData.step_6.sub_steps[0].input_fields.subpoena_complaint_detail.visible  
    };
    let ftc_lawsuit_detail_json = {      
      "required": jsonData.step_6.sub_steps[0].input_fields.ftc_lawsuit_detail.required,
      "visible": jsonData.step_6.sub_steps[0].input_fields.ftc_lawsuit_detail.visible  
    };
    if(subpoena_complaint_detail_json.visible && subpoena_complaint_detail_json.required && this.state[keys.subpoena_complaint_detail] === ""){
      this.setState({
        errorMessage: "Please enter Legal Company Name for trade reference 1",
      });
      return;
    }
    this.setState({ errorMessage: "", successMessage: "", disabled: true });
    const step6_json = {
      keys: keys,
      values: {
        [keys.subpoena_complaint_detail]: this.state[keys.subpoena_complaint_detail],
        [keys.ftc_lawsuit_detail]: this.state[keys.ftc_lawsuit_detail],
        [keys.stir_shaken_framework]: this.state[keys.stir_shaken_framework],
        [keys.previous_complaints]: this.state[keys.previous_complaints],
        [keys.discontinued_service]: this.state[keys.discontinued_service],
        [keys.subpoena_id_complaint]: this.state[keys.subpoena_id_complaint],
        [keys.ftc_lawsuit]: this.state[keys.ftc_lawsuit],
        [keys.lawsuit_claim]: this.state[keys.lawsuit_claim],
      },
    };
    console.log('step_6_data', step6_json);
    this.submitCompliance(step6_json);
    //this.continue(step6_json);
  };

  submitCompliance = async (complianceData) => {
    const {
      signup_hash,
      steps_completed,
      steps_allowed
    } = this.props;
    //console.log('client_email',this.props);
    if (signup_hash !== "") {
      const {keys} = this.props.values;
      try {
        let response = await registerBusinessCompliance({          
          token: process.env.REACT_APP_API_TOKEN,
          api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
          signup_hash: signup_hash,
          subpoena_complaint_detail: complianceData.values[keys.subpoena_complaint_detail],
          ftc_lawsuit_detail: complianceData.values[keys.ftc_lawsuit_detail],
          stir_shaken_framework: complianceData.values[keys.stir_shaken_framework],
          previous_complaints: complianceData.values[keys.previous_complaints],
          discontinued_service: complianceData.values[keys.discontinued_service],
          subpoena_id_complaint: complianceData.values[keys.subpoena_id_complaint],
          ftc_lawsuit: complianceData.values[keys.ftc_lawsuit],
          lawsuit_claim: complianceData.values[keys.lawsuit_claim].toString(),                       
        });
        console.log('submitCompliance',response);
        if (response.data.status === 404 ||
            response.data.status === 422
        ){
          this.setState({
            errorMessage: 'Something went wrong. Please try again',
            successMessage: "",
            disabled: false,            
          });          
        }
        else if(response.data.status === 200 &&
          response.data.message === "success"){
            if(steps_completed === (steps_allowed - 1)){
              // this.setState({
              //   errorMessage: '',
              //   successMessage: "Successfully submitted data.",
              //   disabled: true,            
              // });
              this.props.successHandler();
            }
            else{
              this.continue(complianceData);
            }            
          }
      } catch (err) {
        this.setState({ errorMessage: "", successMessage: "", disabled: false });        
      }
    }

  };

  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      successMessage: "",
      [props.values.keys.lawsuit_claim]: [],
      disabled: props.jsonData.step_6.sub_steps[0].button_fields.continue.disabled,
    };
  }

  componentDidMount(){
    const {
      keys,
      values,
    } = this.props.values;

    let lawsuit_claim = values[keys.lawsuit_claim].length > 0 ? values[keys.lawsuit_claim].includes(',') ? values[keys.lawsuit_claim].split(',') : [values[keys.lawsuit_claim]] : [];


    this.setState({
      [keys.subpoena_complaint_detail]:values[keys.subpoena_complaint_detail],
      [keys.ftc_lawsuit_detail]:values[keys.ftc_lawsuit_detail],
      [keys.stir_shaken_framework]:values[keys.stir_shaken_framework],
      [keys.previous_complaints]:values[keys.previous_complaints] === "yes" ? true : values[keys.previous_complaints] === "no" ? false : values[keys.previous_complaints],
      [keys.discontinued_service]:values[keys.discontinued_service] === "yes" ? true : values[keys.discontinued_service] === "no" ? false : values[keys.discontinued_service],
      [keys.subpoena_id_complaint]:values[keys.subpoena_id_complaint] === "yes" ? true : values[keys.subpoena_id_complaint] === "no" ? false : values[keys.subpoena_id_complaint],
      [keys.ftc_lawsuit]:values[keys.ftc_lawsuit] === "yes" ? true : values[keys.ftc_lawsuit] === "no" ? false : values[keys.ftc_lawsuit],
      [keys.lawsuit_claim]:lawsuit_claim,
    });
  }

  modalHideIns = () => {
    window.$("#instructionsModal").modal("hide");
  };

  handleChangeCheck = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
    console.log(name + " " + value);
  };

  resendEmail = () => {};

  render() {
    const { jsonData, mStepsUITitles, mStepsUIDesc, steps_completed, steps_allowed } = this.props;
    const {values, keys} = this.props.values;

    return (
      <>
        <HelmetProvider>
          <Helmet>
            <link
              rel="stylesheet"
              href="../../assets/ui_latest/css/dashlite.css?ver=3.0.3"
            />
            <link
              id="skin-default"
              rel="stylesheet"
              href="../../assets/ui_latest/csstheme.css?ver=3.0.3"
            />
          </Helmet>
        </HelmetProvider>
        <div className="nk-block nk-block-lg" style={{ width: "100%" }}>
          <div
            className="card card-bordered"
            style={{
              border: "none",
              paddingTop: "2%",
              paddingLeft: "5%",
              paddingRight: "5%",
            }}
          >
            <form
              className="nk-stepper stepper-init is-alter nk-stepper-s1"
              action="#"
              onSubmit={this.submitForms}
              id="stepper-create-profile"
            >
              <div className="row g-0 col-sep col-sep-md col-sep-xl">
                <div className="col-md-4 col-xl-4">
                  <div className="card-inner" id="registration_div">
                    <ul className="nk-stepper-nav nk-stepper-nav-s1 stepper-nav is-vr">
                    <li className={steps_completed === 0 ? "current" : "done"}>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">
                              {mStepsUITitles[0]}
                            </div>
                            <div className="sub-text">
                              {mStepsUIDesc[0]}
                            </div>
                          </div>
                        </div>
                      </li>
                      {
                        steps_allowed >= 2 ? <li className={steps_completed === 1  ? "current" : steps_completed > 1 && steps_completed <= 3 ? "done" : ""}>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">
                            {mStepsUITitles[1]}
                            </div>
                            <div className="sub-text">
                            {mStepsUIDesc[1]}
                            </div>
                          </div>
                        </div>
                      </li> : null
                      }
                      {
                        steps_allowed >= 3 ? <li className={steps_completed === 2  ? "current" : steps_completed > 2 && steps_completed <= 4 ? "done" : ""}>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">
                            {mStepsUITitles[2]}
                            </div>
                            <div className="sub-text">
                            {mStepsUIDesc[2]}
                            </div>
                          </div>
                        </div>
                      </li> : null
                      }
                      {
                        steps_allowed >= 4 ? <li className={steps_completed === 3  ? "current" : steps_completed > 3 && steps_completed <= 5 ? "done" : ""}>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">
                            {mStepsUITitles[3]}
                            </div>
                            <div className="sub-text">
                            {mStepsUIDesc[3]}
                            </div>
                          </div>
                        </div>
                      </li> : null
                      }
                      {
                        steps_allowed >= 5 ? <li className={steps_completed === 4  ? "current" : steps_completed > 4 && steps_completed <= 6 ? "done" : ""}>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">
                            {mStepsUITitles[4]}
                            </div>
                            <div className="sub-text">
                            {mStepsUIDesc[4]}
                            </div>
                          </div>
                        </div>
                      </li> : null
                      }
                      {
                        steps_allowed >= 6 ? <li className={steps_completed === 5  ? "current" : steps_completed > 5 && steps_completed <= 7 ? "done" : ""}>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">
                            {mStepsUITitles[5]}
                            </div>
                            <div className="sub-text">
                            {mStepsUIDesc[5]}
                            </div>
                          </div>
                        </div>
                      </li> : null
                      }
                      {
                        steps_allowed >= 7 ? <li className={steps_completed === 6  ? "current" : steps_completed > 6 && steps_completed <= 8 ? "done" : ""}>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">
                            {mStepsUITitles[6]}
                            </div>
                            <div className="sub-text">
                            {mStepsUIDesc[6]}
                            </div>
                          </div>
                        </div>
                      </li> : null
                      }
                      {steps_allowed >= 8 ? (
                        <li
                          className={
                            steps_completed === 7
                              ? "current"
                              : steps_completed > 7 && steps_completed <= 9
                              ? "done"
                              : ""
                          }
                        >
                          <div className="step-item">
                            <div className="step-text">
                              <div className="lead-text">
                                {mStepsUITitles[7]}
                              </div>
                              <div className="sub-text">{mStepsUIDesc[7]}</div>
                            </div>
                          </div>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                </div>
                <div className="col-md-8 col-xl-8">
                  <div className="card-inner">
                    <div className="nk-stepper-content">
                      <div className="nk-stepper-steps stepper-steps">
                        {this.state.errorMessage !== "" ? (
                          <div
                            className="example-alert"
                            style={{ marginBottom: "20px" }}
                          >
                            <div className="alert alert-pro alert-danger">
                              <div className="alert-text">
                                <h4>Error</h4>
                                <p>{this.state.errorMessage}</p>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div className="nk-stepper-step">
                          <h4
                            className="title mb-3"
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {jsonData.step_6.sub_steps[0].title}
                          </h4>
                          <p
                            style={{
                              textAlign: "center",
                              marginLeft: "10%",
                              marginRight: "10%",
                            }}
                          >
                            {jsonData.step_6.sub_steps[0].description}
                          </p>
                          <div className="row g-3">
                            {jsonData.step_6.sub_steps[0].radio_fields
                              .stir_shaken_framework.visible ? (
                              <div className="col-12">
                                <div className="form-group">
                                  <label className="form-label">
                                    {
                                      jsonData.step_6.sub_steps[0].radio_fields
                                        .stir_shaken_framework.label
                                    }
                                    {jsonData.step_6.sub_steps[0].radio_fields
                                      .stir_shaken_framework.required ? (
                                      <sup style={{ color: "red" }}>*</sup>
                                    ) : null}
                                  </label>
                                  <ul className="custom-radio">
                                    {jsonData.step_6.sub_steps[0].radio_fields.stir_shaken_framework.data.map(
                                      (result, index) => (
                                        <li
                                          key={`stir_shaken_framework${index}`}
                                          className="col-lg-3"
                                        >
                                          <MyCustomRadioField
                                            data={result}
                                            onChange={this.handleChangeCheck}
                                            defaultChecked={
                                              result.id ===
                                              this.state[
                                                keys.stir_shaken_framework
                                              ]
                                                ? true
                                                : false
                                            }
                                          />
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </div>
                            ) : null}
                            {jsonData.step_6.sub_steps[0].checkbox_fields
                              .previous_complaints.visible ? (
                              <div className="col-12">
                                <div className="form-group">
                                  <ul className="custom-control-group custom-control-vertical custom-control-stacked w-100">
                                    {jsonData.step_6.sub_steps[0].checkbox_fields.previous_complaints.data.map(
                                      (result, index) => (
                                        <li key={`previous_complaints${index}`}>
                                          <MyCustomCheckBoxField
                                            data={result}
                                            onChange={this.handleChangeCheck}
                                            checked={
                                              this.state[
                                                keys.previous_complaints
                                              ]
                                            }
                                          />
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </div>
                            ) : null}
                            {jsonData.step_6.sub_steps[0].checkbox_fields
                              .discontinued_service.visible ? (
                              <div className="col-12">
                                <div className="form-group">
                                  <ul className="custom-control-group custom-control-vertical custom-control-stacked w-100">
                                    {jsonData.step_6.sub_steps[0].checkbox_fields.discontinued_service.data.map(
                                      (result, index) => (
                                        <li
                                          key={`discontinued_service${index}`}
                                        >
                                          <MyCustomCheckBoxField
                                            data={result}
                                            onChange={this.handleChangeCheck}
                                            checked={
                                              this.state[
                                                keys.discontinued_service
                                              ]
                                            }
                                          />
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </div>
                            ) : null}
                            {jsonData.step_6.sub_steps[0].checkbox_fields
                              .subpoena_id_complaint.visible ? (
                              <div className="col-12">
                                <div className="form-group">
                                  <ul className="custom-control-group custom-control-vertical custom-control-stacked w-100">
                                    {jsonData.step_6.sub_steps[0].checkbox_fields.subpoena_id_complaint.data.map(
                                      (result, index) => (
                                        <li
                                          key={`subpoena_id_complaint${index}`}
                                        >
                                          <MyCustomCheckBoxField
                                            data={result}
                                            onChange={this.handleChangeCheck}
                                            checked={
                                              this.state[
                                                keys.subpoena_id_complaint
                                              ]
                                            }
                                          />
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </div>
                            ) : null}
                            {jsonData.step_6.sub_steps[0].input_fields
                              .subpoena_complaint_detail.visible ? (
                              <div className="col-lg-12">
                                <div className="form-group">
                                  <label className="form-label">
                                    {
                                      jsonData.step_6.sub_steps[0].input_fields
                                        .subpoena_complaint_detail.label
                                    }
                                    {jsonData.step_6.sub_steps[0].input_fields
                                      .subpoena_complaint_detail.required ? (
                                      <sup style={{ color: "red" }}>*</sup>
                                    ) : null}
                                  </label>
                                  <textarea
                                    className={"form-control form-control-lg"}
                                    id={
                                      jsonData.step_6.sub_steps[0].input_fields
                                        .subpoena_complaint_detail.id
                                    }
                                    name={
                                      jsonData.step_6.sub_steps[0].input_fields
                                        .subpoena_complaint_detail.name
                                    }
                                    onChange={this.handleChangeCheck}
                                    defaultValue={`${
                                      values[keys.subpoena_complaint_detail]
                                    }`}
                                    placeholder={
                                      jsonData.step_6.sub_steps[0].input_fields
                                        .subpoena_complaint_detail.placeholder
                                    }
                                    disabled={
                                      jsonData.step_6.sub_steps[0].input_fields
                                        .subpoena_complaint_detail.disabled
                                    }
                                    required={
                                      jsonData.step_6.sub_steps[0].input_fields
                                        .subpoena_complaint_detail.required
                                    }
                                  />
                                  {/* <MyCustomTextField
                                        data={
                                          jsonData.step_6.sub_steps[0]
                                            .input_fields
                                            .subpoena_complaint_detail
                                        }
                                        onChange={this.handleChangeCheck}
                                        defaultValue={`${
                                          values[keys.subpoena_complaint_detail]
                                        }`}
                                      /> */}
                                </div>
                              </div>
                            ) : null}
                            {jsonData.step_6.sub_steps[0].checkbox_fields
                              .ftc_lawsuit.visible ? (
                              <div className="col-12">
                                <div className="form-group">
                                  <ul className="custom-control-group custom-control-vertical custom-control-stacked w-100">
                                    {jsonData.step_6.sub_steps[0].checkbox_fields.ftc_lawsuit.data.map(
                                      (result, index) => (
                                        <li key={`ftc_lawsuit${index}`}>
                                          <MyCustomCheckBoxField
                                            data={result}
                                            onChange={this.handleChangeCheck}
                                            checked={
                                              this.state[keys.ftc_lawsuit]
                                            }
                                          />
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </div>
                            ) : null}
                            {jsonData.step_6.sub_steps[0].input_fields
                              .ftc_lawsuit_detail.visible ? (
                              <div className="col-lg-12">
                                <div className="form-group">
                                  <label className="form-label">
                                    {
                                      jsonData.step_6.sub_steps[0].input_fields
                                        .ftc_lawsuit_detail.label
                                    }
                                    {jsonData.step_6.sub_steps[0].input_fields
                                      .ftc_lawsuit_detail.required ? (
                                      <sup style={{ color: "red" }}>*</sup>
                                    ) : null}
                                  </label>
                                  <textarea
                                    className={"form-control form-control-lg"}
                                    id={
                                      jsonData.step_6.sub_steps[0].input_fields
                                        .ftc_lawsuit_detail.id
                                    }
                                    name={
                                      jsonData.step_6.sub_steps[0].input_fields
                                        .ftc_lawsuit_detail.name
                                    }
                                    onChange={this.handleChangeCheck}
                                    defaultValue={`${
                                      values[keys.ftc_lawsuit_detail]
                                    }`}
                                    placeholder={
                                      jsonData.step_6.sub_steps[0].input_fields
                                        .ftc_lawsuit_detail.placeholder
                                    }
                                    disabled={
                                      jsonData.step_6.sub_steps[0].input_fields
                                        .ftc_lawsuit_detail.disabled
                                    }
                                    required={
                                      jsonData.step_6.sub_steps[0].input_fields
                                        .ftc_lawsuit_detail.required
                                    }
                                  />
                                  {/* <MyCustomTextField
                                    data={
                                      jsonData.step_6.sub_steps[0].input_fields
                                        .ftc_lawsuit_detail
                                    }
                                    onChange={this.handleChangeCheck}
                                    defaultValue={`${
                                      values[keys.ftc_lawsuit_detail]
                                    }`}
                                  /> */}
                                </div>
                              </div>
                            ) : null}
                            {jsonData.step_6.sub_steps[0].checkbox_fields
                              .lawsuit_claim.visible ? (
                              <div className="col-12">
                                <div className="form-group">
                                  <label className="form-label">
                                    {
                                      jsonData.step_6.sub_steps[0]
                                        .checkbox_fields.lawsuit_claim.label
                                    }
                                    {jsonData.step_6.sub_steps[0]
                                      .checkbox_fields.lawsuit_claim
                                      .required ? (
                                      <sup style={{ color: "red" }}>*</sup>
                                    ) : null}
                                  </label>

                                  <ul className="custom-control-group custom-control-horizontal custom-control-stacked w-100">
                                    {jsonData.step_6.sub_steps[0].checkbox_fields.lawsuit_claim.data.map(
                                      (result, index) => (
                                        <li key={`lawsuit_claim${index}`}>
                                          <MyCustomCheckBoxField
                                            data={result}
                                            checked={this.state[keys.lawsuit_claim].includes(result.value) ? true : false}
                                            onChange={(e) => {
                                              if(e.target.checked){
                                                this.setState({
                                                  [keys.lawsuit_claim]: [
                                                    ...this.state[keys.lawsuit_claim],
                                                    result.value
                                                  ]
                                                });
                                              }
                                              else{
                                                let filteredData = this.state[keys.lawsuit_claim].filter((data) => data !== result.value);
                                                this.setState({
                                                  [keys.lawsuit_claim]: filteredData
                                                });
                                              }
                                            }}
                                          />
                                          <span className="ms-2" />
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </div>
                            ) : null}
                          </div>                          
                        </div>
                        <div className="nk-stepper-step"></div>
                        <div className="nk-stepper-step"></div>
                        <div className="nk-stepper-step"></div>
                        <div className="nk-stepper-step text-center"></div>
                      </div>
                      <div className="row mt-5 gx-1 gy-1">
                      {jsonData.step_6.sub_steps[0].button_fields.back
                          .visible && steps_completed > 0 ? (
                          <div className="col-lg-6">
                            <MyCustomButton
                              data={
                                jsonData.step_6.sub_steps[0].button_fields.back
                              }
                              onClick={this.back}
                            />
                          </div>
                        ) : null}
                        {jsonData.step_6.sub_steps[0].button_fields.continue
                          .visible && steps_completed < steps_allowed - 1 ? (
                            <div className={steps_completed === 0 ? "col-lg-12" : "col-lg-6"}>
                            <MyCustomButton
                              data={
                                jsonData.step_6.sub_steps[0].button_fields
                                  .continue
                              }
                              //onClick={this.submitForms}
                              disabled={this.state.disabled}
                            />
                          </div>
                        ) : null}
                        {jsonData.step_6.sub_steps[0].button_fields.submit
                          .visible && steps_completed === (steps_allowed - 1)  ? (
                          <div className="col-lg-6">
                            <MyCustomButton
                              data={
                                jsonData.step_6.sub_steps[0].button_fields
                                  .submit
                              }
                              //onClick={this.submitForms}
                              disabled={this.state.disabled}
                            />
                          </div>
                        ) : null}
                      </div>
                      {/* <div
                        className="text-center"
                        style={{ marginTop: "25px" }}
                      >
                        Already have an account ? <a href="">Sign in instead</a>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default Step6;
