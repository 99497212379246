import { React, Component } from "react";

import axios from "axios";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { client_registration_data } from "../../../config/client_api_calls";


import $ from "jquery";
import { checkAgent } from "./../../../config/api_calls";
import PROMO_A from "../../../assets/images/promo-a.png";
import PROMO_B from "../../../assets/images/promo-b.png";
import PROMO_C from "../../../assets/images/promo-c.png";
import { COMPANY_NAME, LOGO, THEME_COLOR } from "../../../config/config";
import { ValidateEmail, axiosConfig, normalize } from "../../../config/utility";

//import { resendCodeFun } from "../../../config/api_calls";

import TextField from "./TextField";
import { tableLoader } from "../../../components/ContentLoaders/table_loader_register";
import HelperClass from "./../../../config/helperClass";

import Tooltip from "@material-ui/core/Tooltip";
import dateFormat, { masks } from "dateformat";

import jsonData from '../../../assets/kyc_registration.json';

import Step1 from "../../../components/Client/ClientRegister/Step1";
import Step2 from "../../../components/Client/ClientRegister/Step2";
import Step3 from "../../../components/Client/ClientRegister/Step3";
import Step4 from "../../../components/Client/ClientRegister/Step4";
import Step5 from "../../../components/Client/ClientRegister/Step5";
import Step6 from "../../../components/Client/ClientRegister/Step6";
import Step7 from "../../../components/Client/ClientRegister/Step7";
import Step8 from "../../../components/Client/ClientRegister/Step8";
const auth = new HelperClass();

export default class Register extends Component {
  constructor() {
    super();
    this.state = {
      steps_completed: 0,
      steps_allowed: 0,
      mStepUIData: [],
      mStepsUITitles: [],
      mStepsUIDesc: [],
      step1_data: {
        keys: {
          firstname: jsonData.step_1.sub_steps[0].input_fields.firstname.name,
          lastname: jsonData.step_1.sub_steps[0].input_fields.lastname.name,
          email: jsonData.step_1.sub_steps[0].input_fields.email.name,
          phone: jsonData.step_1.sub_steps[0].input_fields.phone.name,
          date_of_birth:
            jsonData.step_1.sub_steps[0].input_fields.date_of_birth.name,
          telegram_username:
            jsonData.step_1.sub_steps[0].input_fields.telegram_username.name,
          linkedin: jsonData.step_1.sub_steps[0].input_fields.linkedin.name,
          business_owner:
            jsonData.step_1.sub_steps[0].checkbox_fields.business_owner.data[0]
              .name,
          position: jsonData.step_1.sub_steps[0].input_fields.position.name,
          authority_to_sign:
            jsonData.step_1.sub_steps[0].checkbox_fields.signatury_authority
              .data[0].name,
          signature_data: "signature_data",
        },
        values: {
          [jsonData.step_1.sub_steps[0].input_fields.firstname.name]: "",
          [jsonData.step_1.sub_steps[0].input_fields.lastname.name]: "",
          [jsonData.step_1.sub_steps[0].input_fields.email.name]: "",
          [jsonData.step_1.sub_steps[0].input_fields.phone.name]: "",
          [jsonData.step_1.sub_steps[0].input_fields.date_of_birth.name]: "",
          [jsonData.step_1.sub_steps[0].input_fields.telegram_username.name]:
            "",
          [jsonData.step_1.sub_steps[0].input_fields.linkedin.name]: "",
          [jsonData.step_1.sub_steps[0].checkbox_fields.business_owner.data[0]
            .name]:
            jsonData.step_1.sub_steps[0].checkbox_fields.business_owner.data[0]
              .defaultChecked,
          [jsonData.step_1.sub_steps[0].checkbox_fields.signatury_authority
            .data[0].name]:
            jsonData.step_1.sub_steps[0].checkbox_fields.signatury_authority
              .data[0].defaultChecked,
          [jsonData.step_1.sub_steps[0].input_fields.position.name]: "",
          signature_data: "",
        },
      },
      step2_data: {
        keys: {
          legal_company_name:
            jsonData.step_2.sub_steps[0].input_fields.legal_company_name.name,
          date_of_incorporation:
            jsonData.step_2.sub_steps[0].input_fields.date_of_incorporation
              .name,
          other_trade_names:
            jsonData.step_2.sub_steps[0].input_fields.other_trade_names.name,
          main_telephone:
            jsonData.step_2.sub_steps[0].input_fields.main_telephone.name,
          fax_number: jsonData.step_2.sub_steps[0].input_fields.fax_number.name,
          website_url:
            jsonData.step_2.sub_steps[0].input_fields.website_url.name,
          company_linkedin:
            jsonData.step_2.sub_steps[0].input_fields.company_linkedin.name,
          entity_type:
            jsonData.step_2.sub_steps[0].radio_fields.entity_type.data[0].name,
          address_1_address:
            jsonData.step_2.sub_steps[0].input_fields.addresses.address_1
              .address.name,
          address_1_address_line_2:
            jsonData.step_2.sub_steps[0].input_fields.addresses.address_1
              .address_line_2.name,
          address_1_city:
            jsonData.step_2.sub_steps[0].input_fields.addresses.address_1.city
              .name,
          address_1_state:
            jsonData.step_2.sub_steps[0].input_fields.addresses.address_1.state
              .name,
          address_1_zip:
            jsonData.step_2.sub_steps[0].input_fields.addresses.address_1.zip
              .name,
          address_1_country:
            jsonData.step_2.sub_steps[0].input_fields.addresses.address_1
              .country.name,
          address_type:
            jsonData.step_2.sub_steps[0].input_fields.addresses.address_1
              .address_type.data[0].name,
          address_mark_primary:
            jsonData.step_2.sub_steps[0].input_fields.addresses.address_1
              .address_mark.data[0].name,
          address_mark_billing:
            jsonData.step_2.sub_steps[0].input_fields.addresses.address_1
              .address_mark.data[1].name,
          address_2_address:
            jsonData.step_2.sub_steps[0].input_fields.addresses.address_2
              .address.name,
          address_2_city:
            jsonData.step_2.sub_steps[0].input_fields.addresses.address_2.city
              .name,
          address_2_state:
            jsonData.step_2.sub_steps[0].input_fields.addresses.address_2.state
              .name,
          address_2_zip:
            jsonData.step_2.sub_steps[0].input_fields.addresses.address_2.zip
              .name,
          address_mark_primary_2:
            jsonData.step_2.sub_steps[0].input_fields.addresses.address_2
              .address_mark.data[0].name,
          address_mark_billing_2:
            jsonData.step_2.sub_steps[0].input_fields.addresses.address_2
              .address_mark.data[1].name,
          billing_address_address:
            jsonData.step_2.sub_steps[0].input_fields.addresses.billing_address
              .address.name,
          billing_address_city:
            jsonData.step_2.sub_steps[0].input_fields.addresses.billing_address
              .city.name,
          billing_address_state:
            jsonData.step_2.sub_steps[0].input_fields.addresses.billing_address
              .state.name,
          billing_address_zip:
            jsonData.step_2.sub_steps[0].input_fields.addresses.billing_address
              .zip.name,
          foreign_entity_international_address:
            jsonData.step_2.sub_steps[0].input_fields.addresses.foreign_entity
              .international_address.name,
          foreign_entity_country:
            jsonData.step_2.sub_steps[0].input_fields.addresses.foreign_entity
              .country.name,
        },
        values: {
          [jsonData.step_2.sub_steps[0].input_fields.legal_company_name.name]:
            "",
          [jsonData.step_2.sub_steps[0].input_fields.date_of_incorporation
            .name]: "",
          [jsonData.step_2.sub_steps[0].input_fields.other_trade_names.name]:
            "",
          [jsonData.step_2.sub_steps[0].input_fields.main_telephone.name]: "",
          [jsonData.step_2.sub_steps[0].input_fields.fax_number.name]: "",
          [jsonData.step_2.sub_steps[0].input_fields.website_url.name]: "",
          [jsonData.step_2.sub_steps[0].input_fields.company_linkedin.name]: "",
          [jsonData.step_2.sub_steps[0].radio_fields.entity_type.data[0].name]:
            [jsonData.step_2.sub_steps[0].radio_fields.entity_type.data[0].id],
          [jsonData.step_2.sub_steps[0].input_fields.addresses.address_1.address
            .name]: "",
          [jsonData.step_2.sub_steps[0].input_fields.addresses.address_1
            .address_line_2.name]: "",
          [jsonData.step_2.sub_steps[0].input_fields.addresses.address_1.city
            .name]: "",
          [jsonData.step_2.sub_steps[0].input_fields.addresses.address_1.state
            .name]: "",
          [jsonData.step_2.sub_steps[0].input_fields.addresses.address_1.zip
            .name]: "",
          [jsonData.step_2.sub_steps[0].input_fields.addresses.address_1.country
            .name]: "",
          [jsonData.step_2.sub_steps[0].input_fields.addresses.address_1
            .address_type.data[0].name]: "",
          // jsonData.step_2.sub_steps[0].input_fields.addresses.address_1
          //   .address_type.data[0].id,
          [jsonData.step_2.sub_steps[0].input_fields.addresses.address_1
            .address_mark.data[0].name]: false,
          [jsonData.step_2.sub_steps[0].input_fields.addresses.address_1
            .address_mark.data[1].name]: false,
          [jsonData.step_2.sub_steps[0].input_fields.addresses.address_2.address
            .name]: "",
          [jsonData.step_2.sub_steps[0].input_fields.addresses.address_2.city
            .name]: "",
          [jsonData.step_2.sub_steps[0].input_fields.addresses.address_2.state
            .name]: "",
          [jsonData.step_2.sub_steps[0].input_fields.addresses.address_2.zip
            .name]: "",
          [jsonData.step_2.sub_steps[0].input_fields.addresses.address_2
            .address_mark.data[0].name]: false,
          [jsonData.step_2.sub_steps[0].input_fields.addresses.address_2
            .address_mark.data[1].name]: false,
          [jsonData.step_2.sub_steps[0].input_fields.addresses.billing_address
            .address.name]: "",
          [jsonData.step_2.sub_steps[0].input_fields.addresses.billing_address
            .city.name]: "",
          [jsonData.step_2.sub_steps[0].input_fields.addresses.billing_address
            .state.name]: "",
          [jsonData.step_2.sub_steps[0].input_fields.addresses.billing_address
            .zip.name]: "",
          [jsonData.step_2.sub_steps[0].input_fields.addresses.foreign_entity
            .international_address.name]: "",
          [jsonData.step_2.sub_steps[0].input_fields.addresses.foreign_entity
            .country.name]: "",
        },
      },
      step3_data: {
        keys: {
          usf_registration_number:
            jsonData.step_3.sub_steps[0].input_fields.usf_registration_number
              .name,
          usf_registration_name:
            jsonData.step_3.sub_steps[0].input_fields.usf_registration_name
              .name,
          fein_no: jsonData.step_3.sub_steps[0].input_fields.fein_no.name,
          fcc_499_filer_id_no:
            jsonData.step_3.sub_steps[0].input_fields.fcc_499_filer_id_no.name,
          fcc_499_filer_id_name:
            jsonData.step_3.sub_steps[0].input_fields.fcc_499_filer_id_name
              .name,
          tax_exempt:
            jsonData.step_3.sub_steps[0].checkbox_fields.tax_exempt.data[0]
              .name,
          usf_registration_same:
            jsonData.step_3.sub_steps[0].checkbox_fields.usf_registration_same
              .data[0].name,
          company_name_for_entity_filer_id:
            jsonData.step_3.sub_steps[0].checkbox_fields
              .company_name_for_entity_filer_id.data[0].name,
        },
        values: {
          [jsonData.step_3.sub_steps[0].input_fields.usf_registration_number
            .name]: "",
          [jsonData.step_3.sub_steps[0].input_fields.usf_registration_name
            .name]: "",
          [jsonData.step_3.sub_steps[0].input_fields.fein_no.name]: "",
          [jsonData.step_3.sub_steps[0].input_fields.fcc_499_filer_id_no.name]:
            "",
          [jsonData.step_3.sub_steps[0].input_fields.fcc_499_filer_id_name
            .name]: "",
          [jsonData.step_3.sub_steps[0].checkbox_fields.tax_exempt.data[0]
            .name]:
            jsonData.step_3.sub_steps[0].checkbox_fields.tax_exempt.data[0]
              .defaultChecked,
          [jsonData.step_3.sub_steps[0].checkbox_fields.usf_registration_same
            .data[0].name]: false,
          [jsonData.step_3.sub_steps[0].checkbox_fields
            .company_name_for_entity_filer_id.data[0].name]: false,
        },
      },
      step4_data: {
        keys: {
          percentage_of_mix:
            jsonData.step_4.sub_steps[0].input_fields.percentage_of_mix.name,
          type_of_business:
            jsonData.step_4.sub_steps[0].radio_fields.type_of_business.data[0]
              .name,
          type_of_traffic:
            jsonData.step_4.sub_steps[0].radio_fields.type_of_traffic.data[0]
              .name,
          us_point_of_entry:
            jsonData.step_4.sub_steps[0].checkbox_fields.us_point_of_entry
              .data[0].name,
          business_description:
            jsonData.step_4.sub_steps[0].checkbox_fields.business_description
              .data[0].name,
          goods_services:
            jsonData.step_4.sub_steps[1].input_fields.goods_services.name,
          business_engaged_telemarketing:
            jsonData.step_4.sub_steps[1].checkbox_fields
              .business_engaged_telemarketing.data[0].name,
          pre_recorded_messages:
            jsonData.step_4.sub_steps[1].checkbox_fields.pre_recorded_messages
              .data[0].name,
          pre_recorded_messages_approval:
            jsonData.step_4.sub_steps[1].checkbox_fields
              .pre_recorded_messages_approval.data[0].name,
          transport_robocalls:
            jsonData.step_4.sub_steps[1].checkbox_fields.transport_robocalls
              .data[0].name,
          national_dns_subscription:
            jsonData.step_4.sub_steps[1].radio_fields.national_dns_subscription
              .data[0].name,
        },
        values: {
          [jsonData.step_4.sub_steps[0].input_fields.percentage_of_mix.name]:
            "",
          [jsonData.step_4.sub_steps[0].radio_fields.type_of_business.data[0]
            .name]:
            jsonData.step_4.sub_steps[0].radio_fields.type_of_business.data[0]
              .id,
          [jsonData.step_4.sub_steps[0].radio_fields.type_of_traffic.data[0]
            .name]:
            jsonData.step_4.sub_steps[0].radio_fields.type_of_traffic.data[0]
              .id,
          [jsonData.step_4.sub_steps[0].checkbox_fields.us_point_of_entry
            .data[0].name]:
            jsonData.step_4.sub_steps[0].checkbox_fields.us_point_of_entry
              .data[0].defaultChecked,
          [jsonData.step_4.sub_steps[0].checkbox_fields.business_description
            .data[0].name]: [],
          [jsonData.step_4.sub_steps[1].input_fields.goods_services.name]: "",
          [jsonData.step_4.sub_steps[1].checkbox_fields
            .business_engaged_telemarketing.data[0].name]:
            jsonData.step_4.sub_steps[1].checkbox_fields
              .business_engaged_telemarketing.data[0].defaultChecked,
          [jsonData.step_4.sub_steps[1].checkbox_fields.pre_recorded_messages
            .data[0].name]:
            jsonData.step_4.sub_steps[1].checkbox_fields.pre_recorded_messages
              .data[0].defaultChecked,
          [jsonData.step_4.sub_steps[1].checkbox_fields
            .pre_recorded_messages_approval.data[0].name]:
            jsonData.step_4.sub_steps[1].checkbox_fields
              .pre_recorded_messages_approval.data[0].defaultChecked,
          [jsonData.step_4.sub_steps[1].checkbox_fields.transport_robocalls
            .data[0].name]:
            jsonData.step_4.sub_steps[1].checkbox_fields.transport_robocalls
              .data[0].defaultChecked,
          [jsonData.step_4.sub_steps[1].radio_fields.national_dns_subscription
            .data[0].name]:
            jsonData.step_4.sub_steps[1].radio_fields.national_dns_subscription
              .data[0].id,
        },
      },
      step5_data: {
        keys: {
          legal_company_name:
            jsonData.step_5.sub_steps[0].input_fields.trade_reference_1
              .legal_company_name.name,
          main_telephone_number:
            jsonData.step_5.sub_steps[0].input_fields.trade_reference_1
              .main_telephone_number.name,
          email:
            jsonData.step_5.sub_steps[0].input_fields.trade_reference_1.email
              .name,
          address:
            jsonData.step_5.sub_steps[0].input_fields.trade_reference_1.address
              .address.name,
          city: jsonData.step_5.sub_steps[0].input_fields.trade_reference_1
            .address.city.name,
          state:
            jsonData.step_5.sub_steps[0].input_fields.trade_reference_1.address
              .state.name,
          zip: jsonData.step_5.sub_steps[0].input_fields.trade_reference_1
            .address.zip.name,
          legal_company_name2:
            jsonData.step_5.sub_steps[0].input_fields.trade_reference_2
              .legal_company_name.name,
          main_telephone_number2:
            jsonData.step_5.sub_steps[0].input_fields.trade_reference_2
              .main_telephone_number.name,
          email2:
            jsonData.step_5.sub_steps[0].input_fields.trade_reference_2.email
              .name,
          address2:
            jsonData.step_5.sub_steps[0].input_fields.trade_reference_2.address
              .address.name,
          city2:
            jsonData.step_5.sub_steps[0].input_fields.trade_reference_2.address
              .city.name,
          state2:
            jsonData.step_5.sub_steps[0].input_fields.trade_reference_2.address
              .state.name,
          zip2: jsonData.step_5.sub_steps[0].input_fields.trade_reference_2
            .address.zip.name,
        },
        values: {
          [jsonData.step_5.sub_steps[0].input_fields.trade_reference_1
            .legal_company_name.name]: "",
          [jsonData.step_5.sub_steps[0].input_fields.trade_reference_1
            .main_telephone_number.name]: "",
          [jsonData.step_5.sub_steps[0].input_fields.trade_reference_1.email
            .name]: "",
          [jsonData.step_5.sub_steps[0].input_fields.trade_reference_1.address
            .address.name]: "",
          [jsonData.step_5.sub_steps[0].input_fields.trade_reference_1.address
            .city.name]: "",
          [jsonData.step_5.sub_steps[0].input_fields.trade_reference_1.address
            .state.name]: "",
          [jsonData.step_5.sub_steps[0].input_fields.trade_reference_1.address
            .zip.name]: "",
          [jsonData.step_5.sub_steps[0].input_fields.trade_reference_2
            .legal_company_name.name]: "",
          [jsonData.step_5.sub_steps[0].input_fields.trade_reference_2
            .main_telephone_number.name]: "",
          [jsonData.step_5.sub_steps[0].input_fields.trade_reference_2.email
            .name]: "",
          [jsonData.step_5.sub_steps[0].input_fields.trade_reference_2.address
            .address.name]: "",
          [jsonData.step_5.sub_steps[0].input_fields.trade_reference_2.address
            .city.name]: "",
          [jsonData.step_5.sub_steps[0].input_fields.trade_reference_2.address
            .state.name]: "",
          [jsonData.step_5.sub_steps[0].input_fields.trade_reference_2.address
            .zip.name]: "",
        },
      },
      step6_data: {
        keys: {
          subpoena_complaint_detail:
            jsonData.step_6.sub_steps[0].input_fields.subpoena_complaint_detail
              .name,
          ftc_lawsuit_detail:
            jsonData.step_6.sub_steps[0].input_fields.ftc_lawsuit_detail.name,
          stir_shaken_framework:
            jsonData.step_6.sub_steps[0].radio_fields.stir_shaken_framework
              .data[0].name,
          previous_complaints:
            jsonData.step_6.sub_steps[0].checkbox_fields.previous_complaints
              .data[0].name,
          discontinued_service:
            jsonData.step_6.sub_steps[0].checkbox_fields.discontinued_service
              .data[0].name,
          subpoena_id_complaint:
            jsonData.step_6.sub_steps[0].checkbox_fields.subpoena_id_complaint
              .data[0].name,
          ftc_lawsuit:
            jsonData.step_6.sub_steps[0].checkbox_fields.ftc_lawsuit.data[0]
              .name,
          lawsuit_claim:
            jsonData.step_6.sub_steps[0].checkbox_fields.lawsuit_claim.data[0]
              .name,
        },
        values: {
          [jsonData.step_6.sub_steps[0].input_fields.subpoena_complaint_detail
            .name]: "",
          [jsonData.step_6.sub_steps[0].input_fields.ftc_lawsuit_detail.name]:
            "",
          [jsonData.step_6.sub_steps[0].radio_fields.stir_shaken_framework
            .data[0].name]:
            jsonData.step_6.sub_steps[0].radio_fields.stir_shaken_framework
              .data[0].id,
          [jsonData.step_6.sub_steps[0].checkbox_fields.previous_complaints
            .data[0].name]:
            jsonData.step_6.sub_steps[0].checkbox_fields.previous_complaints
              .data[0].defaultChecked,
          [jsonData.step_6.sub_steps[0].checkbox_fields.discontinued_service
            .data[0].name]:
            jsonData.step_6.sub_steps[0].checkbox_fields.discontinued_service
              .data[0].defaultChecked,
          [jsonData.step_6.sub_steps[0].checkbox_fields.subpoena_id_complaint
            .data[0].name]:
            jsonData.step_6.sub_steps[0].checkbox_fields.subpoena_id_complaint
              .data[0].defaultChecked,
          [jsonData.step_6.sub_steps[0].checkbox_fields.ftc_lawsuit.data[0]
            .name]:
            jsonData.step_6.sub_steps[0].checkbox_fields.ftc_lawsuit.data[0]
              .defaultChecked,
          [jsonData.step_6.sub_steps[0].checkbox_fields.lawsuit_claim.data[0]
            .name]: [],
        },
      },
      step7_data: {
        keys: {
          bank_name:
            jsonData.step_7.sub_steps[0].input_fields.bank_details.bank_name
              .name,
          bank_routing_number:
            jsonData.step_7.sub_steps[0].input_fields.bank_details
              .bank_routing_number.name,
          bank_account_number:
            jsonData.step_7.sub_steps[0].input_fields.bank_details
              .bank_account_number.name,
          us_bank_account:
            jsonData.step_7.sub_steps[0].checkbox_fields.us_bank_account.data[0]
              .name,
          customer_affirmation:
            jsonData.step_7.sub_steps[0].checkbox_fields.customer_affirmation
              .data[0].name,
        },
        values: {
          [jsonData.step_7.sub_steps[0].input_fields.bank_details.bank_name
            .name]: "",
          [jsonData.step_7.sub_steps[0].input_fields.bank_details
            .bank_routing_number.name]: "",
          [jsonData.step_7.sub_steps[0].input_fields.bank_details
            .bank_account_number.name]: "",
          [jsonData.step_7.sub_steps[0].checkbox_fields.us_bank_account.data[0]
            .name]:
            jsonData.step_7.sub_steps[0].checkbox_fields.us_bank_account.data[0]
              .defaultChecked,
          [jsonData.step_7.sub_steps[0].checkbox_fields.customer_affirmation
            .data[0].name]: [],
        },
      },
      step8_data: {
        keys: {
          passport:
            jsonData.step_8.sub_steps[0].radio_fields.document_type.data[0].id,
          national_id:
            jsonData.step_8.sub_steps[0].radio_fields.document_type.data[1].id,
          driving_license:
            jsonData.step_8.sub_steps[0].radio_fields.document_type.data[2].id,
        },
        values: {
          [jsonData.step_8.sub_steps[0].radio_fields.document_type.data[0].id]:
            [],
          [jsonData.step_8.sub_steps[0].radio_fields.document_type.data[1].id]:
            [],
          [jsonData.step_8.sub_steps[0].radio_fields.document_type.data[2].id]:
            [],
        },
      },
      tableLoader: true,
      pageError: "",
      step: 1,
    };
  }

  async componentDidMount() {    
    let signup_hash = this.props.match.params.signup_hash;    
    this.setState({
      signup_hash: signup_hash
    });
    if (signup_hash !== "") {      
      
      try {
        let response = await client_registration_data(
          {
            signup_hash: signup_hash,
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,            
          }
        );
        console.log("client_registration_data : ", response.data);
            if (
              response.data.status === 404 ||
              response.data.status === 422
            ) {
              if(response.data.message === 'record_not_found'){
                this.props.history.push(`/client/register/${signup_hash}/error`);
              }
              else if((response.data.errors !== undefined && response.data.errors !== "")){
                this.setState({
                  errorMessage: "There is some error.",
                  successMessage: "",
                  pageError: "",
                });
              }
              
            }
            else if (
              response.data.status === 200 &&
              response.data.message === "success"
            ){
              let steps_completed = response.data.steps_completed;
              
              let steps_allowed = response.data.steps_allowed;
              if(steps_completed === steps_allowed){
                this.successHandler();
                return;
              }
              let data = response.data.data;
              let client_actual_data = response.data.client_actual_data;
              let steps_ui_data = response.data.steps_ui_data;
              
              let step1_data = {...this.state[`step1_data`]};
              let step2_data = {...this.state[`step2_data`]};
              let step3_data = {...this.state[`step3_data`]};
              let step4_data = {...this.state[`step4_data`]};
              let step5_data = {...this.state[`step5_data`]};
              let step6_data = {...this.state[`step6_data`]};
              let step7_data = {...this.state[`step7_data`]};
              let step8_data = {...this.state[`step8_data`]};


              let mStepUIData = [];
              let mStepsUITitles = [];
              let mStepsUIDesc = [];

              for (let i = 0; i < steps_allowed; i++) {
                let dataObj = data[i];
                let steps_ui_data_obj = steps_ui_data[i];              
                if ("customer_profile" in steps_ui_data_obj) {
                  jsonData.step_1 = steps_ui_data_obj["customer_profile"];
                  mStepUIData.push("customer_profile");
                  mStepsUITitles.push(jsonData.step_1.title);
                  mStepsUIDesc.push(jsonData.step_1.description);
                  if(steps_completed === 0){
                    
                    const nameParts = client_actual_data[0]["name"].trim().split(' ');
                    step1_data.values[jsonData.step_1.sub_steps[0].input_fields.email.name] = client_actual_data[0]["email"];
                    step1_data.values[jsonData.step_1.sub_steps[0].input_fields.firstname.name] = nameParts[0];
                    step1_data.values[jsonData.step_1.sub_steps[0].input_fields.lastname.name] = nameParts.length > 1 ? nameParts.slice(1).join(' ') : '';
                    step1_data.values[jsonData.step_1.sub_steps[0].input_fields.phone.name] = client_actual_data[0]["phone"].replace('+', '');
                  }
                  
                  
                } else if ("business_details" in steps_ui_data_obj) {
                  jsonData.step_2 = steps_ui_data_obj["business_details"];
                  mStepUIData.push("business_details");
                  mStepsUITitles.push(jsonData.step_2.title);
                  mStepsUIDesc.push(jsonData.step_2.description);
                  if(steps_completed === 1){
                    console.log("Val: ",step2_data.values[jsonData.step_2.sub_steps[0].input_fields.addresses.address_1.address.name]);
                    if(step2_data.values[jsonData.step_2.sub_steps[0].input_fields.addresses.address_1.address.name] === ""){
                      step2_data.values[jsonData.step_2.sub_steps[0].input_fields.addresses.address_1.address.name] = client_actual_data[0]["address"];
                    }
                    if(step2_data.values[jsonData.step_2.sub_steps[0].input_fields.addresses.address_1.address_line_2.name] === ""){
                      if(client_actual_data[0]["address2"] && client_actual_data[0]["address2"] !== ""){
                        step2_data.values[jsonData.step_2.sub_steps[0].input_fields.addresses.address_1.address_line_2.name] = client_actual_data[0]["address2"];
                      }
                      
                    }
                    if(step2_data.values[jsonData.step_2.sub_steps[0].input_fields.addresses.address_1.city.name] === ""){
                      step2_data.values[jsonData.step_2.sub_steps[0].input_fields.addresses.address_1.city.name] = client_actual_data[0]["city"];
                    }
                    if(step2_data.values[jsonData.step_2.sub_steps[0].input_fields.addresses.address_1.state.name] === ""){
                      step2_data.values[jsonData.step_2.sub_steps[0].input_fields.addresses.address_1.state.name] = client_actual_data[0]["state"];
                    }
                    if(step2_data.values[jsonData.step_2.sub_steps[0].input_fields.addresses.address_1.zip.name] === ""){
                      step2_data.values[jsonData.step_2.sub_steps[0].input_fields.addresses.address_1.zip.name] = client_actual_data[0]["zip"];
                    }
                    if(step2_data.values[jsonData.step_2.sub_steps[0].input_fields.addresses.address_1.country.name] === ""){
                      step2_data.values[jsonData.step_2.sub_steps[0].input_fields.addresses.address_1.country.name] = client_actual_data[0]["country"];
                    }
                    
                  }
                 
                } else if ("fcc_usf_tax_details" in steps_ui_data_obj) {
                  jsonData.step_3 = steps_ui_data_obj["fcc_usf_tax_details"];
                  mStepUIData.push("fcc_usf_tax_details");
                  mStepsUITitles.push(jsonData.step_3.title);
                  mStepsUIDesc.push(jsonData.step_3.description);
                  
                } else if ("purpose_of_business" in steps_ui_data_obj) {
                  jsonData.step_4 = steps_ui_data_obj["purpose_of_business"];
                  mStepUIData.push("purpose_of_business");
                  mStepsUITitles.push(jsonData.step_4.title);
                  mStepsUIDesc.push(jsonData.step_4.description);
                  
                } else if ("business_references" in steps_ui_data_obj) {
                  jsonData.step_5 = steps_ui_data_obj["business_references"];
                  mStepUIData.push("business_references");
                  mStepsUITitles.push(jsonData.step_5.title);
                  mStepsUIDesc.push(jsonData.step_5.description);
                 
                } else if ("compliance_and_legal" in steps_ui_data_obj) {
                  jsonData.step_6 = steps_ui_data_obj["compliance_and_legal"];
                  mStepUIData.push("compliance_and_legal");
                  mStepsUITitles.push(jsonData.step_6.title);
                  mStepsUIDesc.push(jsonData.step_6.description);
                } else if ("other_information" in steps_ui_data_obj) {
                  jsonData.step_7 = steps_ui_data_obj["other_information"];
                  mStepUIData.push("other_information");
                  mStepsUITitles.push(jsonData.step_7.title);
                  mStepsUIDesc.push(jsonData.step_7.description);
                }
                else if ("document_upload" in steps_ui_data_obj) {
                  jsonData.step_8 = steps_ui_data_obj["document_upload"];
                  mStepUIData.push("document_upload");
                  mStepsUITitles.push(jsonData.step_8.title);
                  mStepsUIDesc.push(jsonData.step_8.description);
                }
                if (i < steps_completed) {
                  if("customer_profile" in dataObj){
                    step1_data.values = dataObj["customer_profile"];
                  }
                  else if("business_details" in dataObj){
                    step2_data.values = dataObj["business_details"];
                  }
                  else if("fcc_usf_tax_details" in dataObj){
                    step3_data.values = dataObj["fcc_usf_tax_details"];
                  }
                  else if("purpose_of_business" in dataObj){
                    step4_data.values = dataObj["purpose_of_business"];
                  }
                  else if("business_references" in dataObj){
                    step5_data.values = dataObj["business_references"];
                  }
                  else if("compliance_and_legal" in dataObj){
                    step6_data.values = dataObj["compliance_and_legal"];
                  }
                  else if("other_information" in dataObj){
                    step7_data.values = dataObj["other_information"];
                  }
                  else if("document_upload" in dataObj){
                    step8_data.values = dataObj["document_upload"];
                  }
                }
              }
              this.setState({
                step1_data: step1_data,
                step2_data: step2_data,
                step3_data: step3_data,
                step4_data: step4_data,
                step5_data: step5_data,
                step6_data: step6_data,
                step7_data: step7_data,
                step8_data: step8_data,
                mStepUIData: mStepUIData,
                mStepsUITitles: mStepsUITitles,
                mStepsUIDesc: mStepsUIDesc,
                steps_completed: steps_completed,
                steps_allowed: steps_allowed,
                tableLoader: false
              })
            }
      } catch (error) {
        this.setState({
          errorMessage: "There is some error.",
          successMessage: "",
          pageError: "",
        });
        console.log("catch : ", error);
      }
    } else {
      this.setState({
        errorMessage: "",
        successMessage: "",
        pageError:
          "Please enter the valid URL or contact admin for more details",
      });
    }
  }

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy, h:MM:ss TT");
  };

  handleChangeRadio = (event) => {
    const { target } = event;
    const value = target.type === "radio" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
    console.log("Main Handler: ", name + " - " + value);
  };

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
    console.log("Main Handler Radio: ", name + " - " + value);
  };

  nextStep = (data) => {
    const { mStepUIData,steps_completed,steps_allowed } = this.state;
    if(steps_completed === steps_allowed){
      return;
    }
    //console.log("STEP BEFORE: ", this.state.step);
    if(mStepUIData[steps_completed] === "customer_profile"){
      this.setState({
        step1_data: data,
        steps_completed: steps_completed + 1,
        errorMessage: "",
        successMessage: ""
      });
    }
    else if(mStepUIData[steps_completed] === "business_details"){
      this.setState({
        step2_data: data,
        steps_completed: steps_completed + 1,
        errorMessage: "",
        successMessage: ""
      });
    }
    else if(mStepUIData[steps_completed] === "fcc_usf_tax_details"){
      this.setState({
        step3_data: data,
        steps_completed: steps_completed + 1,
        errorMessage: "",
        successMessage: ""
      });
    }
    else if(mStepUIData[steps_completed] === "purpose_of_business"){
      this.setState({
        step4_data: data,
        steps_completed: steps_completed + 1,
        errorMessage: "",
        successMessage: ""
      });
    }
    else if(mStepUIData[steps_completed] === "business_references"){
      this.setState({
        step5_data: data,
        steps_completed: steps_completed + 1,
        errorMessage: "",
        successMessage: ""
      });
    }
    else if(mStepUIData[steps_completed] === "compliance_and_legal"){
      this.setState({
        step6_data: data,
        steps_completed: steps_completed + 1,
        errorMessage: "",
        successMessage: ""
      });
    }
    else if(mStepUIData[steps_completed] === "other_information"){
      this.setState({
        step7_data: data,
        steps_completed: steps_completed + 1,
        errorMessage: "",
        successMessage: ""
      });
    }
    else if(mStepUIData[steps_completed] === "document_upload"){
      this.setState({
        step8_data: data,
        steps_completed: steps_completed + 1,
        errorMessage: "",
        successMessage: ""
      });
    }
  };

  // nextStep = (data) => {
  //   const { step } = this.state;
  //   //console.log("STEP BEFORE: ", this.state.step);
  //   if(step === 1){
  //     this.setState({
  //       step1_data: data,
  //       step: step + 1,
  //       errorMessage: "",
  //       successMessage: ""
  //     });
  //   }
  //   else if(step === 2){
  //     this.setState({
  //       step2_data: data,
  //       step: step + 1,
  //       errorMessage: "",
  //       successMessage: ""
  //     });
  //   }
  //   else if(step === 3){
  //     this.setState({
  //       step3_data: data,
  //       step: step + 1,
  //       errorMessage: "",
  //       successMessage: ""
  //     });
  //   }
  //   else if(step === 4){
  //     this.setState({
  //       step4_data: data,
  //       step: step + 1,
  //       errorMessage: "",
  //       successMessage: ""
  //     });
  //   }
  //   else if(step === 5){
  //     this.setState({
  //       step5_data: data,
  //       step: step + 1,
  //       errorMessage: "",
  //       successMessage: ""
  //     });
  //   }
  //   else if(step === 6){
  //     this.setState({
  //       step6_data: data,
  //       step: step + 1,
  //       errorMessage: "",
  //       successMessage: ""
  //     });
  //   }
  //   else if(step === 7){
  //     this.setState({
  //       step7_data: data,
  //       errorMessage: "",
  //       successMessage: ""
  //     });
  //   }
  // };

  // Go to prev step
  prevStep = () => {
    
    // const { step } = this.state;
    // this.setState({
    //   step: step - 1,
    // });
    const { steps_completed } = this.state;
    if(steps_completed === 0){
      return;
    }
    this.setState({
      steps_completed: steps_completed - 1,
    });
  };

  successHandler = () => {
    let signup_hash = this.props.match.params.signup_hash;
    this.props.history.push(`/client/register/${signup_hash}/success`);
  };

  firstStep = () => {
    this.setState({
      step: 1,
    });
  };

  formSubmit = async () => {
    //e.preventDefault();
    // let {
    //   name,
    //   email,
    //   password,
    //   confirm_password,
    //   phone,
    //   company_name,
    //   title,
    //   filer_id,
    //   no_of_employes,
    //   super_agent_accountno,
    // } = this.state;
    // var is_validated = true;
    // if (name === "") {
    //   is_validated = false;
    // }
    // if (email === "") {
    //   is_validated = false;
    // }
    // if (password === "") {
    //   is_validated = false;
    // }
    // if (phone === "") {
    //   is_validated = false;
    // }
    // if (company_name === "") {
    //   is_validated = false;
    // }
    // if (title === "") {
    //   is_validated = false;
    // }
    // if (no_of_employes === "") {
    //   is_validated = false;
    // }    

    // if (is_validated) {
    //   try {
    //     this.setState({          
    //       disabled: true,
    //     });
    //     let clientRegisterService = await registerClient(
    //       {
    //         name: name,
    //         email: email,
    //         password: password,
    //         token: process.env.REACT_APP_API_TOKEN,
    //         api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
    //         agent_accountno: super_agent_accountno,
    //         phone: phone,
    //         comp_name: company_name,
    //         title: title,
    //         filer_id: filer_id,
    //         no_of_employes: no_of_employes,
    //       }
    //     );
    //     console.log("response.data : ", clientRegisterService.data);
    //         if (
    //           clientRegisterService.data.status === 404 ||
    //           clientRegisterService.data.status === 422 ||
    //           clientRegisterService.data.errors !== ""
    //         ) {
    //           this.setState({
    //             errorMessage: "Invalid Email OR Password",
    //             disabled: false,
    //           });
    //         }

    //         if (
    //           clientRegisterService.data.status === 404 &&
    //           clientRegisterService.data.message === "Account Already Exists"
    //         ) {
    //           this.setState({
    //             errorMessage: clientRegisterService.data.message,
    //             disabled: false,
    //           });
    //         } else if (
    //           clientRegisterService.data.status === 200 &&
    //           clientRegisterService.data.message === "Success"
    //         ) {
    //           $("#resendBtn").show();
    //           $("#signupBtn").hide();

    //           this.setState({
    //             successMessage: ` Congrats! Successfully created your profile. Email
    //             sent to your account. To complete verification
    //             process please go to your inbox and click on
    //             verification link in the email.`,
    //             errorMessage: "",
    //             fieldsHide: true,
    //           });
    //         } else {
    //           this.setState({
    //             errorMessage: ` There is some error while creating the account.`,
    //             successMessage: "",
    //             fieldsHide: true,
    //           });
    //         }
    //   } catch (error) {
    //     console.log("catch : ", error);
    //     this.setState({
    //       errorMessage: "There is some error while creating the account.",
    //       disabled: false,
    //     });
    //   }
    // } else {
    //   this.setState({
    //     errorMessage: "Please enter the required fields.",
    //     disabled: false,
    //   });
    // }
  };

  downloadCsv = async () => {
    console.log("HERE FILE: ");

    /*if (page_id !== "") {
      const reportsData = await getCSVData(
        auth.getAccount(),
        auth.getToken(),
        page_id
      );
      console.log("downloadCsv: ", reportsData.data.data);

      if (
        reportsData.data.status === 403 ||
        reportsData.data.errors === "authentication missing" ||
        reportsData.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (reportsData.data.status === 404) {
        this.setState({
          dErrorMessage: "There is some error.",
        });
        //window.location.replace("/error");
      } else if (
        reportsData.data.status === 200 &&
        reportsData.data.message === "success"
      ) {
        var url = reportsData.data.data;
        const response = await fetch(url);
        const data = await response.text();
        const blob = new Blob([data], { type: "data:text/csv;charset=utf-8," });
        const blobURL = window.URL.createObjectURL(blob);

        // Create new tag for download file
        const anchor = document.createElement("a");
        anchor.download = page_id;
        anchor.href = blobURL;
        anchor.dataset.downloadurl = [
          "text/csv",
          anchor.download,
          anchor.href,
        ].join(":");
        anchor.click();

        // Remove URL.createObjectURL. The browser should not save the reference to the file.
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          URL.revokeObjectURL(blobURL);
        }, 100);
      } else {
        this.setState({
          dErrorMessage: "There is some error.",
        });
      }
    } else {
      this.setState({
        dErrorMessage: "File does not exists",
      });
    }*/
  };

  render() {
    const {
      step1_data,
      step2_data,
      step3_data,
      step4_data,
      step5_data,
      step6_data,
      step7_data,
      step8_data,
      step,
      mStepUIData,
      steps_completed
    } = this.state;
    //console.log('step_1_data',step1_data);
    const values = {
      step1_data,
      step2_data,
      step3_data,
      step4_data,
      step5_data,
      step6_data,
      step7_data,
      step8_data
    };

    return (
      <>
        <HelmetProvider>
          <Helmet>
            <meta name="description" content="Client SignUp" />
            <title>Client SignUp</title>
          </Helmet>
        </HelmetProvider>
        <div
          className="nk-body npc-crypto ui-clean pg-auth"
          id="register_container"
        >
          <div className="nk-app-root">
            <div className="nk-block nk-block-middle nk-auth-body" style={{
              textAlign:"center",
              padding: "1.25rem 1.25rem 0.5rem 1.25rem"
            }}>
              <div className="brand-logo">
                <a href="/" className="logo-link">
                  <img                    
                    src="../../../assets/images/logo.png"
                  />
                </a>
              </div>
            </div>
            <div className="nk-split nk-split-page" style={{                  
                  paddingTop: "1%"
                }}>              
              <div
                className="nk-split-content nk-split-stretch bg-lighter d-flex "
                id="registration_step"
                data-content="athPromo"
                data-toggle-screen="lg"
                data-toggle-overlay="true"
                style={{
                  backgroundColor: "#fff",
                  background: "#ffffff",
                  borderLeft: "1px solid #e5sde9f2",
                }}
              >
                {this.state.pageError !== "" ? (
                  <div
                    className="nk-wrap nk-wrap-nosidebar"
                    style={{
                      width: "80%",
                      marginRight: "10%",
                      marginLeft: "10%",
                    }}
                  >
                    <div className="nk-content ">
                      <div className="nk-block nk-block-middle wide-md mx-auto">
                        <div className="nk-block-content nk-error-ld text-center">
                          <img
                            className="nk-error-gfx"
                            src="../../../../assets/images/error-404_1.png"
                            alt=""
                          />
                          <div className="wide-xs mx-auto">
                            <h3 className="nk-error-title">
                              Oops! Why you’re here?
                            </h3>
                            <p className="nk-error-text">
                              {this.state.pageError}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {this.state.tableLoader === true ? (
                      tableLoader()
                    ) : (
                      <>
                        {/* START DATATABLE */}

                        {(() => {
                          console.log('Step after',step);
                          switch (mStepUIData[steps_completed]) {
                            default:
                              return (
                                <h1>
                                  User Forms not working. Enable Javascript!
                                </h1>
                              );
                            case "customer_profile":
                              return (
                                <Step1
                                  nextStep={this.nextStep}
                                  handleChange={this.handleChange}
                                  values={values.step1_data}
                                  jsonData={jsonData}
                                  signup_hash={this.state.signup_hash}
                                  mStepsUITitles={this.state.mStepsUITitles}
                                  mStepsUIDesc={this.state.mStepsUIDesc}
                                  steps_completed={this.state.steps_completed}
                                  steps_allowed={this.state.steps_allowed}
                                  successHandler={this.successHandler}
                                />
                              );
                            case "business_details":
                              return (
                                <Step2
                                  nextStep={this.nextStep}
                                  prevStep={this.prevStep}
                                  handleChange={this.handleChange}
                                  values={values.step2_data}
                                  jsonData={jsonData}
                                  signup_hash={this.state.signup_hash}
                                  mStepsUITitles={this.state.mStepsUITitles}
                                  mStepsUIDesc={this.state.mStepsUIDesc}
                                  steps_completed={this.state.steps_completed}
                                  steps_allowed={this.state.steps_allowed}
                                  successHandler={this.successHandler}
                                />
                              );
                            case "fcc_usf_tax_details":
                              return (
                                <Step3
                                  nextStep={this.nextStep}
                                  prevStep={this.prevStep}
                                  values={values.step3_data}
                                  handleChange={this.handleChange}
                                  jsonData={jsonData}
                                  signup_hash={this.state.signup_hash}
                                  mStepsUITitles={this.state.mStepsUITitles}
                                  mStepsUIDesc={this.state.mStepsUIDesc}
                                  steps_completed={this.state.steps_completed}
                                  steps_allowed={this.state.steps_allowed}
                                  successHandler={this.successHandler}
                                />
                              );
                            case "purpose_of_business":
                              return (
                                <Step4
                                  nextStep={this.nextStep}
                                  prevStep={this.prevStep}
                                  handleChange={this.handleChange}
                                  values={values.step4_data}
                                  jsonData={jsonData}
                                  signup_hash={this.state.signup_hash}
                                  mStepsUITitles={this.state.mStepsUITitles}
                                  mStepsUIDesc={this.state.mStepsUIDesc}
                                  steps_completed={this.state.steps_completed}
                                  steps_allowed={this.state.steps_allowed}
                                  successHandler={this.successHandler}
                                />
                              );
                            case "business_references":
                              return (
                                <Step5
                                  nextStep={this.nextStep}
                                  prevStep={this.prevStep}
                                  handleChange={this.handleChange}
                                  values={values.step5_data}
                                  jsonData={jsonData}
                                  signup_hash={this.state.signup_hash}
                                  mStepsUITitles={this.state.mStepsUITitles}
                                  mStepsUIDesc={this.state.mStepsUIDesc}
                                  steps_completed={this.state.steps_completed}
                                  steps_allowed={this.state.steps_allowed}
                                  successHandler={this.successHandler}
                                />
                              );
                            case "compliance_and_legal":
                              return (
                                <Step6
                                  nextStep={this.nextStep}
                                  prevStep={this.prevStep}
                                  handleChange={this.handleChange}
                                  values={values.step6_data}
                                  jsonData={jsonData}
                                  signup_hash={this.state.signup_hash}
                                  mStepsUITitles={this.state.mStepsUITitles}
                                  mStepsUIDesc={this.state.mStepsUIDesc}
                                  steps_completed={this.state.steps_completed}
                                  steps_allowed={this.state.steps_allowed}
                                  successHandler={this.successHandler}
                                />
                              );
                            case "other_information":
                              return (
                                <Step7
                                  nextStep={this.nextStep}
                                  prevStep={this.prevStep}
                                  handleChange={this.handleChange}
                                  values={values.step7_data}
                                  jsonData={jsonData}
                                  signup_hash={this.state.signup_hash}
                                  mStepsUITitles={this.state.mStepsUITitles}
                                  mStepsUIDesc={this.state.mStepsUIDesc}
                                  steps_completed={this.state.steps_completed}
                                  steps_allowed={this.state.steps_allowed}
                                  successHandler={this.successHandler}
                                />
                              );
                            case "document_upload":
                              return (
                                <Step8
                                  nextStep={this.nextStep}
                                  prevStep={this.prevStep}
                                  handleChange={this.handleChange}
                                  values={values.step8_data}
                                  jsonData={jsonData}
                                  signup_hash={this.state.signup_hash}
                                  mStepsUITitles={this.state.mStepsUITitles}
                                  mStepsUIDesc={this.state.mStepsUIDesc}
                                  steps_completed={this.state.steps_completed}
                                  steps_allowed={this.state.steps_allowed}
                                  successHandler={this.successHandler}
                                />
                              );
                            //
                            //case 6:
                            //return <Step6 firstStep={this.firstStep} />;
                          }
                        })()}

                        {/* {(() => {
                          console.log('Step after',step);
                          switch (step) {
                            default:
                              return (
                                <h1>
                                  User Forms not working. Enable Javascript!
                                </h1>
                              );
                            case 1:
                              return (
                                <Step1
                                  nextStep={this.nextStep}
                                  handleChange={this.handleChange}
                                  values={values.step1_data}
                                  jsonData={jsonData}
                                  signup_hash={this.state.signup_hash}
                                />
                              );
                            case 2:
                              return (
                                <Step2
                                  nextStep={this.nextStep}
                                  prevStep={this.prevStep}
                                  handleChange={this.handleChange}
                                  values={values.step2_data}
                                  jsonData={jsonData}
                                  signup_hash={this.state.signup_hash}                                  

                                />
                              );
                            case 3:
                              return (
                                <Step3
                                  nextStep={this.nextStep}
                                  prevStep={this.prevStep}
                                  values={values.step3_data}
                                  handleChange={this.handleChange}
                                  jsonData={jsonData}
                                  signup_hash={this.state.signup_hash}                                  
                                />
                              );
                            case 4:
                              return (
                                <Step4
                                  nextStep={this.nextStep}
                                  prevStep={this.prevStep}
                                  handleChange={this.handleChange}
                                  values={values.step4_data}
                                  jsonData={jsonData}
                                  signup_hash={this.state.signup_hash}                                  
                                />
                              );
                            case 5:
                              return (
                                <Step5
                                  nextStep={this.nextStep}
                                  prevStep={this.prevStep}
                                  handleChange={this.handleChange}
                                  values={values.step5_data}
                                  jsonData={jsonData}
                                  signup_hash={this.state.signup_hash}                                  
                                />
                              );
                            case 6:
                              return (
                                <Step6
                                  nextStep={this.nextStep}
                                  prevStep={this.prevStep}
                                  handleChange={this.handleChange}
                                  values={values.step6_data}
                                  jsonData={jsonData}
                                  signup_hash={this.state.signup_hash}
                                />
                              );
                            case 7:
                              return (
                                <Step7
                                  nextStep={this.nextStep}
                                  prevStep={this.prevStep}
                                  handleChange={this.handleChange}
                                  values={values.step7_data}
                                  jsonData={jsonData}
                                  signup_hash={this.state.signup_hash}
                                />
                              );
                            //
                            //case 6:
                            //return <Step6 firstStep={this.firstStep} />;
                          }
                        })()} */}
                        {/* END TABLE */}
                      </>
                    )}
                  </>
                )}
                <div style={{ clear: "both" }}></div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
