import React, { Component, Fragment, useRef } from "react";
import {
  AppBar,
  Button,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { LOGO_VERIFY } from "../../../config/config";

import HelperClass from "../../../config/helperClass";
import { tableLoader } from "../../ContentLoaders/table_loader";
import Skeleton from "@material-ui/lab/Skeleton";

import { Helmet, HelmetProvider } from "react-helmet-async";



import MyCustomTextField from "../../../components/InputFields/TextField";
import MyCustomRadioField from "../../../components/InputFields/RadioField";
import MyCustomCheckBoxField from "../../../components/InputFields/CheckBox";
import MyCustomButton from "../../../components/InputFields/Button";
import MyCustomDropZone from "../../../components/InputFields/KycDropZone";
import "../ClientRegister/registration_step.css";

import { registerUploadDocuments } from "../../../config/client_api_calls";



const auth = new HelperClass();
export class Step8 extends Component {
  continue = (data) => {
    //e.preventDefault();
    this.props.nextStep(data);
  };
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  submitForms = (e) => {
    e.preventDefault();
    const { jsonData } = this.props;
    const { keys } = this.props.values;

    let passport_json = {      
      "required": jsonData.step_8.sub_steps[0].radio_fields.document_type.data[0].required,
      "visible": jsonData.step_8.sub_steps[0].radio_fields.document_type.data[0].visible,
      "id": jsonData.step_8.sub_steps[0].radio_fields.document_type.data[0].id  
    };
    let national_id_json = {      
      "required": jsonData.step_8.sub_steps[0].radio_fields.document_type.data[1].required,
      "visible": jsonData.step_8.sub_steps[0].radio_fields.document_type.data[1].visible,
      "id": jsonData.step_8.sub_steps[0].radio_fields.document_type.data[1].id  
    };
    let driving_license_json = {      
      "required": jsonData.step_8.sub_steps[0].radio_fields.document_type.data[2].required,
      "visible": jsonData.step_8.sub_steps[0].radio_fields.document_type.data[2].visible,
      "id": jsonData.step_8.sub_steps[0].radio_fields.document_type.data[2].id  
    };

    if (passport_json.visible && passport_json.required) {
      let listID = this.state[passport_json.id];
      let isDataAvailable = listID.filter(
        (data) => Object.keys(data).length === 0
      );
      if (isDataAvailable.length > 0) {
        this.setState({
          errorMessage: `Please upload all required ${passport_json.id} files`,
        });
        return;
      }
    }
    if (national_id_json.visible && national_id_json.required) {
      let listID = this.state[national_id_json.id];
      let isDataAvailable = listID.filter(
        (data) => Object.keys(data).length === 0
      );
      if (isDataAvailable.length > 0) {
        this.setState({
          errorMessage: `Please upload all required ${national_id_json.id} files`,
        });
        return;
      }
    }
    if (driving_license_json.visible && driving_license_json.required) {
      let listID = this.state[driving_license_json.id];
      let isDataAvailable = listID.filter(
        (data) => Object.keys(data).length === 0
      );
      if (isDataAvailable.length > 0) {
        this.setState({
          errorMessage: `Please upload all required ${driving_license_json.id} files`,
        });
        return;
      }
    }

    this.setState({ errorMessage: "", successMessage: "", disabled: true });
    const step8_json = {
      keys: keys,
      values: {
        [keys.passport]: this.state[keys.passport],
        [keys.national_id]: this.state[keys.national_id],
        [keys.driving_license]: this.state[keys.driving_license]        
      },
    };
    console.log('step_8_data', step8_json);
    this.submitUploadDocuments(step8_json);
    //this.continue(step7_json);
  };

  submitUploadDocuments = async (docs) => {
    const {
      signup_hash,
      steps_completed,
      steps_allowed
    } = this.props;

    //console.log('client_email',this.props);
    if (signup_hash !== "") {
      const {keys} = this.props.values;
      try {
        let response = await registerUploadDocuments({          
          token: process.env.REACT_APP_API_TOKEN,
          api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
          signup_hash: signup_hash,
          passport: docs.values[keys.passport],
          national_id: docs.values[keys.national_id],
          driving_license: docs.values[keys.driving_license],          
        });
        console.log('submitUploadDocuments',response);
        if (response.data.status === 404 ||
            response.data.status === 422
        ){
          this.setState({
            errorMessage: 'Something went wrong. Please try again',
            successMessage: "",
            disabled: false,            
          });          
        }
        else if(response.data.status === 200 &&
          response.data.message === "success"){
            if(steps_completed === (steps_allowed - 1)){
              // this.setState({
              //   errorMessage: '',
              //   successMessage: "Successfully submitted data.",
              //   disabled: true,            
              // });
              this.props.successHandler();
            }
            else{
              this.continue(docs);
            }  
          }
      } catch (err) {
        this.setState({ errorMessage: "", successMessage: "", disabled: false });        
      }
    }

  };

  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      successMessage: "",
      disabled: props.jsonData.step_8.sub_steps[0].button_fields.continue.disabled,
      selectedFile: null,
      [props.jsonData.step_8.sub_steps[0].radio_fields.document_type.data[0].name]: props.jsonData.step_8.sub_steps[0].radio_fields.document_type.data[0].id
    };
    
  }

  componentDidMount(){
    const {
      keys,
      values,
    } = this.props.values;
    let docTypeList = this.props.jsonData.step_8.sub_steps[0].radio_fields.document_type.data;
    for(let i=0; i < docTypeList.length; i++){
      let tempDocList = [];
      for(let j = 0; j < docTypeList[i].document_list.length; j++){
        tempDocList.push({});
      }
      this.setState({
        [docTypeList[i].id]: tempDocList
      });
    }
  }

  onFileChange = event => { 
    // Update the state 
    this.setState({ selectedFile: event.target.files[0] }); 
  }; 

  modalHideIns = () => {
    window.$("#instructionsModal").modal("hide");
  };

  handleChangeCheck = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
    console.log(name + " " + value);
  };

  render() {
    const { jsonData, mStepsUITitles, mStepsUIDesc, steps_completed, steps_allowed } = this.props;
    const {values, keys} = this.props.values;
    
    let filteredDataList = jsonData.step_8.sub_steps[0].radio_fields.document_type.data.filter((doc) => {
      return doc.id === this.state['document_type'];
    });

    console.log('filteredDataList', filteredDataList[0]);
    console.log('state', this.state);


    return (
      <>
        <HelmetProvider>
          <Helmet>
            <link
              rel="stylesheet"
              href="../../assets/ui_latest/css/dashlite.css?ver=3.0.3"
            />
            <link
              id="skin-default"
              rel="stylesheet"
              href="../../assets/ui_latest/csstheme.css?ver=3.0.3"
            />
          </Helmet>
        </HelmetProvider>
        <div className="nk-block nk-block-lg" style={{ width: "100%" }}>
          <div
            className="card card-bordered"
            style={{
              border: "none",
              paddingTop: "2%",
              paddingLeft: "5%",
              paddingRight: "5%",
            }}
          >
            <form
              className="nk-stepper stepper-init is-alter nk-stepper-s1"
              action="#"
              onSubmit={this.submitForms}
              id="stepper-create-profile"
            >
              <div className="row g-0 col-sep col-sep-md col-sep-xl">
                <div className="col-md-4 col-xl-4">
                  <div className="card-inner" id="registration_div">
                    <ul className="nk-stepper-nav nk-stepper-nav-s1 stepper-nav is-vr">
                      <li
                        className={steps_completed === 0 ? "current" : "done"}
                      >
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">{mStepsUITitles[0]}</div>
                            <div className="sub-text">{mStepsUIDesc[0]}</div>
                          </div>
                        </div>
                      </li>
                      {steps_allowed >= 2 ? (
                        <li
                          className={
                            steps_completed === 1
                              ? "current"
                              : steps_completed > 1 && steps_completed <= 3
                              ? "done"
                              : ""
                          }
                        >
                          <div className="step-item">
                            <div className="step-text">
                              <div className="lead-text">
                                {mStepsUITitles[1]}
                              </div>
                              <div className="sub-text">{mStepsUIDesc[1]}</div>
                            </div>
                          </div>
                        </li>
                      ) : null}
                      {steps_allowed >= 3 ? (
                        <li
                          className={
                            steps_completed === 2
                              ? "current"
                              : steps_completed > 2 && steps_completed <= 4
                              ? "done"
                              : ""
                          }
                        >
                          <div className="step-item">
                            <div className="step-text">
                              <div className="lead-text">
                                {mStepsUITitles[2]}
                              </div>
                              <div className="sub-text">{mStepsUIDesc[2]}</div>
                            </div>
                          </div>
                        </li>
                      ) : null}
                      {steps_allowed >= 4 ? (
                        <li
                          className={
                            steps_completed === 3
                              ? "current"
                              : steps_completed > 3 && steps_completed <= 5
                              ? "done"
                              : ""
                          }
                        >
                          <div className="step-item">
                            <div className="step-text">
                              <div className="lead-text">
                                {mStepsUITitles[3]}
                              </div>
                              <div className="sub-text">{mStepsUIDesc[3]}</div>
                            </div>
                          </div>
                        </li>
                      ) : null}
                      {steps_allowed >= 5 ? (
                        <li
                          className={
                            steps_completed === 4
                              ? "current"
                              : steps_completed > 4 && steps_completed <= 6
                              ? "done"
                              : ""
                          }
                        >
                          <div className="step-item">
                            <div className="step-text">
                              <div className="lead-text">
                                {mStepsUITitles[4]}
                              </div>
                              <div className="sub-text">{mStepsUIDesc[4]}</div>
                            </div>
                          </div>
                        </li>
                      ) : null}
                      {steps_allowed >= 6 ? (
                        <li
                          className={
                            steps_completed === 5
                              ? "current"
                              : steps_completed > 5 && steps_completed <= 7
                              ? "done"
                              : ""
                          }
                        >
                          <div className="step-item">
                            <div className="step-text">
                              <div className="lead-text">
                                {mStepsUITitles[5]}
                              </div>
                              <div className="sub-text">{mStepsUIDesc[5]}</div>
                            </div>
                          </div>
                        </li>
                      ) : null}
                      {steps_allowed >= 7 ? (
                        <li
                          className={
                            steps_completed === 6
                              ? "current"
                              : steps_completed > 6 && steps_completed <= 8
                              ? "done"
                              : ""
                          }
                        >
                          <div className="step-item">
                            <div className="step-text">
                              <div className="lead-text">
                                {mStepsUITitles[6]}
                              </div>
                              <div className="sub-text">{mStepsUIDesc[6]}</div>
                            </div>
                          </div>
                        </li>
                      ) : null}
                      {steps_allowed >= 8 ? (
                        <li
                          className={
                            steps_completed === 7
                              ? "current"
                              : steps_completed > 7 && steps_completed <= 9
                              ? "done"
                              : ""
                          }
                        >
                          <div className="step-item">
                            <div className="step-text">
                              <div className="lead-text">
                                {mStepsUITitles[7]}
                              </div>
                              <div className="sub-text">{mStepsUIDesc[7]}</div>
                            </div>
                          </div>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                </div>
                <div className="col-md-8 col-xl-8">
                  <div className="card-inner">
                    <div className="nk-stepper-content">
                      <div className="nk-stepper-steps stepper-steps">
                        {this.state.errorMessage !== "" ? (
                          <div
                            className="example-alert"
                            style={{ marginBottom: "20px" }}
                          >
                            <div className="alert alert-pro alert-danger">
                              <div className="alert-text">
                                <h4>Error</h4>
                                <p>{this.state.errorMessage}</p>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {this.state.successMessage !== "" ? (
                          <div className="example-alert">
                            <div className="alert alert-pro alert-success">
                              <div className="alert-text">
                                <h4>Success</h4>
                                <p>{this.state.successMessage}</p>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div className="nk-stepper-step">
                          <h4
                            className="title mb-3"
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {jsonData.step_8.sub_steps[0].title}
                          </h4>
                          <p
                            style={{
                              textAlign: "center",
                              marginLeft: "10%",
                              marginRight: "10%",
                            }}
                          >
                            {jsonData.step_8.sub_steps[0].description}
                          </p>
                          <div className="row g-3">
                            {jsonData.step_8.sub_steps[0].radio_fields
                              .document_type.visible ? (
                              <>
                                <div className="nk-kycfm-note">
                                  <em
                                    className="icon ni ni-info-fill"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="right"
                                    aria-label="Tooltip on right"
                                    data-bs-original-title="Tooltip on right"
                                  ></em>
                                  <p>
                                    {
                                      jsonData.step_8.sub_steps[0].radio_fields
                                        .document_type.label
                                    }
                                  </p>
                                </div>
                                <ul className="nk-kycfm-control-list g-3">
                                  {jsonData.step_8.sub_steps[0].radio_fields.document_type.data.map(
                                    (result, index) => (
                                      <li
                                        key={`document_type${index}`}
                                        className="nk-kycfm-control-item"
                                      >
                                        <input
                                          className="nk-kycfm-control"
                                          type={result.type}
                                          name={result.name}
                                          id={result.id}
                                          value={result.id}
                                          onChange={this.handleChangeCheck}
                                          data-title={result.value}
                                          checked={
                                            result.id ===
                                            this.state["document_type"]
                                              ? true
                                              : false
                                          }
                                        />
                                        <label
                                          className="nk-kycfm-label"
                                          htmlFor={result.htmlFor}
                                        >
                                          <span className="nk-kycfm-label-icon">
                                            <span className="label-icon">
                                              {/* <svg viewBox="0 0 71.9904 75.9285">
                                            <path
                                              d="M27.14,23.73A15.55,15.55,0,1,0,42.57,39.4v-.12A15.5,15.5,0,0,0,27.14,23.73Zm11.42,9.72H33a25.55,25.55,0,0,0-2.21-6.53A12.89,12.89,0,0,1,38.56,33.45ZM31,39.28a26.9929,26.9929,0,0,1-.2,3.24H23.49a26.0021,26.0021,0,0,1,0-6.48H30.8A29.3354,29.3354,0,0,1,31,39.28ZM26.77,26.36h.75a21.7394,21.7394,0,0,1,2.85,7.09H23.91A21.7583,21.7583,0,0,1,26.77,26.36Zm-3.28.56a25.1381,25.1381,0,0,0-2.2,6.53H15.72a12.88,12.88,0,0,1,7.78-6.53ZM14.28,39.28A13.2013,13.2013,0,0,1,14.74,36H20.9a29.25,29.25,0,0,0,0,6.48H14.74A13.1271,13.1271,0,0,1,14.28,39.28Zm1.44,5.83h5.57a25.9082,25.9082,0,0,0,2.2,6.53A12.89,12.89,0,0,1,15.72,45.11ZM27.51,52.2h-.74a21.7372,21.7372,0,0,1-2.85-7.09h6.44A21.52,21.52,0,0,1,27.51,52.2Zm3.28-.56A25.1413,25.1413,0,0,0,33,45.11h5.57a12.84,12.84,0,0,1-7.77,6.53Zm2.59-9.12a28.4606,28.4606,0,0,0,0-6.48h6.15a11.7,11.7,0,0,1,0,6.48ZM14.29,62.6H40v2.6H14.28V62.61ZM56.57,49l1.33-5,2.48.67-1.33,5Zm-6,22.52L55.24,54l2.48.67L53.06,72.14Zm21.6-61.24-29.8-8a5.13,5.13,0,0,0-6.29,3.61h0L33.39,16H6.57A2.58,2.58,0,0,0,4,18.55V70.38A2.57,2.57,0,0,0,6.52,73L6.57,73h29.7l17.95,4.85a5.12,5.12,0,0,0,6.28-3.6v-.06L75.8,16.61a5.18,5.18,0,0,0-3.6066-6.3763L72.18,10.23ZM6.57,70.38V18.55H45.14a2.57,2.57,0,0,1,2.57,2.57V67.79a2.57,2.57,0,0,1-2.55,2.59H6.57ZM73.34,15.91,58,73.48a2.59,2.59,0,0,1-2.48,1.93,2.5192,2.5192,0,0,1-.67-.09l-9-2.42a5.15,5.15,0,0,0,4.37-5.11V47.24l1.32.36,1.33-5-2.49-.67-.16.62V21.94l2.62.71,3.05,10,2.13.57L57.88,24l3.76,1,1.65,3,1.42.39-.25-4.09,2.24-3.42-1.41-.39L62.4,22.15l-3.76-1,4.76-7.92-2.13-.57-7.6,7.14-4-1.08A5.1,5.1,0,0,0,45.14,16H36.05l2.51-9.45a2.57,2.57,0,0,1,3.12-1.84h0l29.81,8.05a2.56,2.56,0,0,1,1.56,1.21A2.65,2.65,0,0,1,73.34,15.91ZM56.57,39.59l.66-2.5,2.44.65L59,40.24Zm4.88,1.31.66-2.51,2.44.66-.65,2.5Zm-9.76-2.61.66-2.51,2.45.66-.66,2.5Z"
                                              transform="translate(-3.9995 -2.101)"
                                              fill="#6476ff"
                                            ></path>
                                          </svg> */}
                                              <svg viewBox="0 0 1024 1024">
                                                <path
                                                  fill="#6476ff"
                                                  transform="translate(-3.9995 -2.101)"
                                                  d="M854.6 288.6L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0042 42h216v494zM544 472c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v108H372c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h108v108c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V644h108c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H544V472z"
                                                />
                                              </svg>
                                            </span>
                                            {/* <em className="icon ni ni-file-plus"></em> */}
                                          </span>
                                          <span className="nk-kycfm-label-text">
                                            {result.value}
                                          </span>
                                        </label>
                                      </li>
                                    )
                                  )}
                                </ul>
                                <ul>
                                  {filteredDataList[0].document_list.map(
                                    (result, index) => (
                                      <li
                                        key={`key_${filteredDataList[0].id}_${index}`}
                                        className="nk-kycfm-control-item"
                                        style={{
                                          marginBottom: "20px",
                                          marginTop: "20px",
                                        }}
                                      >
                                        <div className="nk-kycfm-upload">
                                          <h6 className="title nk-kycfm-upload-title">
                                            {result.label}
                                          </h6>
                                          <div className="row align-items-center">
                                            <div className="col-sm-8">
                                              {/* <div className="upload-zone dropzone dz-clickable" data-max-files="1" id={`upload_${filteredDataList[0].id}_${index}`}>
                                            <div
                                              className="dz-message"
                                              data-dz-message
                                            >
                                              <span className="dz-message-text">
                                                Drag and drop file
                                              </span>
                                              <span className="dz-message-or">
                                                or
                                              </span>
                                              <button
                                                className="btn btn-primary"
                                                type="button" id={`button_${filteredDataList[0].id}_${index}`}
                                              >
                                                SELECT
                                              </button>
                                            </div>

                                          </div> */}
                                              <MyCustomDropZone
                                                filteredDataList={
                                                  filteredDataList
                                                }
                                                index={index}
                                                onDrop={(files) => {
                                                  console.log(files,index);
                                                  let list = this.state[filteredDataList[0].id];
                                                  list[index] = files[0];
                                                  //list.splice(index, 0, files[0]);
                                                  this.setState({
                                                    [filteredDataList[0].id]: list
                                                  });
                                                }}
                                              />
                                            </div>
                                            <div className="col-sm-4 d-none d-sm-block">
                                              <div className="mx-lg-4">
                                                <img
                                                  src="assets/images/placeholder_documents.png"
                                                  alt="ID Front"
                                                  width="70%"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </>
                            ) : null}
                          </div>
                        </div>
                        <div className="nk-stepper-step"></div>
                        <div className="nk-stepper-step"></div>
                        <div className="nk-stepper-step"></div>
                        <div className="nk-stepper-step text-center"></div>
                      </div>
                      <div className="row mt-5 gx-1 gy-1">
                        {jsonData.step_8.sub_steps[0].button_fields.back
                          .visible && steps_completed > 0 ? (
                          <div className={"col-lg-6"}>
                            <MyCustomButton
                              data={
                                jsonData.step_8.sub_steps[0].button_fields.back
                              }
                              onClick={this.back}
                            />
                          </div>
                        ) : null}
                        {jsonData.step_8.sub_steps[0].button_fields.continue
                          .visible && steps_completed < steps_allowed - 1 ? (
                          <div
                            className={
                              steps_completed === 0 ? "col-lg-12" : "col-lg-6"
                            }
                          >
                            <MyCustomButton
                              data={
                                jsonData.step_8.sub_steps[0].button_fields
                                  .continue
                              }
                              //onClick={this.submitForms}
                              disabled={this.state.disabled}
                            />
                          </div>
                        ) : null}
                        {jsonData.step_8.sub_steps[0].button_fields.submit
                          .visible && steps_completed === steps_allowed - 1 ? (
                          <div
                            className={
                              steps_allowed > 1 ? "col-lg-6" : "col-lg-12"
                            }
                          >
                            <MyCustomButton
                              data={
                                jsonData.step_8.sub_steps[0].button_fields
                                  .submit
                              }
                              //onClick={this.submitForms}
                              disabled={this.state.disabled}
                            />
                          </div>
                        ) : null}
                      </div>
                      {/* <div
                        className="text-center"
                        style={{ marginTop: "25px" }}
                      >
                        Already have an account ? <a href="">Sign in instead</a>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default Step8;
