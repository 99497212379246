import React, { Component, Fragment } from "react";
import {
  AppBar,
  Button,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { LOGO_VERIFY } from "../../../config/config";

import "../ClientRegister/registration_step.css";

import { registerBusinessReferences } from "../../../config/client_api_calls";


import {
  ValidateEmail,
} from "../../../config/utility";

import { Col, Row } from "react-bootstrap";
import SignatureCanvas from "react-signature-canvas";

import HelperClass from "../../../config/helperClass";
import { tableLoader } from "../../ContentLoaders/table_loader";
import Skeleton from "@material-ui/lab/Skeleton";
import { Helmet, HelmetProvider } from "react-helmet-async";

import MyCustomTextField from "../../../components/InputFields/TextField";
import MyCustomRadioField from "../../../components/InputFields/RadioField";
import MyCustomCheckBoxField from "../../../components/InputFields/CheckBox";
import MyCustomButton from "../../../components/InputFields/Button";

const auth = new HelperClass();
export class DomainConnect extends Component {
  continue = (data) => {
    //e.preventDefault();
    this.props.nextStep(data);
  };
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  submitForms = (e) => {
    e.preventDefault();
    const { jsonData } = this.props;
    const { keys } = this.props.values;

    let trade_reference_1_json = {      
      "required": jsonData.step_5.sub_steps[0].input_fields.trade_reference_1.required,
      "visible": jsonData.step_5.sub_steps[0].input_fields.trade_reference_1.visible  
    };
    let trade_reference_2_json = {      
      "required": jsonData.step_5.sub_steps[0].input_fields.trade_reference_2.required,
      "visible": jsonData.step_5.sub_steps[0].input_fields.trade_reference_2.visible  
    };
    let legal_company_name_json = {      
      "required": jsonData.step_5.sub_steps[0].input_fields.trade_reference_1.legal_company_name.required,
      "visible": jsonData.step_5.sub_steps[0].input_fields.trade_reference_1.legal_company_name.visible  
    };
    let main_telephone_number_json = {      
      "required": jsonData.step_5.sub_steps[0].input_fields.trade_reference_1.main_telephone_number.required,
      "visible": jsonData.step_5.sub_steps[0].input_fields.trade_reference_1.main_telephone_number.visible  
    };
    let email_json = {      
      "required": jsonData.step_5.sub_steps[0].input_fields.trade_reference_1.email.required,
      "visible": jsonData.step_5.sub_steps[0].input_fields.trade_reference_1.email.visible  
    };
    let address_json = {      
      "required": jsonData.step_5.sub_steps[0].input_fields.trade_reference_1.address.address.required,
      "visible": jsonData.step_5.sub_steps[0].input_fields.trade_reference_1.address.visible  
    };
    let city_json = {      
      "required": jsonData.step_5.sub_steps[0].input_fields.trade_reference_1.address.city.required,
      "visible": jsonData.step_5.sub_steps[0].input_fields.trade_reference_1.address.city.visible  
    };
    let state_json = {      
      "required": jsonData.step_5.sub_steps[0].input_fields.trade_reference_1.address.state.required,
      "visible": jsonData.step_5.sub_steps[0].input_fields.trade_reference_1.address.state.visible  
    };
    let zip_json = {      
      "required": jsonData.step_5.sub_steps[0].input_fields.trade_reference_1.address.zip.required,
      "visible": jsonData.step_5.sub_steps[0].input_fields.trade_reference_1.address.zip.visible  
    };
    let legal_company_name2_json = {      
      "required": jsonData.step_5.sub_steps[0].input_fields.trade_reference_2.legal_company_name.required,
      "visible": jsonData.step_5.sub_steps[0].input_fields.trade_reference_2.legal_company_name.visible  
    };
    let main_telephone_number2_json = {      
      "required": jsonData.step_5.sub_steps[0].input_fields.trade_reference_2.main_telephone_number.required,
      "visible": jsonData.step_5.sub_steps[0].input_fields.trade_reference_2.main_telephone_number.visible  
    };
    let email2_json = {      
      "required": jsonData.step_5.sub_steps[0].input_fields.trade_reference_2.email.required,
      "visible": jsonData.step_5.sub_steps[0].input_fields.trade_reference_2.email.visible  
    };
    let address2_json = {      
      "required": jsonData.step_5.sub_steps[0].input_fields.trade_reference_2.address.address.required,
      "visible": jsonData.step_5.sub_steps[0].input_fields.trade_reference_2.address.visible  
    };
    let city2_json = {      
      "required": jsonData.step_5.sub_steps[0].input_fields.trade_reference_2.address.city.required,
      "visible": jsonData.step_5.sub_steps[0].input_fields.trade_reference_2.address.city.visible  
    };
    let state2_json = {      
      "required": jsonData.step_5.sub_steps[0].input_fields.trade_reference_2.address.state.required,
      "visible": jsonData.step_5.sub_steps[0].input_fields.trade_reference_2.address.state.visible  
    };
    let zip2_json = {      
      "required": jsonData.step_5.sub_steps[0].input_fields.trade_reference_2.address.zip.required,
      "visible": jsonData.step_5.sub_steps[0].input_fields.trade_reference_2.address.zip.visible  
    };

    if(trade_reference_1_json.visible && trade_reference_1_json.required){
      if(legal_company_name_json.visible && legal_company_name_json.required && this.state[keys.legal_company_name] === ""){
        this.setState({
          errorMessage: "Please enter Legal Company Name for trade reference 1",
        });
        return;
      }
      if(main_telephone_number_json.visible && main_telephone_number_json.required && this.state[keys.main_telephone_number] === ""){
        this.setState({
          errorMessage: "Please enter Main Telephone Number for trade reference 1",
        });
        return;
      }
      if(email_json.visible && email_json.required){
        if(this.state[keys.email] === ""){
          this.setState({
            errorMessage: "Please enter your email for trade reference 1",
          });
          return;
        }
        if(ValidateEmail(this.state[keys.email]) === false){
          this.setState({
            errorMessage: "Invalid email format for trade reference 1.",
          });
          return;
        }
        
      }     
      if(address_json.visible && address_json.required && this.state[keys.address] === ""){
        this.setState({
          errorMessage: "Please enter address for trade reference 1",
        });
        return;
      }
      if(city_json.visible && city_json.required && this.state[keys.city] === ""){
        this.setState({
          errorMessage: "Please enter city for trade reference 1",
        });
        return;
      }
      if(state_json.visible && state_json.required && this.state[keys.state] === ""){
        this.setState({
          errorMessage: "Please enter state for trade reference 1",
        });
        return;
      }
      if(zip_json.visible && zip_json.required && this.state[keys.zip] === ""){
        this.setState({
          errorMessage: "Please enter zip for trade reference 1",
        });
        return;
      } 
    }
    if(trade_reference_2_json.visible && trade_reference_2_json.required){
      if(legal_company_name2_json.visible && legal_company_name2_json.required && this.state[keys.legal_company_name2] === ""){
        this.setState({
          errorMessage: "Please enter Legal Company Name for trade reference 2",
        });
        return;
      }
      if(main_telephone_number2_json.visible && main_telephone_number2_json.required && this.state[keys.main_telephone_number2] === ""){
        this.setState({
          errorMessage: "Please enter Main Telephone Number for trade reference 2",
        });
        return;
      }
      if(email2_json.visible && email2_json.required){
        if(this.state[keys.email2] === ""){
          this.setState({
            errorMessage: "Please enter your email for trade reference 2",
          });
          return;
        }
        if(ValidateEmail(this.state[keys.email2]) === false){
          this.setState({
            errorMessage: "Invalid email format for trade reference 2.",
          });
          return;
        }
        
      }     
      if(address2_json.visible && address2_json.required && this.state[keys.address2] === ""){
        this.setState({
          errorMessage: "Please enter address for trade reference 2",
        });
        return;
      }
      if(city2_json.visible && city2_json.required && this.state[keys.city2] === ""){
        this.setState({
          errorMessage: "Please enter city for trade reference 2",
        });
        return;
      }
      if(state2_json.visible && state2_json.required && this.state[keys.state2] === ""){
        this.setState({
          errorMessage: "Please enter state for trade reference 2",
        });
        return;
      }
      if(zip2_json.visible && zip2_json.required && this.state[keys.zip2] === ""){
        this.setState({
          errorMessage: "Please enter zip for trade reference 2",
        });
        return;
      } 
    }
    this.setState({ errorMessage: "", successMessage: "", disabled: true });
    const step5_json = {
      keys: keys,
      values: {
        [keys.legal_company_name]: this.state[keys.legal_company_name],
        [keys.main_telephone_number]: this.state[keys.main_telephone_number],
        [keys.email]: this.state[keys.email],
        [keys.address]: this.state[keys.address],
        [keys.city]: this.state[keys.city],
        [keys.state]: this.state[keys.state],
        [keys.zip]: this.state[keys.zip],
        [keys.legal_company_name2]: this.state[keys.legal_company_name2],
        [keys.main_telephone_number2]: this.state[keys.main_telephone_number2],
        [keys.email2]: this.state[keys.email2],
        [keys.address2]: this.state[keys.address2],
        [keys.city2]: this.state[keys.city2],
        [keys.state2]: this.state[keys.state2],
        [keys.zip2]: this.state[keys.zip2],
      },
    };
    console.log('step_5_data', step5_json);
    this.submitBusinessReferences(step5_json);
    //this.continue(step5_json);
  };

  submitBusinessReferences = async (businessReferences) => {
    const {
      signup_hash,
      steps_completed,
      steps_allowed
    } = this.props;
    
    //console.log('client_email',this.props);
    if (signup_hash !== "") {
      const {keys} = this.props.values;
      try {
        let response = await registerBusinessReferences({          
          token: process.env.REACT_APP_API_TOKEN,
          api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
          signup_hash: signup_hash,
          legal_company_name: businessReferences.values[keys.legal_company_name],
          main_telephone_number: businessReferences.values[keys.main_telephone_number],
          email: businessReferences.values[keys.email],
          address: businessReferences.values[keys.address],
          city: businessReferences.values[keys.city],
          state: businessReferences.values[keys.state],
          zip: businessReferences.values[keys.zip],
          legal_company_name2: businessReferences.values[keys.legal_company_name2],
          main_telephone_number2: businessReferences.values[keys.main_telephone_number2],
          email2: businessReferences.values[keys.email2],
          address2: businessReferences.values[keys.address2],
          city2: businessReferences.values[keys.city2],
          state2: businessReferences.values[keys.state2],
          zip2: businessReferences.values[keys.zip2],                   
        });
        console.log('submitBusinessReferences',response);
        if (response.data.status === 404 ||
            response.data.status === 422
        ){
          this.setState({
            errorMessage: 'Something went wrong. Please try again',
            successMessage: "",
            disabled: false,            
          });          
        }
        else if(response.data.status === 200 &&
          response.data.message === "success"){
            if(steps_completed === (steps_allowed - 1)){
              // this.setState({
              //   errorMessage: '',
              //   successMessage: "Successfully submitted data.",
              //   disabled: true,            
              // });
              this.props.successHandler();
            }
            else{
              this.continue(businessReferences);
            }   
            
          }
      } catch (err) {
        this.setState({ errorMessage: "", successMessage: "", disabled: false });        
      }
    }

  };

  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      successMessage: "",
      disabled: props.jsonData.step_5.sub_steps[0].button_fields.continue.disabled,
    };
  }

  componentDidMount(){
    const {
      keys,
      values,
    } = this.props.values;

    console.log('componentDidMount',values);

    this.setState({
      [keys.legal_company_name]:values[keys.legal_company_name],
      [keys.main_telephone_number]:values[keys.main_telephone_number],
      [keys.email]:values[keys.email],
      [keys.address]:values[keys.address],
      [keys.city]:values[keys.city],
      [keys.state]:values[keys.state],
      [keys.zip]:values[keys.zip],
      [keys.legal_company_name2]:values[keys.legal_company_name2],
      [keys.main_telephone_number2]:values[keys.main_telephone_number2],
      [keys.email2]:values[keys.email2],
      [keys.address2]:values[keys.address2],
      [keys.city2]:values[keys.city2],
      [keys.state2]:values[keys.state2],
      [keys.zip2]:values[keys.zip2],
    });
  }

  modalHideIns = () => {
    window.$("#instructionsModal").modal("hide");
  };

  handleChangeCheck = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
    console.log(name + " " + value);
  };

  render() {
    const { jsonData, mStepsUITitles, mStepsUIDesc, steps_completed, steps_allowed } = this.props;
    const {values, keys} = this.props.values;

    return (
      <>
        <HelmetProvider>
          <Helmet>
            <link
              rel="stylesheet"
              href="../../assets/ui_latest/css/dashlite.css?ver=3.0.3"
            />
            <link
              id="skin-default"
              rel="stylesheet"
              href="../../assets/ui_latest/csstheme.css?ver=3.0.3"
            />
          </Helmet>
        </HelmetProvider>
        <div className="nk-block nk-block-lg" style={{ width: "100%" }}>
          <div
            className="card card-bordered"
            style={{
              border: "none",
              paddingTop: "2%",
              paddingLeft: "5%",
              paddingRight: "5%",
            }}
          >
            <form
              className="nk-stepper stepper-init is-alter nk-stepper-s1"
              action="#"
              onSubmit={this.submitForms}
              id="stepper-create-profile"
            >
              <div className="row g-0 col-sep col-sep-md col-sep-xl">
                <div className="col-md-4 col-xl-4">
                  <div className="card-inner" id="registration_div">
                    <ul className="nk-stepper-nav nk-stepper-nav-s1 stepper-nav is-vr">
                    <li className={steps_completed === 0 ? "current" : "done"}>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">
                              {mStepsUITitles[0]}
                            </div>
                            <div className="sub-text">
                              {mStepsUIDesc[0]}
                            </div>
                          </div>
                        </div>
                      </li>
                      {
                        steps_allowed >= 2 ? <li className={steps_completed === 1  ? "current" : steps_completed > 1 && steps_completed <= 3 ? "done" : ""}>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">
                            {mStepsUITitles[1]}
                            </div>
                            <div className="sub-text">
                            {mStepsUIDesc[1]}
                            </div>
                          </div>
                        </div>
                      </li> : null
                      }
                      {
                        steps_allowed >= 3 ? <li className={steps_completed === 2  ? "current" : steps_completed > 2 && steps_completed <= 4 ? "done" : ""}>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">
                            {mStepsUITitles[2]}
                            </div>
                            <div className="sub-text">
                            {mStepsUIDesc[2]}
                            </div>
                          </div>
                        </div>
                      </li> : null
                      }
                      {
                        steps_allowed >= 4 ? <li className={steps_completed === 3  ? "current" : steps_completed > 3 && steps_completed <= 5 ? "done" : ""}>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">
                            {mStepsUITitles[3]}
                            </div>
                            <div className="sub-text">
                            {mStepsUIDesc[3]}
                            </div>
                          </div>
                        </div>
                      </li> : null
                      }
                      {
                        steps_allowed >= 5 ? <li className={steps_completed === 4  ? "current" : steps_completed > 4 && steps_completed <= 6 ? "done" : ""}>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">
                            {mStepsUITitles[4]}
                            </div>
                            <div className="sub-text">
                            {mStepsUIDesc[4]}
                            </div>
                          </div>
                        </div>
                      </li> : null
                      }
                      {
                        steps_allowed >= 6 ? <li className={steps_completed === 5  ? "current" : steps_completed > 5 && steps_completed <= 7 ? "done" : ""}>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">
                            {mStepsUITitles[5]}
                            </div>
                            <div className="sub-text">
                            {mStepsUIDesc[5]}
                            </div>
                          </div>
                        </div>
                      </li> : null
                      }
                      {
                        steps_allowed >= 7 ? <li className={steps_completed === 6  ? "current" : steps_completed > 6 && steps_completed <= 8 ? "done" : ""}>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">
                            {mStepsUITitles[6]}
                            </div>
                            <div className="sub-text">
                            {mStepsUIDesc[6]}
                            </div>
                          </div>
                        </div>
                      </li> : null
                      }
                      {steps_allowed >= 8 ? (
                        <li
                          className={
                            steps_completed === 7
                              ? "current"
                              : steps_completed > 7 && steps_completed <= 9
                              ? "done"
                              : ""
                          }
                        >
                          <div className="step-item">
                            <div className="step-text">
                              <div className="lead-text">
                                {mStepsUITitles[7]}
                              </div>
                              <div className="sub-text">{mStepsUIDesc[7]}</div>
                            </div>
                          </div>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                </div>
                <div className="col-md-8 col-xl-8">
                  <div className="card-inner">
                    <div className="nk-stepper-content">
                      <div className="nk-stepper-steps stepper-steps">
                        {this.state.errorMessage !== "" ? (
                          <div
                            className="example-alert"
                            style={{ marginBottom: "20px" }}
                          >
                            <div className="alert alert-pro alert-danger">
                              <div className="alert-text">
                                <h4>Error</h4>
                                <p>{this.state.errorMessage}</p>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div className="nk-stepper-step">
                          <h4
                            className="title mb-3"
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {jsonData.step_5.sub_steps[0].title}
                          </h4>
                          <p
                            style={{
                              textAlign: "center",
                              marginLeft: "10%",
                              marginRight: "10%",
                            }}
                          >
                            {jsonData.step_5.sub_steps[0].description}
                          </p>
                          <div className="row g-3">
                            {jsonData.step_5.sub_steps[0].input_fields
                              .trade_reference_1.visible ? (
                              <>
                                <h4
                                  className="title mb-3"
                                  style={{
                                    textAlign: "center",
                                    marginTop: "3%",
                                  }}
                                >
                                  {
                                    jsonData.step_5.sub_steps[0].input_fields
                                      .trade_reference_1.label
                                  }
                                </h4>
                                {jsonData.step_5.sub_steps[0].input_fields
                                  .trade_reference_1.legal_company_name
                                  .visible ? (
                                  <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <MyCustomTextField
                                        data={
                                          jsonData.step_5.sub_steps[0]
                                            .input_fields.trade_reference_1
                                            .legal_company_name
                                        }
                                        onChange={this.handleChangeCheck}
                                        defaultValue={`${
                                          values[keys.legal_company_name]
                                        }`}
                                      />
                                    </div>
                                  </div>
                                ) : null}
                                {jsonData.step_5.sub_steps[0].input_fields
                                  .trade_reference_1.main_telephone_number
                                  .visible ? (
                                  <div className="col-lg-6 col-md-6">
                                    <MyCustomTextField
                                      data={
                                        jsonData.step_5.sub_steps[0]
                                          .input_fields.trade_reference_1
                                          .main_telephone_number
                                      }
                                      onChange={this.handleChangeCheck}
                                      defaultValue={`${
                                        values[keys.main_telephone_number]
                                      }`}
                                    />
                                  </div>
                                ) : null}
                                {jsonData.step_5.sub_steps[0].input_fields
                                  .trade_reference_1.email.visible ? (
                                  <div className="col-12">
                                    <MyCustomTextField
                                      data={
                                        jsonData.step_5.sub_steps[0]
                                          .input_fields.trade_reference_1.email
                                      }
                                      onChange={this.handleChangeCheck}
                                      defaultValue={`${values[keys.email]}`}
                                    />
                                  </div>
                                ) : null}
                                {jsonData.step_5.sub_steps[0].input_fields
                                  .trade_reference_1.address.address.visible ? (
                                  <div className="col-12">
                                    <MyCustomTextField
                                      data={
                                        jsonData.step_5.sub_steps[0]
                                          .input_fields.trade_reference_1
                                          .address.address
                                      }
                                      onChange={this.handleChangeCheck}
                                      defaultValue={`${values[keys.address]}`}
                                    />
                                  </div>
                                ) : null}
                                {jsonData.step_5.sub_steps[0].input_fields
                                  .trade_reference_1.address.city.visible ? (
                                  <div className="col-4">
                                    <MyCustomTextField
                                      data={
                                        jsonData.step_5.sub_steps[0]
                                          .input_fields.trade_reference_1
                                          .address.city
                                      }
                                      onChange={this.handleChangeCheck}
                                      defaultValue={`${values[keys.city]}`}
                                    />
                                  </div>
                                ) : null}
                                {jsonData.step_5.sub_steps[0].input_fields
                                  .trade_reference_1.address.state.visible ? (
                                  <div className="col-4">
                                    <MyCustomTextField
                                      data={
                                        jsonData.step_5.sub_steps[0]
                                          .input_fields.trade_reference_1
                                          .address.state
                                      }
                                      onChange={this.handleChangeCheck}
                                      defaultValue={`${values[keys.state]}`}
                                    />
                                  </div>
                                ) : null}
                                {jsonData.step_5.sub_steps[0].input_fields
                                  .trade_reference_1.address.zip.visible ? (
                                  <div className="col-4">
                                    <MyCustomTextField
                                      data={
                                        jsonData.step_5.sub_steps[0]
                                          .input_fields.trade_reference_1
                                          .address.zip
                                      }
                                      onChange={this.handleChangeCheck}
                                      defaultValue={`${values[keys.zip]}`}
                                    />
                                  </div>
                                ) : null}
                              </>
                            ) : null}
                          </div>
                          <div className="row g-3">
                            {jsonData.step_5.sub_steps[0].input_fields
                              .trade_reference_2.visible ? (
                              <>
                                <h4
                                  className="title mb-3"
                                  style={{
                                    marginTop: "5%",
                                    textAlign: "center",
                                  }}
                                >
                                  {
                                    jsonData.step_5.sub_steps[0].input_fields
                                      .trade_reference_2.label
                                  }
                                </h4>
                                {jsonData.step_5.sub_steps[0].input_fields
                                  .trade_reference_2.legal_company_name
                                  .visible ? (
                                  <div className="col-lg-6 col-md-6">
                                    <MyCustomTextField
                                        data={
                                          jsonData.step_5.sub_steps[0]
                                            .input_fields.trade_reference_2
                                            .legal_company_name
                                        }
                                        onChange={this.handleChangeCheck}
                                        defaultValue={`${
                                          values[keys.legal_company_name2]
                                        }`}
                                      />
                                  </div>
                                ) : null}
                                {jsonData.step_5.sub_steps[0].input_fields
                                  .trade_reference_2.main_telephone_number
                                  .visible ? (
                                  <div className="col-lg-6 col-md-6">
                                    <MyCustomTextField
                                        data={
                                          jsonData.step_5.sub_steps[0]
                                            .input_fields.trade_reference_2
                                            .main_telephone_number
                                        }
                                        onChange={this.handleChangeCheck}
                                        defaultValue={`${
                                          values[keys.main_telephone_number2]
                                        }`}
                                      />
                                  </div>
                                ) : null}
                                {jsonData.step_5.sub_steps[0].input_fields
                                  .trade_reference_2.email.visible ? (
                                  <div className="col-12">
                                    <MyCustomTextField
                                      data={
                                        jsonData.step_5.sub_steps[0]
                                          .input_fields.trade_reference_2.email
                                      }
                                      onChange={this.handleChangeCheck}
                                      defaultValue={`${values[keys.email2]}`}
                                    />
                                  </div>
                                ) : null}
                                {jsonData.step_5.sub_steps[0].input_fields
                                  .trade_reference_2.address.address.visible ? (
                                  <div className="col-12">
                                    <MyCustomTextField
                                      data={
                                        jsonData.step_5.sub_steps[0]
                                          .input_fields.trade_reference_2
                                          .address.address
                                      }
                                      onChange={this.handleChangeCheck}
                                      defaultValue={`${values[keys.address2]}`}
                                    />
                                  </div>
                                ) : null}
                                {jsonData.step_5.sub_steps[0].input_fields
                                  .trade_reference_2.address.city.visible ? (
                                  <div className="col-4">
                                    <div className="form-group">
                                      <MyCustomTextField
                                        data={
                                          jsonData.step_5.sub_steps[0]
                                            .input_fields.trade_reference_2
                                            .address.city
                                        }
                                        onChange={this.handleChangeCheck}
                                        defaultValue={`${values[keys.city2]}`}
                                      />
                                    </div>
                                  </div>
                                ) : null}
                                {jsonData.step_5.sub_steps[0].input_fields
                                  .trade_reference_2.address.state.visible ? (
                                  <div className="col-4">
                                    <div className="form-group">
                                      <MyCustomTextField
                                        data={
                                          jsonData.step_5.sub_steps[0]
                                            .input_fields.trade_reference_2
                                            .address.state
                                        }
                                        onChange={this.handleChangeCheck}
                                        defaultValue={`${values[keys.state2]}`}
                                      />
                                    </div>
                                  </div>
                                ) : null}
                                {jsonData.step_5.sub_steps[0].input_fields
                                  .trade_reference_2.address.zip.visible ? (
                                  <div className="col-4">
                                    <div className="form-group">
                                      <MyCustomTextField
                                        data={
                                          jsonData.step_5.sub_steps[0]
                                            .input_fields.trade_reference_2
                                            .address.zip
                                        }
                                        onChange={this.handleChangeCheck}
                                        defaultValue={`${values[keys.zip2]}`}
                                      />
                                    </div>
                                  </div>
                                ) : null}
                              </>
                            ) : null}
                          </div>
                        </div>
                        <div className="nk-stepper-step"></div>
                        <div className="nk-stepper-step"></div>
                        <div className="nk-stepper-step"></div>
                        <div className="nk-stepper-step text-center"></div>
                      </div>
                      <div className="row mt-5 gx-1 gy-1">
                      {jsonData.step_5.sub_steps[0].button_fields.back
                          .visible && steps_completed > 0 ? (
                          <div className="col-lg-6">
                            <MyCustomButton
                              data={
                                jsonData.step_5.sub_steps[0].button_fields.back
                              }
                              onClick={this.back}
                            />
                          </div>
                        ) : null}
                        {jsonData.step_5.sub_steps[0].button_fields.continue
                          .visible && steps_completed < steps_allowed - 1 ? (
                            <div className={steps_completed === 0 ? "col-lg-12" : "col-lg-6"}>
                            <MyCustomButton
                              data={
                                jsonData.step_5.sub_steps[0].button_fields
                                  .continue
                              }
                              //onClick={this.submitForms}
                              disabled={this.state.disabled}
                            />
                          </div>
                        ) : null}
                        {jsonData.step_5.sub_steps[0].button_fields.submit
                          .visible && steps_completed === (steps_allowed - 1)  ? (
                          <div className="col-lg-6">
                            <MyCustomButton
                              data={
                                jsonData.step_5.sub_steps[0].button_fields
                                  .submit
                              }
                              //onClick={this.submitForms}
                              disabled={this.state.disabled}
                            />
                          </div>
                        ) : null}
                      </div>
                      {/* <div
                        className="text-center"
                        style={{ marginTop: "25px" }}
                      >
                        Already have an account ? <a href="">Sign in instead</a>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default DomainConnect;
