import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Error404 from "./components/Errors/404";
import Success from "./components/Success/success";
import ClientRegister from "./Pages/Client/Register";
import ClientSignUpVerification from "./Pages/Client/Verification/index";
import ClientMain from "./components/Client/main.js";




function App() {
  console.log("version ", React.version);

  return (
    <div>
      <Router>
        <Switch>
          {/* CLIENT ROUTES */}

          {/* <Route
            exact
            path="/"
            component={(props) => <ClientRegister {...props} />}
          /> */}

          <Route
            exact
            path="/client/register/:signup_hash/error"
            component={Error404}
          />

          <Route
            exact
            path="/client/register/:signup_hash"
            component={(props) => <ClientRegister {...props} />}
          />

          <Route
            exact
            path="/client/reports/:signup_hash"
            component={(props) => <ClientRegister {...props} />}
          />

          <Route
            exact
            path="/client/verification/:verification_token"
            component={(props) => <ClientSignUpVerification {...props} />}
          />

          <Route
            exact
            path="/client/dashboard"
            component={(props) => <ClientMain {...props} view="dashboard" />}
          />

          <Route
            exact
            path="/client/register/:signup_hash/success"
            component={Success}
          />

          {/* END CLIENT ROUTES */}

          <Route component={Error404} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
