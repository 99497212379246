export default function RadioField(props) {
  //console.log('RadioField',props);
  return (
    <>
    <input
        type={props.data.type}
        id={props.data.id}
        name={props.data.name}
        value={props.data.id}
        checked={props.defaultChecked}
        required={props.data.required}
        onChange={props.onChange}
      />
      <label htmlFor={props.data.htmlFor} className="form-label">
      {props.data.value}
      </label></>
  );
}
