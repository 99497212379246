export default function RadioField(props) {
  return (
    <div class={props.data.root_class}>
      <input
        type={props.data.type}
        id={props.data.id}
        name={props.data.name}
        value={props.data.id}
        onChange={props.onChange}
        defaultChecked={props.checked}
        checked={props.checked}
        className={props.data.input_class}
        required={props.data.required}
      />
      <label className={props.data.label_class} htmlFor={props.data.htmlFor}>
        <span className="d-flex align-center">{props.data.value}</span>
      </label>
    </div>
  );
}
