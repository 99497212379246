import React from 'react';
import Dropzone from 'react-dropzone';

export default function KycDropZone(props) {
    //const {acceptedFiles, getRootProps, getInputProps} = useDropzone();
    //console.log('KycDropZone',acceptedFiles);
    return (
      <Dropzone
        id={`dropzone_${props.filteredDataList[0].id}_${props.index}`}
        onDrop={props.onDrop}
        maxFiles={1}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <div className="dz-message" data-dz-message>
              <span className="dz-message-text">Drag and drop file</span>
              <span className="dz-message-or">or</span>
              <button
                className="btn btn-primary"
                type="button"
                id={`button_${props.filteredDataList[0].id}_${props.index}`}
              >
                SELECT
              </button>
            </div>
          </div>
        )}
      </Dropzone>
    );
}
