import { React } from "react";
import { Component } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  listServices,
  serviceRatesUpdate,
  serviceDefaultUpdate,
  getServiceDetails,
  listDefaultServices,
} from "./../../../config/client_api_calls";

import $ from "jquery";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { tableLoaderListing } from "../../../components/ContentLoaders/table_loader_listing";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { THEME_TEXT_COLOR, THEME_COLOR } from "./../../../config/config";
import MutextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import Tooltip from "@material-ui/core/Tooltip";
import dateFormat, { masks } from "dateformat";

const auth = new HelperClass();

class Services extends Component {
  constructor() {
    super();

    this.state = {
      tableData: [],
      serviceData: [],
      completeData: [],
      defaultServices: [],
      errorMessage: "",
      serviceId: "",
      successMessage: "",
      waitMessage: "",
      tableLoader: true,
      tableLoaderListing: true,
      nrc_rate_type: "Flat",
      defaultNrc: "",
      defaultMrc: "",
      new_nrc: "",
      new_mrc: "",
      rate_type: "",
      rate: "",
      defaultRate: "",
      defaultSmsIn: "",
      defaultSmsOut: "",
      agent_mrc_comission: 0,
      agent_nrc_comission: 0.0,
      agent_rate_comission: 0,
      sms_in_comission: 0,
      sms_out_comission: 0,
      update_nrc: "",
      update_mrc: "",
      update_rate: "",
      product_id: "",
      product_desc: "",
      mrc_rate_type: "Flat",
      service_title: "",
      service_type_edit: "",
      default_mrc: "",
      default_nrc: "",
      default_rate: "",
      default_sms_in: "",
      default_sms_out: "",
      sms_in: "",
      sms_out: "",
      sms_in_percentage: "",
      sms_out_percentage: "",
      mrc_percentage: "",
      rate_percentage: "",
      select_service: "",
      columnServices: [
        { name: "Id" },
        { name: "Title" },

        { name: "Date Added" },
        { name: "Date Updated" },
        {
          name: "Default",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.defaultText(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Actions",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            href="#"
                            title="Other Actions"
                            className="dropdown-toggle btn btn-icon  btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.showDetails(value, tableMeta)
                                  }
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Service Details"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-list"></em>
                                  <span>Service Details</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
    };
  }
  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };

  serviceSigning = async (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[6]);
  };

  defaultText = (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let is_default = tableMeta.rowData[5];
    if (is_default === "No") {
      return (
        <a
          href="#"
          onClick={() => this.setDefaultService(value, tableMeta)}
          disabled={
            this.props.is_msa_signed === "No" ||
            this.props.defaultSettings === "missing"
              ? true
              : false
          }
        >
          <span className="badge badge-outline-primary">
            <em className="icon ni ni-signin"></em> Make Default
          </span>
        </a>
      );
    } else {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Yes
        </span>
      );
    }
  };

  async componentDidMount() {
    const servicesResponce = await listServices(
      auth.getClientAccount(),
      auth.getClientToken()
    );
    console.log("servicesResponce.data.data: ", servicesResponce);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoader: false,
        tableLoaderListing: false,
      });
    } else {
      //window.location.replace("/error");
    }
  }

  get_default_services = async () => {
    const servicesResponce = await listDefaultServices(
      auth.getClientAccount(),
      auth.getClientToken()
    );
    console.log("Service Data: ", servicesResponce.data.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        defaultServices: servicesResponce.data.data,
        completeData: servicesResponce.data.all_data,
      });
    } else {
      //window.location.replace("/error");
    }
  };

  setMarkUp = async (value, tableMeta) => {
    let service_id = tableMeta.rowData[0];

    window.$("#modalForm").modal("show");
  };

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy, h:MM:ss TT");
  };

  handleStatesDynamic = async (
    serviceId,
    update_nrc,
    update_mrc,
    update_rate,
    service_title,
    service_type_edit,
    sms_in,
    sms_out
  ) => {
    this.setState({
      serviceId: serviceId,
      update_nrc: update_nrc,
      update_mrc: update_mrc,
      update_rate: update_rate,
      service_title: service_title,
      service_type_edit: service_type_edit,
      sms_in: sms_in,
      sms_out: sms_out,
    });
  };

  showDetails = async (value, tableMeta) => {
    let service_id = tableMeta.rowData[0];
    this.setState({
      errorMessage: "",
      successMessage: "",
    });
    const servicesResponce = await getServiceDetails(
      auth.getClientAccount(),
      auth.getClientToken(),
      service_id
    );
    console.log("getAgentService: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while getting the service data.",
        successMessage: "",
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        serviceData: servicesResponce.data.data,
        tableLoader: false,
        tableLoaderListing: false,
        errorMessage: "",
      });
    } else {
      this.setState({
        errorMessage: "There is some error while getting the service data.",
        successMessage: "",
      });
      //window.location.replace("/error");
    }
    window.$("#modalFormDetail").modal("show");
  };

  setDefaultService = async (value, tableMeta) => {
    let id = tableMeta.rowData[0];
    let service_id = tableMeta.rowData[10];
    let service_type = tableMeta.rowData[5];
    let nrc = tableMeta.rowData[3];
    let mrc = tableMeta.rowData[4];
    let rate = tableMeta.rowData[6];

    this.setState({ errorMessage: "" });

    this.setState({
      errorMessage: "",
      waitMessage: "Please wait while we are making this service to default.",
      disabled: true,
      successMessage: "",
    });
    window.$("#modalFormDefault").modal("show");

    const saveResponce = await serviceDefaultUpdate(
      auth.getClientAccount(),
      auth.getClientToken(),
      id,
      service_id,
      service_type,
      nrc,
      mrc,
      rate
    );
    console.log("saveResponce.data: ", saveResponce.data);
    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "fail" &&
      saveResponce.data.note !== ""
    ) {
      this.setState({
        errorMessage: saveResponce.data.note,
        successMessage: "",
        waitMessage: "",
        disabled: false,
      });
      //window.location.replace("/error");
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        errorMessage: "",
        successMessage: "Service has been changed to default successfully.",
        disabled: false,
        waitMessage: "",
      });

      $("#btnloader").hide();

      setTimeout(function () {
        window.$("#modalFormDefault").modal("hide");
      }, 3000);
      this.componentDidMount();
    } else {
      this.setState({
        errorMessage: saveResponce.data.note,
        successMessage: "",
        disabled: false,
        waitMessage: "",
      });
      //window.location.replace("/error");
    }
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  formSave = async () => {
    let {
      update_nrc,
      update_mrc,
      service_title,
      service_type_edit,
      update_rate,
      sms_in,
      sms_out,
      product_desc,
      default_nrc,
      default_mrc,
      default_rate,
      default_sms_in,
      default_sms_out,
      agent_mrc_comission,
      agent_nrc_comission,
      agent_rate_comission,
      sms_in_comission,
      sms_out_comission,
    } = this.state;

    var is_validated = false;
    if (service_title === "") {
      is_validated = true;
    } else if (service_type_edit === "sms") {
      if (sms_in === "") {
        is_validated = true;
      } else {
        if (sms_in < this.state.default_sms_in) {
          is_validated = true;
          this.setState({
            sms_in_error: true,
          });
        }
      }
      if (sms_out === "") {
        is_validated = true;
      } else {
        if (sms_out < this.state.default_sms_out) {
          is_validated = true;
          this.setState({
            sms_out_error: true,
          });
        }
      }
    } else if (service_type_edit === "term") {
      if (update_rate === "") {
        is_validated = true;
      } else {
        console.log("rate > defaultRate: ", update_rate + " " + default_rate);
        if (update_rate < default_rate) {
          is_validated = false;
          this.setState({
            rate_error: true,
          });
        }
      }
    } else if (service_type_edit === "ivr") {
      if (update_rate === "") {
        is_validated = true;
      } else {
        if (update_rate < default_rate) {
          is_validated = true;
          this.setState({
            rate_error: true,
          });
        }
      }
    } else if (service_type_edit === "live_agent") {
      if (update_rate === "") {
        is_validated = true;
      } else {
        if (update_rate < default_rate) {
          is_validated = true;
          this.setState({
            rate_error: true,
          });
        }
      }
    } else if (service_type_edit === "did" || service_type_edit === "tfn") {
      if (update_nrc === "") {
        is_validated = true;
      } else {
        if (update_nrc < this.state.default_nrc) {
          is_validated = true;
          this.setState({ nrc_error: true });
        }
      }
      if (agent_nrc_comission === "" || agent_nrc_comission === 0) {
        is_validated = true;
      } else {
        let nrc_diff = update_nrc - this.state.default_nrc;
        if (agent_nrc_comission > nrc_diff) {
          is_validated = true;
          this.setState({ nrc_comission_error: true });
        }
      }
      if (update_mrc === "") {
        is_validated = true;
      } else {
        if (update_mrc < this.state.default_mrc) {
          is_validated = true;
          this.setState({ mrc_error: true });
        }
      }
      if (update_rate === "") {
        is_validated = true;
      } else {
        if (update_rate < this.state.default_rate) {
          is_validated = true;
          this.setState({
            rate_error: true,
          });
        }
      }
    }
    /*else if (service_type_edit === "tfn") {
      if (update_nrc === "") {
        is_validated = true;
      }
      if (update_mrc === "") {
        is_validated = true;
      }
      if (update_rate === "") {
        is_validated = true;
      }
    }*/

    if (is_validated) {
      return this.setState({
        errorMessage:
          "Please enter the required fields or check the highlighted text fields.",
      });
    } else {
      this.setState({ errorMessage: "" });

      this.setState({
        errorMessage: "",
        disabled: true,
      });

      const saveResponce = await serviceRatesUpdate(
        auth.getClientAccount(),
        auth.getClientToken(),
        update_nrc,
        update_mrc,
        update_rate,
        this.state.serviceId,
        service_title,
        service_type_edit,
        sms_in,
        sms_out,
        this.state.agent_mrc_comission,
        this.state.agent_nrc_comission,
        this.state.agent_rate_comission,
        this.state.default_mrc,
        this.state.default_rate,
        this.state.default_nrc,
        this.state.sms_in_comission,
        this.state.sms_out_comission,
        this.state.product_desc
      );

      console.log("status: ", saveResponce.data);
      //console.log("message: ", saveResponce.data.message);
      //console.log("note: ", saveResponce.data.note);
      if (
        saveResponce.data.status === 403 ||
        saveResponce.data.errors === "authentication missing" ||
        saveResponce.data.errors === "jwt expired"
      ) {
        auth.clientLogout();
      } else if (
        saveResponce.data.status === 404 &&
        saveResponce.data.message === "fail" &&
        saveResponce.data.note !== ""
      ) {
        // window.location.replace("/error");
        this.setState({
          errorMessage: saveResponce.data.note,
          successMessage: "",
          disabled: false,
        });
      } else if (
        saveResponce.data.status === 200 &&
        saveResponce.data.message === "success"
      ) {
        this.setState({
          errorMessage: "",
          successMessage: "Rates Saved Successfully.",
          disabled: false,
        });

        $("#viewBtn").show();
        $("#btnloader").hide();
        this.componentDidMount();
        setTimeout(function () {
          window.$("#modalForm").modal("hide");
        }, 3000);
        //this.componentDidMount();
      } else {
        this.setState({
          errorMessage: saveResponce.data.message,
          successMessage: "",
          disabled: false,
        });
        //window.location.replace("/error");
      }
    }
  };

  calculatePercent = (percent, num) => {
    return (percent / 100) * num;
  };
  modalHide = () => {
    window.$("#modalFormDetail").modal("hide");
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  handleChangeDefault = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
    console.log("handleChangeDefault: ", name + " " + value);
    let service_data = this.state.defaultServices;
    console.log("serviceData: ", service_data);
    for (let i = 0; i < service_data.length; i++) {
      if (service_data[i][0] == value) {
        this.setState({
          product_id: service_data[i][0],
          defaultNrc: Number.parseFloat(service_data[i][3]).toFixed(4),
          defaultMrc: Number.parseFloat(service_data[i][4]).toFixed(4),
          rate_type: service_data[i][5],
          defaultRate: Number.parseFloat(service_data[i][5]).toFixed(4),
          defaultSmsIn: service_data[i][7],
          defaultSmsOut: service_data[i][8],
          service_type_edit: service_data[i][9],
        });
      }
    }
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  render() {
    //$(".nk-block .MUIDataTable-paper-2").css("padding", "10px");
    return (
      <div className="nk-content " id="Services_Block">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" &&
          this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" &&
          this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle name="Services" icon="icon ni ni-card-view" />
                    </h3>
                  </div>
                  <div className="nk-block-head-content" id="HeadContent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                      {/* <div
                        className="toggle-expand-content"
                        data-content="pageMenu"
                      >
                        <ul className="nk-block-tools g-3">
                          <li className="nk-block-tools-opt">
                            {this.props.defaultSettings !== "missing" ? (
                              <a
                                href={"/" + auth.getResellerIdentifier() +  "/client/services/Addservice"}
                                style={{
                                  color: "#fff",
                                }}
                                className="btn btn-primary"
                              >
                                <em className="icon ni ni-plus-round"></em>
                                <span>Add New Service</span>
                              </a>
                            ) : null}
                          </li>
                        </ul>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      {/* START DATATABLE */}
                      {this.state.tableLoader === true ? (
                        tableLoader()
                      ) : (
                        <DataTable
                          columns={this.state.columnServices}
                          tableData={this.state.tableData}
                          title="All Services"
                        />
                      )}

                      {/* END TABLE */}
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                  {/*  DETAILS MODAL*/}
                  <div
                    className="modal fade zoom"
                    tabIndex="-1"
                    id="modalFormDetail"
                  >
                    <div
                      className="modal-dialog modal-xl modal-dialog-top"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <div
                            className="modal-title"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <strong>
                              <em className="icon ni ni-list-round"></em>{" "}
                              Service Details
                            </strong>
                          </div>
                          <a
                            href="#"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <em className="icon ni ni-cross"></em>
                          </a>
                        </div>

                        <div className="modal-body">
                          <br />
                          {/* NRC DIVS START */}

                          {this.state.serviceData ? (
                            <>
                              <div>
                                {this.state.serviceData.map((services, idx) => (
                                  <div
                                    className="card card-bordered"
                                    key={`servi${idx}`}
                                  >
                                    <div className="card-inner-group">
                                      {services[6] === "did" ||
                                      services[6] === "tfn" ||
                                      services[6] === "teams" ? (
                                        <div className="card-inner">
                                          <div className="sp-plan-head">
                                            <h6 className="title">
                                              {services[1]}
                                              <span className="badge badge-dim badge-primary badge-pill">
                                                {services[6] === "did"
                                                  ? "DID Origination"
                                                  : ""}
                                                {services[6] === "tfn"
                                                  ? "Toll Free Origination"
                                                  : ""}
                                                {services[6] === "term"
                                                  ? "Voice Termination"
                                                  : ""}
                                                {services[6] === "teams"
                                                  ? "Microsoft Teams UCAAS"
                                                  : ""}
                                                {services[6] === "ivr"
                                                  ? "Voyce Admins"
                                                  : ""}
                                                {services[6] === "tfn_term"
                                                  ? "Toll Free Termination"
                                                  : ""}
                                                {services[6] === "az_term"
                                                  ? "International A-Z Termination"
                                                  : ""}
                                                {services[6] === "live_agent"
                                                  ? "Live Agent"
                                                  : ""}
                                                {services[6] === "sms"
                                                  ? "SMS Inbound / Outbound"
                                                  : ""}
                                              </span>
                                            </h6>
                                          </div>

                                          <div className="sp-plan-desc sp-plan-desc-mb">
                                            <ul className="row gx-1">
                                              <li className="col-sm-4">
                                                <p>
                                                  <span className="text-soft">
                                                    NRC
                                                    <Tooltip
                                                      title="Non-Recurring Charge – A one-time fee that is deducted on service setup."
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>{" "}
                                                    / Spiff
                                                    <Tooltip
                                                      title="Sales Performance Incentive Fund is an Incentive amount earned against a sale / subscription."
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>
                                                  </span>{" "}
                                                  ${services[4]} / $
                                                  {services[13]}
                                                </p>
                                              </li>
                                              <li className="col-sm-4">
                                                <p>
                                                  <span className="text-soft">
                                                    MRC{" "}
                                                    <Tooltip
                                                      title="Monthly Recurring Charge - what you pay each month for the service."
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>{" "}
                                                    / Commission{" "}
                                                    <Tooltip
                                                      title="Commission amount for agent, taken out of profit."
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>
                                                  </span>{" "}
                                                  ${services[5]} / $
                                                  {(
                                                    (services[5] -
                                                      services[15]) *
                                                    (services[12] / 100)
                                                  ).toFixed(2)}{" "}
                                                  ({services[12]}%)
                                                </p>
                                              </li>
                                              {services[23] !== "ratedeck" ? (
                                                <li className="col-sm-4">
                                                  <p>
                                                    <span className="text-soft">
                                                      Rate Per Minute{" "}
                                                      <Tooltip
                                                        title="Rate assigned per minute."
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>{" "}
                                                      / Commission{" "}
                                                      <Tooltip
                                                        title="Commission amount for agent, taken out of profit."
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>
                                                    </span>{" "}
                                                    ${services[7]} / $
                                                    {(
                                                      (services[7] -
                                                        services[16]) *
                                                      (services[14] / 100)
                                                    ).toFixed(2)}{" "}
                                                    ({services[14]}%)
                                                  </p>
                                                </li>
                                              ) : null}
                                            </ul>
                                          </div>
                                        </div>
                                      ) : null}
                                      {services[6] === "term" ||
                                      services[6] === "ivr" ||
                                      services[6] === "live_agent" ||
                                      services[6] === "tfn_term" ||
                                      services[6] === "az_term" ? (
                                        <div className="card-inner">
                                          <div className="sp-plan-head">
                                            <h6 className="title">
                                              {services[1]}{" "}
                                              <span className="badge badge-dim badge-primary badge-pill">
                                                {services[6] === "did"
                                                  ? "DID Origination"
                                                  : ""}
                                                {services[6] === "tfn"
                                                  ? "Toll Free Origination"
                                                  : ""}
                                                {services[6] === "term"
                                                  ? "DID Termination"
                                                  : ""}
                                                {services[6] === "teams"
                                                  ? "Microsoft Teams UCAAS"
                                                  : ""}
                                                {services[6] === "ivr"
                                                  ? "Voyce Admins"
                                                  : ""}
                                                {services[6] === "tfn_term"
                                                  ? "Toll Free Termination"
                                                  : ""}
                                                {services[6] === "az_term"
                                                  ? "International A-Z Termination"
                                                  : ""}
                                                {services[6] === "live_agent"
                                                  ? "Live Agent"
                                                  : ""}
                                                {services[6] === "sms"
                                                  ? "SMS Inbound / Outbound"
                                                  : ""}
                                                {services[23] === "ratedeck"
                                                  ? " ( Rate Deck )"
                                                  : ""}
                                              </span>
                                            </h6>
                                          </div>

                                          <div className="sp-plan-desc sp-plan-desc-mb">
                                            <ul className="row gx-1">
                                              {services[23] === "ratedeck" ? (
                                                <>
                                                  {/*<li className="col-sm-4">
                                                    <p>
                                                      <span className="text-soft">
                                                        Agent Commission
                                                      </span>{" "}
                                                      {services[14]}%
                                                    </p>
                                                  </li>
                                                  <li className="col-sm-4">
                                                    <p>
                                                      <span className="text-soft">
                                                        Effective Date
                                                      </span>{" "}
                                                      {services[24]}
                                                    </p>
                                                  </li>
                                                  <li className="col-sm-4">
                                                    <p>
                                                      <span className="text-soft">
                                                        Download Rate Deck
                                                      </span>{" "}
                                                      <a
                                                        onClick={() =>
                                                          this.downloadCsv(
                                                            services[25]
                                                          )
                                                        }
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        className="btn btn-outline-primary"
                                                      >
                                                        <em className="icon ni ni-download"></em>{" "}
                                                        Download Rate Deck
                                                      </a>
                                                    </p>
                                                  </li>*/}
                                                </>
                                              ) : (
                                                <li className="col-sm-4">
                                                  <p>
                                                    <span className="text-soft">
                                                      Rate Per Minute{" "}
                                                      <Tooltip
                                                        title="Rate assigned per minute."
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>{" "}
                                                      / Commission{" "}
                                                      <Tooltip
                                                        title="Commission amount for agent, taken out of profit."
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>
                                                    </span>{" "}
                                                    ${services[7]} / $
                                                    {(
                                                      (services[7] -
                                                        services[16]) *
                                                      (services[14] / 100)
                                                    ).toFixed(2)}{" "}
                                                    ({services[14]}%)
                                                  </p>
                                                </li>
                                              )}
                                            </ul>
                                          </div>
                                        </div>
                                      ) : null}
                                      {services[6] === "sms" ? (
                                        <div className="card-inner">
                                          <div className="sp-plan-head">
                                            <h6 className="title">
                                              {services[1]}{" "}
                                              <span className="badge badge-dim badge-primary badge-pill">
                                                New
                                              </span>
                                            </h6>
                                          </div>

                                          <div className="sp-plan-desc sp-plan-desc-mb">
                                            <ul className="row gx-1">
                                              <li className="col-sm-5">
                                                <p>
                                                  <span className="text-soft">
                                                    SMS In{" "}
                                                    <Tooltip
                                                      title="Price applied to SMS In Services."
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>{" "}
                                                    / Commission{" "}
                                                    <Tooltip
                                                      title="Commission amount for agent, taken out of profit."
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>
                                                  </span>{" "}
                                                  ${services[10]} / $
                                                  {(
                                                    (services[10] -
                                                      services[15]) *
                                                    (services[17] / 100)
                                                  ).toFixed(2)}{" "}
                                                  ({services[17]}%)
                                                </p>
                                              </li>
                                              <li className="col-sm-5">
                                                <p>
                                                  <span className="text-soft">
                                                    SMS Out{" "}
                                                    <Tooltip
                                                      title="Price applied to SMS Out Services."
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>{" "}
                                                    / Commission{" "}
                                                    <Tooltip
                                                      title="Commission amount for agent, taken out of profit"
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>
                                                  </span>{" "}
                                                  ${services[11]} / $
                                                  {(
                                                    (services[11] -
                                                      services[16]) *
                                                    (services[18] / 100)
                                                  ).toFixed(2)}{" "}
                                                  ({services[18]}%)
                                                </p>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      ) : null}
                                      {services[6] === "did" ||
                                      services[6] === "tfn" ||
                                      services[6] === "teams" ? (
                                        <>
                                          {services[30] &&
                                          services[30].length > 0 ? (
                                            <div className="card-inner">
                                              <div className="sp-plan-head">
                                                <h6 className="title">
                                                  Porting Rates
                                                </h6>
                                              </div>

                                              <div className="sp-plan-desc sp-plan-desc-mb">
                                                <ul className="row gx-1">
                                                  <li className="col-sm-5">
                                                    <p>
                                                      <span className="text-soft">
                                                        Portin Sell Rate / Spiff
                                                      </span>{" "}
                                                      $
                                                      {services[30][0].portin_sell_rate.toFixed(
                                                        4
                                                      )}{" "}
                                                      / $
                                                      {services[30][0].portin_agent_spiff.toFixed(
                                                        2
                                                      )}
                                                    </p>
                                                  </li>
                                                  <li className="col-sm-5">
                                                    <p>
                                                      <span className="text-soft">
                                                        Portin Sell Rate / Spiff
                                                      </span>{" "}
                                                      $
                                                      {services[30][0].portout_sell_rate.toFixed(
                                                        4
                                                      )}{" "}
                                                      / $
                                                      {services[30][0].portout_agent_spiff.toFixed(
                                                        2
                                                      )}
                                                    </p>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          ) : null}
                                        </>
                                      ) : null}
                                      <div className="card-inner">
                                        {services[23] !== "ratedeck" ? (
                                          <>
                                            <div className="sp-plan-head">
                                              <h6 className="title">
                                                Cancellation Fee
                                              </h6>
                                            </div>
                                            <div className="sp-plan-desc sp-plan-desc-mb">
                                              <ul className="row gx-1">
                                                <li className="col-sm-5">
                                                  <p>
                                                    <span className="text-soft">
                                                      Cancellation Fee
                                                    </span>{" "}
                                                    {services[29] &&
                                                    services[29] !== "" ? (
                                                      <>
                                                        $
                                                        {services[29].toFixed(
                                                          4
                                                        )}
                                                      </>
                                                    ) : (
                                                      "$0.00"
                                                    )}
                                                  </p>
                                                </li>
                                              </ul>
                                            </div>
                                          </>
                                        ) : null}
                                        {services[23] === "ratedeck" ? (
                                          <>
                                            <div className="sp-plan-head">
                                              <h6 className="title">
                                                Rate Deck / Effective Date
                                              </h6>
                                            </div>
                                            <div className="sp-plan-desc sp-plan-desc-mb">
                                              <ul className="row gx-1">
                                                <li className="col-sm-5">
                                                  <p>
                                                    <span className="text-soft">
                                                      Rate Deck
                                                    </span>{" "}
                                                    {services[25] &&
                                                    services[25] !== "" ? (
                                                      <>
                                                        <a
                                                          onClick={() =>
                                                            this.downloadCsv(
                                                              services[25]
                                                            )
                                                          }
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          className="btn btn-outline-primary"
                                                        >
                                                          <em className="icon ni ni-download"></em>{" "}
                                                          Download Rate Deck
                                                        </a>
                                                      </>
                                                    ) : null}
                                                  </p>
                                                </li>
                                                <li className="col-sm-5">
                                                  <p>
                                                    <span className="text-soft">
                                                      Effective Date
                                                    </span>{" "}
                                                    {services[24] &&
                                                    services[24] !== "" ? (
                                                      <>
                                                        {this.format_date(
                                                          services[24]
                                                        )}
                                                      </>
                                                    ) : null}
                                                  </p>
                                                </li>
                                              </ul>
                                            </div>
                                          </>
                                        ) : null}
                                        {services[31] &&
                                        services[31].length > 0 ? (
                                          <>
                                            <div
                                              className="sp-plan-head"
                                              style={{
                                                marginTop: "15px",
                                                borderTop: "1px solid #f6f6f6",
                                                paddingTop: "15px",
                                              }}
                                            >
                                              <h6 className="title">
                                                Miscellaneous Rates
                                              </h6>
                                            </div>
                                            <div className="sp-plan-desc sp-plan-desc-mb">
                                              <table className="table">
                                                <thead className="thead-light">
                                                  <tr>
                                                    <th scope="col">Title</th>
                                                    <th scope="col">
                                                      Rate Type
                                                    </th>
                                                    <th scope="col">Rate</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {services[31].map(
                                                    (miscrate, idx) => (
                                                      <tr key={`mis${idx}`}>
                                                        <td>
                                                          {miscrate.misc_title}
                                                        </td>
                                                        <td>
                                                          {miscrate.misc_type ===
                                                          "per_minute"
                                                            ? "Per Minute"
                                                            : ""}
                                                          {miscrate.misc_type ===
                                                          "per_hour"
                                                            ? "Per Hour"
                                                            : ""}
                                                          {miscrate.misc_type ===
                                                          "one_time"
                                                            ? "One Time"
                                                            : ""}
                                                        </td>
                                                        <td>
                                                          {miscrate.misc_rate !==
                                                            "" &&
                                                          miscrate.misc_rate >
                                                            0 ? (
                                                            <>
                                                              $
                                                              {miscrate.misc_rate.toFixed(
                                                                4
                                                              )}
                                                            </>
                                                          ) : (
                                                            "$0.00"
                                                          )}
                                                        </td>
                                                      </tr>
                                                    )
                                                  )}
                                                </tbody>
                                              </table>
                                            </div>
                                          </>
                                        ) : null}
                                      </div>
                                      <div className="card-inner">
                                        <div className="sp-plan-head">
                                          <h6 className="title">
                                            Add / Update Dates
                                          </h6>
                                        </div>
                                        <div className="sp-plan-desc sp-plan-desc-mb">
                                          <ul className="row gx-1">
                                            <li className="col-sm-5">
                                              <p>
                                                <span className="text-soft">
                                                  Date Added
                                                </span>{" "}
                                                {services[19] !== ""
                                                  ? this.format_date(
                                                      services[19]
                                                    )
                                                  : null}
                                              </p>
                                            </li>
                                            <li className="col-sm-5">
                                              <p>
                                                <span className="text-soft">
                                                  Date Updated
                                                </span>{" "}
                                                {services[20] !== ""
                                                  ? this.format_date(
                                                      services[20]
                                                    )
                                                  : null}
                                              </p>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="card-inner">
                                        <div className="sp-plan-head-group">
                                          <div className="sp-plan-head">
                                            <h6 className="title">
                                              Short Description
                                            </h6>
                                            <span className="ff-italic text-soft">
                                              {services[3]}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* DETAILS MODAL */}
                  {/* DEFAULT MODAL START */}
                  <div
                    className="modal fade zoom"
                    tabIndex="-1"
                    id="modalFormDefault"
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <a
                          href="#"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-body modal-body-lg text-center">
                          <div className="nk-modal">
                            <h4 className="nk-modal-title">Set Default</h4>
                            <div className="nk-modal-text">
                              <div className="caption-text">
                                {this.state.errorMessage !== "" ? (
                                  <div className="example-alert">
                                    <div className="alert alert-pro alert-danger">
                                      <div className="alert-text">
                                        <h4>Error</h4>
                                        <p>{this.state.errorMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.successMessage !== "" ? (
                                  <div className="example-alert">
                                    <div className="alert alert-pro alert-success">
                                      <div className="alert-text">
                                        <h4>Success</h4>
                                        <p>{this.state.successMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.waitMessage !== "" ? (
                                  <div className="example-alert">
                                    <div className="alert alert-pro alert-warning">
                                      <div className="alert-text">
                                        <h4>Waiting...</h4>
                                        <p>{this.state.waitMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="nk-modal-action"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* DEFAULT MODAL End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Services);
