import React, { Component, Fragment } from "react";
import {
  AppBar,
  Button,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { LOGO_VERIFY } from "../../../config/config";

import MyCustomTextField from "../../../components/InputFields/TextField";
import MyCustomRadioField from "../../../components/InputFields/RadioField";
import MyCustomCheckBoxField from "../../../components/InputFields/CheckBox";
import MyCustomButton from "../../../components/InputFields/Button";

import HelperClass from "../../../config/helperClass";
import { tableLoader } from "../../ContentLoaders/table_loader";
import Skeleton from "@material-ui/lab/Skeleton";
import { Helmet, HelmetProvider } from "react-helmet-async";

import "../ClientRegister/registration_step.css";

import { registerBusinessPurpose } from "../../../config/client_api_calls";

import "../ClientRegister/custom_radio.css";
import "../ClientRegister/custom_checkbox.css";



const auth = new HelperClass();
export class Step4 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      successMessage: "",
      business_description: [],
      disabled: props.jsonData.step_4.sub_steps[0].button_fields.continue.disabled,
    };
  }

  componentDidMount(){
    const {
      keys,
      values,
    } = this.props.values;

    let business_description = values[keys.business_description].length > 0 ? values[keys.business_description].includes(',') ? values[keys.business_description].split(',') : [values[keys.business_description]] : [];

    this.setState({
      [keys.percentage_of_mix]:values[keys.percentage_of_mix],
      [keys.type_of_business]:values[keys.type_of_business],
      [keys.type_of_traffic]:values[keys.type_of_traffic],
      [keys.us_point_of_entry]:values[keys.us_point_of_entry] === "yes" ? true : values[keys.us_point_of_entry] === "no" ? false : values[keys.us_point_of_entry],
      [keys.business_description]:business_description,
      [keys.goods_services]:values[keys.goods_services],
      [keys.business_engaged_telemarketing]:values[keys.business_engaged_telemarketing] === "yes" ? true : values[keys.business_engaged_telemarketing] === "no" ? false : values[keys.business_engaged_telemarketing],
      [keys.pre_recorded_messages]:values[keys.pre_recorded_messages] === "yes" ? true : values[keys.pre_recorded_messages] === "no" ? false : values[keys.pre_recorded_messages],
      [keys.pre_recorded_messages_approval]:values[keys.pre_recorded_messages_approval] === "yes" ? true : values[keys.pre_recorded_messages_approval] === "no" ? false : values[keys.pre_recorded_messages_approval],
      [keys.transport_robocalls]:values[keys.transport_robocalls] === "yes" ? true : values[keys.transport_robocalls] === "no" ? false : values[keys.transport_robocalls],
      [keys.national_dns_subscription]:values[keys.national_dns_subscription],
    });
  }

  continue = (data) => {
    //e.preventDefault();
    this.props.nextStep(data);
  };
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  submitForms = (event) => {
    event.preventDefault();
    const { jsonData } = this.props;
    const { keys } = this.props.values;

    let percentage_of_mix_json = {      
      "required": jsonData.step_4.sub_steps[0].input_fields.percentage_of_mix.required,
      "visible": jsonData.step_4.sub_steps[0].input_fields.percentage_of_mix.visible  
    };
    let type_of_business_json = {      
      "required": jsonData.step_4.sub_steps[0].radio_fields.type_of_business.required,
      "visible": jsonData.step_4.sub_steps[0].radio_fields.type_of_business.visible  
    };
    let type_of_traffic_json = {      
      "required": jsonData.step_4.sub_steps[0].radio_fields.type_of_traffic.required,
      "visible": jsonData.step_4.sub_steps[0].radio_fields.type_of_traffic.visible  
    };
    let us_point_of_entry_json = {      
      "required": jsonData.step_4.sub_steps[0].checkbox_fields.us_point_of_entry.required,
      "visible": jsonData.step_4.sub_steps[0].checkbox_fields.us_point_of_entry.visible  
    };
    let business_description_json = {      
      "required": jsonData.step_4.sub_steps[0].checkbox_fields.business_description.required,
      "visible": jsonData.step_4.sub_steps[0].checkbox_fields.business_description.visible  
    };
    let goods_services_json = {      
      "required": jsonData.step_4.sub_steps[1].input_fields.goods_services.required,
      "visible": jsonData.step_4.sub_steps[1].input_fields.goods_services.visible  
    };
    let business_engaged_telemarketing_json = {      
      "required": jsonData.step_4.sub_steps[1].radio_fields.business_engaged_telemarketing.required,
      "visible": jsonData.step_4.sub_steps[1].radio_fields.business_engaged_telemarketing.visible  
    };
    let pre_recorded_messages_json = {      
      "required": jsonData.step_4.sub_steps[1].radio_fields.pre_recorded_messages.required,
      "visible": jsonData.step_4.sub_steps[1].radio_fields.pre_recorded_messages.visible  
    };
    let pre_recorded_messages_approval_json = {      
      "required": jsonData.step_4.sub_steps[1].radio_fields.pre_recorded_messages_approval.required,
      "visible": jsonData.step_4.sub_steps[1].radio_fields.pre_recorded_messages_approval.visible  
    };
    let transport_robocalls_json = {      
      "required": jsonData.step_4.sub_steps[1].radio_fields.transport_robocalls.required,
      "visible": jsonData.step_4.sub_steps[1].radio_fields.transport_robocalls.visible  
    };
    let national_dns_subscription_json = {      
      "required": jsonData.step_4.sub_steps[1].radio_fields.national_dns_subscription.required,
      "visible": jsonData.step_4.sub_steps[1].radio_fields.national_dns_subscription.visible  
    };
    
    if(type_of_business_json.visible && type_of_business_json.required && this.state[keys.type_of_business] === "type_of_business_both"){
      if(percentage_of_mix_json.visible && percentage_of_mix_json.required && this.state[keys.percentage_of_mix] === ""){
        this.setState({
          errorMessage: "Please enter Percentage of Mix.",
        });
        return;
      }
      
    }
    if(goods_services_json.visible && goods_services_json.required && this.state[keys.goods_services] === ""){
      this.setState({
        errorMessage:
          "Please describe the goods, services or contributions solicitated.",
      });
      return;
    }

    this.setState({ errorMessage: "", successMessage: "", disabled: true });
    const step4_json = {
      keys: keys,
      values: {
        [keys.percentage_of_mix]: this.state[keys.percentage_of_mix],
        [keys.type_of_business]: this.state[keys.type_of_business],
        [keys.type_of_traffic]: this.state[keys.type_of_traffic],
        [keys.us_point_of_entry]: this.state[keys.us_point_of_entry],
        [keys.business_description]: this.state[keys.business_description],
        [keys.goods_services]: this.state[keys.goods_services],
        [keys.business_engaged_telemarketing]: this.state[keys.business_engaged_telemarketing],
        [keys.pre_recorded_messages]: this.state[keys.pre_recorded_messages],
        [keys.pre_recorded_messages_approval]: this.state[keys.pre_recorded_messages_approval],
        [keys.transport_robocalls]: this.state[keys.transport_robocalls],
        [keys.national_dns_subscription]: this.state[keys.national_dns_subscription],
      },
    };
    console.log('step_4_data', step4_json);
    this.submitBusinessPurpose(step4_json);
    //this.continue(step4_json);

  };

  submitBusinessPurpose = async (businessData) => {
    const {
      signup_hash,
      steps_completed,
      steps_allowed
    } = this.props;
        //console.log('client_email',this.props);
    if (signup_hash !== "") {
      const {keys} = this.props.values;
      try {
        let response = await registerBusinessPurpose({          
          token: process.env.REACT_APP_API_TOKEN,
          api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
          signup_hash: signup_hash,
          percentage_of_mix: businessData.values[keys.percentage_of_mix],
          type_of_business: businessData.values[keys.type_of_business],
          type_of_traffic: businessData.values[keys.type_of_traffic],
          us_point_of_entry: businessData.values[keys.us_point_of_entry],
          business_description: businessData.values[keys.business_description].toString(),
          goods_services: businessData.values[keys.goods_services],
          business_engaged_telemarketing: businessData.values[keys.business_engaged_telemarketing],
          pre_recorded_messages: businessData.values[keys.pre_recorded_messages],
          pre_recorded_messages_approval: businessData.values[keys.pre_recorded_messages_approval],
          transport_robocalls: businessData.values[keys.transport_robocalls],
          national_dns_subscription: businessData.values[keys.national_dns_subscription],          
        });
        console.log('submitBusinessPurpose',response);
        if (response.data.status === 404 ||
            response.data.status === 422
        ){
          this.setState({
            errorMessage: 'Something went wrong. Please try again',
            successMessage: "",
            disabled: false,            
          });          
        }
        else if(response.data.status === 200 &&
          response.data.message === "success"){
            if(steps_completed === (steps_allowed - 1)){
              // this.setState({
              //   errorMessage: '',
              //   successMessage: "Successfully submitted data.",
              //   disabled: true,            
              // });
              this.props.successHandler();
            }
            else{
              this.continue(businessData);
            }            
            
          }
      } catch (err) {
        this.setState({ errorMessage: "", successMessage: "", disabled: false });        
      }
    }

  };

  modalHideIns = () => {
    window.$("#instructionsModal").modal("hide");
  };

  handleChangeCheck = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
    console.log(name + " " + value);
  };

  render() {
    const { jsonData, mStepsUITitles, mStepsUIDesc, steps_completed, steps_allowed } = this.props;
    const {values, keys} = this.props.values;

    //console.log('business_description',this.state["business_description"]);

    return (
      <>
        <HelmetProvider>
          <Helmet>
            <link
              rel="stylesheet"
              href="../../assets/ui_latest/css/dashlite.css?ver=3.0.3"
            />
            <link
              id="skin-default"
              rel="stylesheet"
              href="../../assets/ui_latest/csstheme.css?ver=3.0.3"
            />
          </Helmet>
        </HelmetProvider>
        <div className="nk-block nk-block-lg" style={{ width: "100%" }}>
          <div
            className="card card-bordered"
            style={{
              border: "none",
              paddingTop: "2%",
              paddingLeft: "5%",
              paddingRight: "5%",
            }}
          >
            <form
              className="nk-stepper stepper-init is-alter nk-stepper-s1"
              action="#"
              onSubmit={this.submitForms}
              id="stepper-create-profile"
            >
              <div className="row g-0 col-sep col-sep-md col-sep-xl">
                <div className="col-md-4 col-xl-4">
                  <div className="card-inner" id="registration_div">
                    <ul className="nk-stepper-nav nk-stepper-nav-s1 stepper-nav is-vr">
                    <li className={steps_completed === 0 ? "current" : "done"}>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">
                              {mStepsUITitles[0]}
                            </div>
                            <div className="sub-text">
                              {mStepsUIDesc[0]}
                            </div>
                          </div>
                        </div>
                      </li>
                      {
                        steps_allowed >= 2 ? <li className={steps_completed === 1  ? "current" : steps_completed > 1 && steps_completed <= 3 ? "done" : ""}>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">
                            {mStepsUITitles[1]}
                            </div>
                            <div className="sub-text">
                            {mStepsUIDesc[1]}
                            </div>
                          </div>
                        </div>
                      </li> : null
                      }
                      {
                        steps_allowed >= 3 ? <li className={steps_completed === 2  ? "current" : steps_completed > 2 && steps_completed <= 4 ? "done" : ""}>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">
                            {mStepsUITitles[2]}
                            </div>
                            <div className="sub-text">
                            {mStepsUIDesc[2]}
                            </div>
                          </div>
                        </div>
                      </li> : null
                      }
                      {
                        steps_allowed >= 4 ? <li className={steps_completed === 3  ? "current" : steps_completed > 3 && steps_completed <= 5 ? "done" : ""}>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">
                            {mStepsUITitles[3]}
                            </div>
                            <div className="sub-text">
                            {mStepsUIDesc[3]}
                            </div>
                          </div>
                        </div>
                      </li> : null
                      }
                      {
                        steps_allowed >= 5 ? <li className={steps_completed === 4  ? "current" : steps_completed > 4 && steps_completed <= 6 ? "done" : ""}>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">
                            {mStepsUITitles[4]}
                            </div>
                            <div className="sub-text">
                            {mStepsUIDesc[4]}
                            </div>
                          </div>
                        </div>
                      </li> : null
                      }
                      {
                        steps_allowed >= 6 ? <li className={steps_completed === 5  ? "current" : steps_completed > 5 && steps_completed <= 7 ? "done" : ""}>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">
                            {mStepsUITitles[5]}
                            </div>
                            <div className="sub-text">
                            {mStepsUIDesc[5]}
                            </div>
                          </div>
                        </div>
                      </li> : null
                      }
                      {
                        steps_allowed >= 7 ? <li className={steps_completed === 6  ? "current" : steps_completed > 6 && steps_completed <= 8 ? "done" : ""}>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">
                            {mStepsUITitles[6]}
                            </div>
                            <div className="sub-text">
                            {mStepsUIDesc[6]}
                            </div>
                          </div>
                        </div>
                      </li> : null
                      }
                      {steps_allowed >= 8 ? (
                        <li
                          className={
                            steps_completed === 7
                              ? "current"
                              : steps_completed > 7 && steps_completed <= 9
                              ? "done"
                              : ""
                          }
                        >
                          <div className="step-item">
                            <div className="step-text">
                              <div className="lead-text">
                                {mStepsUITitles[7]}
                              </div>
                              <div className="sub-text">{mStepsUIDesc[7]}</div>
                            </div>
                          </div>
                        </li>
                      ) : null}
                      {/* <li>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">Account Password</div>
                            <div className="sub-text">
                              Account password to access the portal
                            </div>
                          </div>
                        </div>
                      </li> */}
                      {/* <li>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">Account Password</div>
                            <div className="sub-text">
                              Account password to access the portal
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">Preview Details</div>
                            <div className="sub-text">Review and Submit</div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">Completed</div>
                            <div className="sub-text">You are done.</div>
                          </div>
                        </div>
                      </li> */}
                    </ul>
                  </div>
                </div>
                <div className="col-md-8 col-xl-8">
                  <div className="card-inner">
                    <div className="nk-stepper-content">
                      <div className="nk-stepper-steps stepper-steps">
                        {this.state.errorMessage !== "" ? (
                          <div
                            className="example-alert"
                            style={{ marginBottom: "20px" }}
                          >
                            <div className="alert alert-pro alert-danger">
                              <div className="alert-text">
                                <h4>Error</h4>
                                <p>{this.state.errorMessage}</p>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div className="nk-stepper-step done"></div>
                        <div className="nk-stepper-step done"></div>
                        <div className="nk-stepper-step done"></div>
                        <div className="nk-stepper-step active">
                          <h4
                            className="title mb-3"
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {jsonData.step_4.sub_steps[0].title}
                          </h4>
                          <p
                            style={{
                              textAlign: "center",
                              marginLeft: "10%",
                              marginRight: "10%",
                            }}
                          >
                            {jsonData.step_4.sub_steps[0].description}
                          </p>
                          <div className="row g-3">
                            {jsonData.step_4.sub_steps[0].radio_fields
                              .type_of_business.visible ? (
                              <div className="col-12">
                                <div className="form-group">
                                  <label className="form-label">
                                    {
                                      jsonData.step_4.sub_steps[0].radio_fields
                                        .type_of_business.label
                                    }
                                    {jsonData.step_4.sub_steps[0].radio_fields
                                      .type_of_business.required ? (
                                      <sup style={{ color: "red" }}>*</sup>
                                    ) : null}
                                  </label>
                                  <ul className="custom-radio">
                                    {jsonData.step_4.sub_steps[0].radio_fields.type_of_business.data.map(
                                      (result, index) => (
                                        <li
                                          key={`type_of_business${index}`}
                                          className="col-lg-3"
                                        >
                                          <MyCustomRadioField
                                            data={result}
                                            onChange={this.handleChangeCheck}
                                            defaultChecked={
                                              result.id ===
                                              this.state[keys.type_of_business]
                                                ? true
                                                : false
                                            }
                                          />
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </div>
                            ) : null}
                            {jsonData.step_4.sub_steps[0].input_fields
                              .percentage_of_mix.visible && this.state[keys.type_of_business] === "both" ? (
                              <MyCustomTextField
                                data={
                                  jsonData.step_4.sub_steps[0].input_fields
                                    .percentage_of_mix
                                }
                                onChange={this.handleChangeCheck}
                                defaultValue={`${
                                  values[keys.percentage_of_mix]
                                }`}
                              />
                            ) : null}
                            {jsonData.step_4.sub_steps[0].checkbox_fields
                              .business_description.visible ? (
                              <div className="form-group">
                                <label className="form-label">
                                  {
                                    jsonData.step_4.sub_steps[0].checkbox_fields
                                      .business_description.label
                                  }
                                  {jsonData.step_4.sub_steps[0].checkbox_fields
                                    .business_description.required ? (
                                    <sup style={{ color: "red" }}>*</sup>
                                  ) : null}
                                </label>

                                <ul className="custom-control-group custom-control-horizontal custom-control-stacked w-100">
                                  {jsonData.step_4.sub_steps[0].checkbox_fields.business_description.data.map(
                                    (result, index) => (
                                      <li key={`business_description${index}`}>
                                        <MyCustomCheckBoxField
                                          data={result}
                                          checked={this.state['business_description'].includes(result.value) ? true : false}
                                          onChange={(e) => {
                                            if(e.target.checked){
                                              this.setState({
                                                business_description: [
                                                  ...this.state["business_description"],
                                                  result.value
                                                ]
                                              });
                                            }
                                            else{
                                              let filteredData = this.state["business_description"].filter((data) => data !== result.value);
                                              this.setState({
                                                business_description: filteredData
                                              });
                                            }
                                          }}
                                        />
                                        <span className="ms-2" />
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            ) : null}
                            {jsonData.step_4.sub_steps[0].checkbox_fields
                              .us_point_of_entry.visible ? (
                              <div className="form-group">
                                <ul className="custom-control-group custom-control-vertical custom-control-stacked w-100">
                                  {jsonData.step_4.sub_steps[0].checkbox_fields.us_point_of_entry.data.map(
                                    (result, index) => (
                                      <li key={`us_point_of_entry${index}`}>
                                        <MyCustomCheckBoxField
                                          data={result}
                                          onChange={this.handleChangeCheck}
                                          checked={
                                            this.state[keys.us_point_of_entry]
                                          }
                                        />
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            ) : null}
                            {jsonData.step_4.sub_steps[0].radio_fields
                              .type_of_traffic.visible ? (
                              <div className="form-group">
                                <label className="form-label">
                                  {
                                    jsonData.step_4.sub_steps[0].radio_fields
                                      .type_of_traffic.label
                                  }
                                  {jsonData.step_4.sub_steps[0].radio_fields
                                    .type_of_traffic.required ? (
                                    <sup style={{ color: "red" }}>*</sup>
                                  ) : null}
                                </label>
                                <ul className="custom-radio">
                                  {jsonData.step_4.sub_steps[0].radio_fields.type_of_traffic.data.map(
                                    (result, index) => (
                                      <li
                                        key={`type_of_traffic${index}`}
                                        className="col-lg-3"
                                      >
                                        <MyCustomRadioField
                                          data={result}
                                          onChange={this.handleChangeCheck}
                                          defaultChecked={
                                            result.id ===
                                            this.state[keys.type_of_traffic]
                                              ? true
                                              : false
                                          }
                                        />
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            ) : null}
                            {
                              <div
                                style={{
                                  marginTop: "3%",
                                }}
                              >
                                <h4
                                  className="title mb-3"
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  {jsonData.step_4.sub_steps[1].title}
                                </h4>
                                <p
                                  style={{
                                    textAlign: "center",
                                    marginBottom: "5%",
                                  }}
                                >
                                  {jsonData.step_4.sub_steps[1].description}
                                </p>
                                {jsonData.step_4.sub_steps[1].checkbox_fields
                                  .business_engaged_telemarketing.visible ? (
                                  <div className="form-group">
                                    <ul className="custom-control-group custom-control-vertical custom-control-stacked w-100">
                                      {jsonData.step_4.sub_steps[1].checkbox_fields.business_engaged_telemarketing.data.map(
                                        (result, index) => (
                                          <li
                                            key={`business_engaged_telemarketing${index}`}
                                          >
                                            <MyCustomCheckBoxField
                                              data={result}
                                              onChange={this.handleChangeCheck}
                                              checked={
                                                this.state[
                                                  keys
                                                    .business_engaged_telemarketing
                                                ]
                                              }
                                            />
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                ) : null}
                                {jsonData.step_4.sub_steps[1].checkbox_fields
                                  .pre_recorded_messages.visible ? (
                                  <div className="form-group">
                                    <ul className="custom-control-group custom-control-vertical custom-control-stacked w-100">
                                      {jsonData.step_4.sub_steps[1].checkbox_fields.pre_recorded_messages.data.map(
                                        (result, index) => (
                                          <li
                                            key={`pre_recorded_messages${index}`}
                                          >
                                            <MyCustomCheckBoxField
                                              data={result}
                                              onChange={this.handleChangeCheck}
                                              checked={
                                                this.state[
                                                  keys.pre_recorded_messages
                                                ]
                                              }
                                            />
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                ) : null}
                                {jsonData.step_4.sub_steps[1].checkbox_fields
                                  .pre_recorded_messages_approval.visible ? (
                                  <div className="form-group">
                                    <ul className="custom-control-group custom-control-vertical custom-control-stacked w-100">
                                      {jsonData.step_4.sub_steps[1].checkbox_fields.pre_recorded_messages_approval.data.map(
                                        (result, index) => (
                                          <li
                                            key={`pre_recorded_messages_approval${index}`}
                                          >
                                            <MyCustomCheckBoxField
                                              data={result}
                                              onChange={this.handleChangeCheck}
                                              checked={
                                                this.state[
                                                  keys
                                                    .pre_recorded_messages_approval
                                                ]
                                              }
                                            />
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                ) : null}
                                {jsonData.step_4.sub_steps[1].checkbox_fields
                                  .transport_robocalls.visible ? (
                                  <div className="form-group">
                                    <ul className="custom-control-group custom-control-vertical custom-control-stacked w-100">
                                      {jsonData.step_4.sub_steps[1].checkbox_fields.transport_robocalls.data.map(
                                        (result, index) => (
                                          <li
                                            key={`transport_robocalls${index}`}
                                          >
                                            <MyCustomCheckBoxField
                                              data={result}
                                              onChange={this.handleChangeCheck}
                                              checked={
                                                this.state[
                                                  keys.transport_robocalls
                                                ]
                                              }
                                            />
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                ) : null}
                                {jsonData.step_4.sub_steps[1].input_fields
                                  .goods_services.visible ? (
                                  <div
                                    className="form-group"
                                    style={{
                                      marginTop: "5%",
                                    }}
                                  >
                                    <label className="form-label">
                                      {
                                        jsonData.step_4.sub_steps[1]
                                          .input_fields.goods_services.label
                                      }
                                      {jsonData.step_4.sub_steps[1].input_fields
                                        .goods_services.required ? (
                                        <sup style={{ color: "red" }}>*</sup>
                                      ) : null}
                                    </label>
                                    <textarea
                                      className={"form-control form-control-lg"}
                                      id={
                                        jsonData.step_4.sub_steps[1]
                                          .input_fields.goods_services.id
                                      }
                                      name={
                                        jsonData.step_4.sub_steps[1]
                                          .input_fields.goods_services.name
                                      }
                                      onChange={this.handleChangeCheck}
                                      defaultValue={`${
                                        values[keys.goods_services]
                                      }`}
                                      placeholder={
                                        jsonData.step_4.sub_steps[1]
                                          .input_fields.goods_services
                                          .placeholder
                                      }
                                      disabled={
                                        jsonData.step_4.sub_steps[1]
                                          .input_fields.goods_services.disabled
                                      }
                                      required={
                                        jsonData.step_4.sub_steps[1]
                                          .input_fields.goods_services.required
                                      }
                                    />
                                    {/* <MyCustomTextField
                                      data={
                                        jsonData.step_4.sub_steps[1]
                                          .input_fields.goods_services
                                      }
                                      onChange={this.handleChangeCheck}
                                      defaultValue={`${
                                        values[keys.goods_services]
                                      }`}
                                    /> */}
                                  </div>
                                ) : null}
                                {jsonData.step_4.sub_steps[1].radio_fields
                                  .national_dns_subscription.visible ? (
                                  <div
                                    className="form-group"
                                    style={{
                                      marginTop: "5%",
                                    }}
                                  >
                                    <label className="form-label">
                                      {
                                        jsonData.step_4.sub_steps[1]
                                          .radio_fields
                                          .national_dns_subscription.label
                                      }
                                      {jsonData.step_4.sub_steps[1].radio_fields
                                        .national_dns_subscription.required ? (
                                        <sup style={{ color: "red" }}>*</sup>
                                      ) : null}
                                    </label>
                                    <ul className="custom-radio">
                                      {jsonData.step_4.sub_steps[1].radio_fields.national_dns_subscription.data.map(
                                        (result, index) => (
                                          <li
                                            key={`national_dns_subscription${index}`}
                                            className="col-lg-3"
                                          >
                                            <MyCustomRadioField
                                              data={result}
                                              onChange={this.handleChangeCheck}
                                              defaultChecked={
                                                result.id ===
                                                this.state[keys.national_dns_subscription]
                                                  ? true
                                                  : false
                                              }
                                            />
                                            <span className="ms-2" />
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                ) : null}
                              </div>
                            }
                          </div>
                        </div>
                        <div className="nk-stepper-step"></div>
                        <div className="nk-stepper-step"></div>
                        <div className="nk-stepper-step"></div>
                        <div className="nk-stepper-step text-center"></div>
                      </div>
                      <div className="row mt-5 gx-1 gy-1">
                      {jsonData.step_4.sub_steps[0].button_fields.back
                          .visible && steps_completed > 0 ? (
                          <div className="col-lg-6">
                            <MyCustomButton
                              data={
                                jsonData.step_4.sub_steps[0].button_fields.back
                              }
                              onClick={this.back}
                            />
                          </div>
                        ) : null}
                        {jsonData.step_4.sub_steps[0].button_fields.continue
                          .visible && steps_completed < steps_allowed - 1 ? (
                            <div className={steps_completed === 0 ? "col-lg-12" : "col-lg-6"}>
                            <MyCustomButton
                              data={
                                jsonData.step_4.sub_steps[0].button_fields
                                  .continue
                              }
                              //onClick={this.submitForms}
                              disabled={this.state.disabled}
                            />
                          </div>
                        ) : null}
                        {jsonData.step_4.sub_steps[0].button_fields.submit
                          .visible && steps_completed === (steps_allowed - 1)  ? (
                          <div className="col-lg-6">
                            <MyCustomButton
                              data={
                                jsonData.step_4.sub_steps[0].button_fields
                                  .submit
                              }
                              //onClick={this.submitForms}
                              disabled={this.state.disabled}
                            />
                          </div>
                        ) : null}
                      </div>
                      {/* <div
                        className="text-center"
                        style={{ marginTop: "25px" }}
                      >
                        Already have an account ? <a href="">Sign in instead</a>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default Step4;
