export default function Button(props) {
  return (
    <button
    style={{
      width:"100%",
      padding: "10px",
      display: 'block'

    }}
      type={props.data.type}
      className={props.data.classname}
      id={props.data.id}
      name={props.data.name}
      disabled={props.disabled}
      required={props.data.required}
      onClick={props.onClick}
    >
      {!props.disabled ? (
        props.data.value
      ) : (
        <>
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="false"
          ></span>
          <span class="sr-only">Loading...</span>
        </>
      )}
    </button>
  );
}
