import { COMPANY_NAME, COMPANY_WEBSITE } from "./../../config/config";

export default function footer() {
  return (
    <div className="nk-footer">
      <div className="container-fluid">
        <div className="nk-footer-wrap" style={{ display: "inline" }}>
          <div className="nk-footer-copyright text-right">
            {" "}
            &copy; 2022-2023 All Rights Reserved.{" "}
            <a href="https://onboardsoft.com/">OnBoardsoft LLC.</a>
          </div>
          {/* <div className="nk-footer-links">
            <ul className="nav nav-sm">
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Terms
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Privacy
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Help
                </a>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    </div>
  );
}
