import { React } from "react";
import { Component } from "react";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import { addSettings, addMsaInfo } from "../../../actions";
import { ValidateEmail, normalize } from "../../../config/utility";
import {
  listSubAgents,
  getSingleAgent,
  editSubAgent,
  listServices,
  getAgentSplit,
  addAgentSplit,
} from "./../../../config/api_calls";
import HelperClass from "./../../../config/helperClass";
import $ from "jquery";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { tableLoaderListing } from "../../../components/ContentLoaders/table_loader_listing";
import PageTitle from "../ExtraComponents/PageTitle";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import StepWizard from "react-step-wizard";
import MutextField from "@material-ui/core/TextField";

const auth = new HelperClass();
class Agents extends Component {
  constructor() {
    super();
    this.state = {
      tableData: [],
      servicesData: [],
      splitData: [],
      errorMessage: "",
      agentId: "",
      successMessage: "",
      waitMessage: "",
      tableLoader: true,
      tableLoaderListing: true,
      errorMessageEdit: "",
      successMessageEdit: "",
      title: "",
      name: "",
      agent_email: "",
      agent_phone: "",
      agent_password: "",
      c_password: "",
      agent_id: "",
      sub_agent_id: "",
      formValues: [],
      columnServices: [
        { name: "Date Added" },
        { name: "Title" },
        { name: "Name" },
        { name: "Email" },
        {
          name: "Status",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.setStatus(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Action",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ paddingTop: "0px", paddingBottom: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            href="#"
                            title="Other Actions"
                            className="dropdown-toggle btn btn-icon  btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  href="#"
                                  onClick={() =>
                                    this.editAgentForm(value, tableMeta)
                                  }
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Edit Sub Agent"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-edit"></em>
                                  <span>Edit Sub Agent</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  onClick={() =>
                                    this.agentSplit(value, tableMeta)
                                  }
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Edit Sub Agent"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-share"></em>
                                  <span>Agent Split</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
    };
  }

  async componentDidMount() {
    const servicesResponce = await listSubAgents(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("sub-agent.data.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoader: false,
      });
    } else {
      // window.location.replace("/error");
    }
  }

  setStatus = (value, tableMeta) => {
    let domain_str = tableMeta.rowData[4];
    console.log("domain_str: ", domain_str);
    if (domain_str === "active") {
      return (
        <div>
          <span className="badge badge-outline-success">
            {" "}
            <em className="icon ni ni-done" title="Active"></em> Active
          </span>
        </div>
      );
    } else {
      return (
        <div>
          <span className="badge badge-outline-danger">
            {" "}
            <em className="icon ni ni-alert-c"></em> Inactive
          </span>
        </div>
      );
    }
  };

  setMarkUp = async (value, tableMeta) => {
    this.setState({
      product_desc: tableMeta.rowData[8],
    });
    window.$("#modalFormDetail").modal("show");
  };

  agentSplit = async (value, tableMeta) => {
    let sub_agent_id = value;
    const servicesResponce = await listServices(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("servicesResponce.data.data: ", servicesResponce.data);
    this.setState({
      errorMessage: "",
      successMessage: "",
    });
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let formValues = [];
      let all_data = servicesResponce.data.data;
      for (let i = 0; i < all_data.length; i++) {
        let created = "80/20_" + all_data[i][0];
        formValues.push(created);
      }
      this.setState({
        servicesData: servicesResponce.data.data,
        tableLoader: false,
        tableLoaderListing: false,
        sub_agent_id: sub_agent_id,
        formValues: formValues,
      });
      this.getAgentSplit(servicesResponce.data.data);
    } else {
      //window.location.replace("/error");
    }
    window.$("#modalFormSplit").modal("show");
  };

  getAgentSplit = async (services) => {
    let { sub_agent_id } = this.state;

    const servicesResponce = await getAgentSplit(
      auth.getAccount(),
      auth.getToken(),
      sub_agent_id,
      JSON.stringify(services)
    );
    console.log("getAgentSplit: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        tableLoader: false,
        tableLoaderListing: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        splitData: servicesResponce.data.data,
        tableLoader: false,
        tableLoaderListing: false,
      });
    } else {
      this.setState({
        tableLoader: false,
        tableLoaderListing: false,
      });
    }
  };

  editAgentForm = async (value, tableMeta) => {
    let agent_id = tableMeta.rowData[5];
    const servicesResponce = await getSingleAgent(
      auth.getAccount(),
      auth.getToken(),
      agent_id
    );
    //console.log("servicesResponce.data.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        title: servicesResponce.data.data[0]["title"],
        name: servicesResponce.data.data[0]["name"],
        agent_email: servicesResponce.data.data[0]["email"],
        agent_phone: servicesResponce.data.data[0]["phone"],
        agent_id: servicesResponce.data.data[0]["id"],
        tableLoader: false,
      });
      window.$("#modalFormDetail").modal("show");
    } else {
      // window.location.replace("/error");
    }
  };

  formUpdateSubmit = async () => {
    let { title, name, agent_email, agent_password, c_password, agent_phone } =
      this.state;

    let is_validated = true;
    if (this.state.name === "") {
      this.setState({
        errorCompName: "error",
        errorMessageEdit: "Agent Name is required.",
      });
      is_validated = false;
    } else if (this.state.title === "") {
      this.setState({
        errorCompName: "",
        errorName: "error",
        errorMessageEdit: "Title is required.",
      });
      is_validated = false;
    } else if (this.state.agent_phone === "") {
      this.setState({
        errorPhone: "error",
        errorMessageEdit: "Phone is required.",
      });
      is_validated = false;
    } else if (normalize(this.state.agent_phone) === null) {
      this.setState({
        errorPhone: "error",
        errorMessageEdit: "Invalid phone format.",
      });
      is_validated = false;
    } else if (ValidateEmail(this.state.agent_email) === false) {
      this.setState({
        errorEmail: "error",
        errorName: "",
        errorMessageEdit: "Invalid email format.",
      });
      is_validated = false;
    } else if (this.state.agent_password !== "") {
      if (this.state.agent_password.length < 8) {
        this.setState({
          errorPass: "error",
          errorEmail: "",

          errorPhone: "",
          errorMessageEdit: "Use 8 characters or more for your password",
        });
        is_validated = false;
      } else if (this.state.c_password === "") {
        this.setState({
          errorPass: "error",
          errorEmail: "",
          errorPhone: "",
          errorMessageEdit: "Confirm password is required.",
        });
        is_validated = false;
      } else if (this.state.c_password.length < 8) {
        this.setState({
          errorPass: "error",
          errorEmail: "",
          errorPhone: "",
          errorMessageEdit:
            "Use 8 characters or more for your confirm password",
        });
        is_validated = false;
      } else if (
        this.state.agent_password.trim() != this.state.c_password.trim()
      ) {
        this.setState({
          errorPass: "error",

          errorEmail: "",

          errorPhone: "",
          errorMessageEdit: "Password and confirm password does not match.",
        });
        is_validated = false;
      }
    }
    if (is_validated) {
      let apiResponce = await editSubAgent(
        auth.getAccount(),
        auth.getToken(),
        title,
        name,
        agent_email,
        agent_password,
        agent_phone,
        this.state.agent_id
      );

      if (
        apiResponce.data.status === 403 ||
        apiResponce.data.errors === "authentication missing" ||
        apiResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        apiResponce.data.status === 200 &&
        apiResponce.data.message === "Success"
      ) {
        this.setState({
          successMessageEdit: "Sub agent updated successfully.",
          errorMessageEdit: "",
          tableLoader: false,
        });
        setTimeout(async function () {
          //window.$("#modalFormDetail").modal("hide");
          window.location.replace("/agents");
        }, 3000);
      } else if (
        apiResponce.data.status === 404 &&
        apiResponce.data.message === "fail"
      ) {
        this.setState({
          errorMessageEdit: "Something went wrong,try again later.",
          successMessageEdit: "",
          disabled: false,
          tableLoader: false,
        });
        return false;
      } else {
        this.setState({
          errorMessageEdit: "Something went wrong,try again later.",
          successMessageEdit: "",
          disabled: false,
          tableLoader: false,
        });
        //auth.logout();
      }
    }
  };

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleChangeSplit = (e, i) => {
    console.log(
      "e.target.name  :  e.target.value",
      e.target.name + " : " + e.target.value
    );
    let formValues = [...this.state.formValues];
    formValues[i] = e.target.value;
    this.setState({ formValues });
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  formSplitUpdate = async () => {
    let { formValues, sub_agent_id } = this.state;

    const servicesResponce = await addAgentSplit(
      auth.getAccount(),
      auth.getToken(),
      sub_agent_id,
      JSON.stringify(this.state.formValues)
    );
    console.log("sub_agent_id: ", sub_agent_id);
    console.log("formValues: ", this.state.formValues);
    console.log("formSplitUpdate: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while adding the sub agent split.",
        successMessage: "",
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        errorMessage: "",
        successMessage: "Sub agent split added successfully",
      });
      this.getAgentSplit(this.state.servicesData);
    } else {
      this.setState({
        errorMessage: "There is some error while adding the sub agent split.",
        successMessage: "",
      });
      //window.location.replace("/error");
    }
    setTimeout(() => {
      this.setState({ successMessage: "", errorMessage: "" });
    }, 4000);
  };

  render() {
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {/* MSA WARNING  START*/}
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}
          {/* MSA WARNING END */}
          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle
                        name="Sub Agents / Sub Channel Partners"
                        icon="icon ni ni-users"
                      />
                    </h3>
                  </div>
                  <div className="nk-block-head-content" id="HeadContent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                      <div
                        className="toggle-expand-content"
                        data-content="pageMenu"
                      >
                        <ul className="nk-block-tools g-3">
                          <li className="nk-block-tools-opt">
                            <Link
                              color="inherit"
                              onClick={() =>
                                this.addMenuHistory("/agents/addagent")
                              }
                              className="btn btn-primary"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                                color: "#fff",
                              }}
                            >
                              <em className="icon ni ni-plus-round"></em>
                              &nbsp;Add New Sub Agent
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* PAGE TITLE END */}
              <div className="nk-block">
                {/* Table 5th Col Start */}
                <div className="row g-gs">
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      {/* START DATATABLE */}
                      {this.state.tableLoader === true ? (
                        tableLoader()
                      ) : (
                        <DataTable
                          columns={this.state.columnServices}
                          tableData={this.state.tableData}
                          title="All Agents"
                        />
                      )}

                      {/* END TABLE */}
                    </div>
                  </div>
                </div>
                {/* Table 5th Col End */}
              </div>
              {/* EDIT MODAL End */}
              <div className="modal fade" tabIndex="-1" id="modalFormDetail">
                <div className="modal-dialog modal-xl" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">
                        {" "}
                        <em className="icon ni ni-edit"></em> Edit Sub Agent
                      </h5>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body">
                      {/* Error Start */}
                      {this.state.errorMessageEdit !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "20px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessageEdit}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessageEdit !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "20px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessageEdit}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <br />
                      <div className="row g-4">
                        <form method="post" encType="multipart/form-data">
                          <div className="row g-4">
                            <div className="form-group col-md-6">
                              <MutextField
                                required
                                id="name"
                                name="name"
                                type="text"
                                label="Sub Agent Name"
                                inputProps={{ maxLength: 100 }}
                                value={this.state.name}
                                onChange={this.handleChange}
                                helperText="Enter the agent name."
                                variant="outlined"
                                fullWidth
                              />
                            </div>

                            <div className="form-group col-md-6">
                              <MutextField
                                required
                                id="title"
                                name="title"
                                type="text"
                                inputProps={{ maxLength: 30 }}
                                label="Agent Title"
                                value={this.state.title}
                                onChange={this.handleChange}
                                helperText="Enter the title here."
                                variant="outlined"
                                fullWidth
                              />
                            </div>

                            <div className="form-group col-md-6">
                              <MutextField
                                required
                                id="agent_email"
                                name="agent_email"
                                type="email"
                                label="Email Address"
                                value={this.state.agent_email}
                                onChange={this.handleChange}
                                helperText="Enter your email address here."
                                variant="outlined"
                                fullWidth
                              />
                            </div>

                            <div className="form-group col-md-6">
                              <MutextField
                                required
                                id="agent_phone"
                                name="agent_phone"
                                type="number"
                                label="Phone Number"
                                inputProps={{ maxLength: 11 }}
                                value={this.state.agent_phone}
                                onChange={this.handleChange}
                                helperText="Enter the phone number here."
                                variant="outlined"
                                fullWidth
                              />
                            </div>

                            <div className="form-group col-md-6">
                              <MutextField
                                required
                                id="agent_password"
                                name="agent_password"
                                type="password"
                                label="Paasword"
                                value={this.state.agent_password}
                                inputProps={{ maxLength: 30 }}
                                onChange={this.handleChange}
                                helperText="Enter your password here."
                                variant="outlined"
                                fullWidth
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <MutextField
                                required
                                id="c_password"
                                name="c_password"
                                type="password"
                                label="Confirm Password"
                                value={this.state.c_password}
                                inputProps={{ maxLength: 30 }}
                                onChange={this.handleChange}
                                helperText="Enter the confirm password here."
                                variant="outlined"
                                fullWidth
                              />
                            </div>

                            <div style={{ clear: "both" }}></div>
                            <hr />
                            <div className="form-group text-right col-md-12">
                              <button
                                className="btn btn-lg btn-primary"
                                type="button"
                                disabled={this.state.disabled}
                                onClick={this.formUpdateSubmit}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>

                      {/* </form> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* EDIT MODAL End */}
              {/* DETAIL MODAL End */}
              <div className="modal fade" tabIndex="-1" id="modalFormSplit">
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">
                        {" "}
                        <em className="icon ni ni-edit"></em> Sub Agent Split
                      </h5>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body">
                      {/* Error Start */}

                      <div className="col-lg-12 col-md-12 col-xxl-12">
                        <div
                          id="accordion-2"
                          className="accordion accordion-s3"
                        >
                          <div className="accordion-item">
                            <a
                              href="#"
                              className="accordion-head"
                              data-toggle="collapse"
                              data-target="#accordion-item-2-1"
                            >
                              <h6 className="title">Sub Agent Split</h6>
                              <span className="accordion-icon"></span>
                            </a>
                            <div
                              className="accordion-body collapse show"
                              id="accordion-item-2-1"
                              data-parent="#accordion-2"
                            >
                              <div className="accordion-inner">
                                {this.state.tableLoaderListing === true ? (
                                  tableLoaderListing()
                                ) : (
                                  <>
                                    {this.state.splitData ? (
                                      <table className="table">
                                        <thead className="thead-light">
                                          <tr>
                                            <th scope="col">Service</th>
                                            <th scope="col">Sub Agent Split</th>
                                            <th scope="col">Date Added</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.state.splitData.map(
                                            (splits, index) => (
                                              <tr key={`index${index}`}>
                                                <td>{splits[0]}</td>
                                                <td>{splits[1]}</td>
                                                <td>{splits[2]}</td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </table>
                                    ) : (
                                      <div
                                        className="example-alert"
                                        style={{ marginBottom: "20px" }}
                                      >
                                        <div className="alert alert-pro alert-warning">
                                          <div className="alert-text">
                                            <h4>Warning</h4>
                                            <p>Agent split is not added.</p>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <a
                              href="#"
                              className="accordion-head collapsed"
                              data-toggle="collapse"
                              data-target="#accordion-item-2-2"
                            >
                              <h6 className="title">Update Sub Agent Split</h6>
                              <span className="accordion-icon"></span>
                            </a>
                            <div
                              className="accordion-body collapse"
                              id="accordion-item-2-2"
                              data-parent="#accordion-2"
                            >
                              <div className="accordion-inner">
                                {this.state.errorMessage !== "" ? (
                                  <div
                                    className="example-alert"
                                    style={{ marginBottom: "20px" }}
                                  >
                                    <div className="alert alert-pro alert-danger">
                                      <div className="alert-text">
                                        <h4>Error</h4>
                                        <p>{this.state.errorMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.successMessage !== "" ? (
                                  <div
                                    className="example-alert"
                                    style={{ marginBottom: "20px" }}
                                  >
                                    <div className="alert alert-pro alert-success">
                                      <div className="alert-text">
                                        <h4>Success</h4>
                                        <p>{this.state.successMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                <div style={{ clear: "both" }}></div>
                                {this.state.servicesData.length > 0 ? (
                                  <>
                                    <form
                                      method="post"
                                      encType="multipart/form-data"
                                    >
                                      <div className="col-lg-12 col-md-12">
                                        <ul className="data-list is-compact">
                                          <li
                                            className="data-item"
                                            style={{
                                              backgroundColor: "#f5f6fa",
                                            }}
                                          >
                                            <div className="data-col">
                                              <div className="data-label">
                                                Service
                                              </div>
                                              <div
                                                className="data-value"
                                                style={{ width: "48%" }}
                                              >
                                                Select Split
                                              </div>
                                            </div>
                                          </li>
                                          {this.state.servicesData.map(
                                            (didservices, index) => (
                                              <li
                                                className="data-item"
                                                key={index}
                                              >
                                                <div className="data-col">
                                                  <div className="data-label">
                                                    {didservices[1]}
                                                  </div>
                                                  <div
                                                    className="data-value"
                                                    style={{ width: "48%" }}
                                                  >
                                                    <MutextField
                                                      name="agent_split"
                                                      select
                                                      label="Sub Agent Split"
                                                      value={
                                                        this.state.agent_split
                                                      }
                                                      onChange={(e) =>
                                                        this.handleChangeSplit(
                                                          e,
                                                          index
                                                        )
                                                      }
                                                      SelectProps={{
                                                        native: true,
                                                      }}
                                                      helperText="Split is divided in ratio Agent/Sub-agent."
                                                      variant="outlined"
                                                      fullWidth
                                                    >
                                                      <option
                                                        value={`80/20_${didservices[0]}`}
                                                      >
                                                        80/20
                                                      </option>
                                                      <option
                                                        value={`70/30_${didservices[0]}`}
                                                      >
                                                        70/30
                                                      </option>
                                                      <option
                                                        value={`60/40_${didservices[0]}`}
                                                      >
                                                        60/40
                                                      </option>
                                                      <option
                                                        value={`50/50_${didservices[0]}`}
                                                      >
                                                        50/50
                                                      </option>
                                                      <option
                                                        value={`40/60_${didservices[0]}`}
                                                      >
                                                        40/60
                                                      </option>
                                                      <option
                                                        value={`30/70_${didservices[0]}`}
                                                      >
                                                        30/70
                                                      </option>
                                                      <option
                                                        value={`20/80_${didservices[0]}`}
                                                      >
                                                        20/80
                                                      </option>
                                                    </MutextField>
                                                  </div>
                                                </div>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </div>
                                      <div className="form-group text-right col-md-12">
                                        <button
                                          className="btn btn-lg btn-primary"
                                          type="button"
                                          disabled={this.state.disabled}
                                          onClick={this.formSplitUpdate}
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </form>
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div style={{ clear: "both" }}></div>
                        <hr />
                      </div>

                      {/* </form> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* EDIT MODAL End */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Agents);
