import React from "react";
import MUIDataTable from "mui-datatables";

import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";

import { THEME_COLOR } from "./../../config/config";

const theme = createMuiTheme({
  shadows: Array(5).fill("none"),
  root: {
    "& MuiInputLabel-outlined": {
      zIndex: 0,
    },
    "& MuiTableHead": {
      zIndex: 0,
    },
  },
  overrides: {
    MUIDataTableHeadCell: {
      root: {
        zIndex: 0,
      },
      fixedHeader: {
        zIndex: 0,
      },
    },
  },
  MuiFormLabel: {
    zIndex: 0,
  },
  MuiTableHead: { zIndex: 0 },
  palette: {
    primary: {
      main: THEME_COLOR, //your color
    },
  },
});

export default function DataTable(props) {
  let responsive = "vertical";
  let tableBodyHeight = "100%";
  const options = {
    filter: true,
    responsive,
    tableBodyHeight,
    selectableRows: false,
    rowsPerPage: 50,
    rowsPerPageOptions: [25, 50, 100],
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={{ border: "1px solid #e5e9f2" }}>
        <MUIDataTable
          title={props.title}
          data={props.tableData}
          columns={props.columns}
          options={options}
        />
      </div>
    </ThemeProvider>
  );
}
