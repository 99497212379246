import React, { Component, Fragment } from "react";
import {
  AppBar,
  Button,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { LOGO_VERIFY } from "../../../config/config";

import HelperClass from "../../../config/helperClass";
import { tableLoader } from "../../ContentLoaders/table_loader";
import Skeleton from "@material-ui/lab/Skeleton";
import { Helmet, HelmetProvider } from "react-helmet-async";

import MyCustomTextField from "../../../components/InputFields/TextField";
import MyCustomRadioField from "../../../components/InputFields/RadioField";
import MyCustomCheckBoxField from "../../../components/InputFields/CheckBox";
import MyCustomButton from "../../../components/InputFields/Button";

import "../ClientRegister/registration_step.css";

import { registerFccUsfTaxDetails } from "../../../config/client_api_calls";



const auth = new HelperClass();
export class Step3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      successMessage: "",
      disabled: props.jsonData.step_3.sub_steps[0].button_fields.continue.disabled,
    };
  }

  componentDidMount(){
    const {
      keys,
      values,
    } = this.props.values;

    let tax_exempt = values[keys.tax_exempt];

    this.setState({
      [keys.usf_registration_number]:values[keys.usf_registration_number],
      [keys.usf_registration_name]:values[keys.usf_registration_name],
      [keys.fein_no]:values[keys.fein_no],
      [keys.fcc_499_filer_id_no]:values[keys.fcc_499_filer_id_no],
      [keys.fcc_499_filer_id_name]:values[keys.fcc_499_filer_id_name],
      [keys.tax_exempt]:tax_exempt === "yes" ? true : "no" ? false : true,
      [keys.usf_registration_same]:values[keys.usf_registration_same],
      [keys.company_name_for_entity_filer_id]:values[keys.company_name_for_entity_filer_id],
    });
  }

  continue = (data) => {
    //e.preventDefault();
    this.props.nextStep(data);
  };
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  submitForms = (event) => {
    //console.log('submit',event);
    event.preventDefault();

    


    const { jsonData } = this.props;
    const {keys} = this.props.values;

    let usf_registration_number_json = {      
      "required": jsonData.step_3.sub_steps[0].input_fields.usf_registration_number.required,
      "visible": jsonData.step_3.sub_steps[0].input_fields.usf_registration_number.visible  
    };
    let usf_registration_name_json = {      
      "required": jsonData.step_3.sub_steps[0].input_fields.usf_registration_name.required,
      "visible": jsonData.step_3.sub_steps[0].input_fields.usf_registration_name.visible  
    };
    let fein_no_json = {      
      "required": jsonData.step_3.sub_steps[0].input_fields.fein_no.required,
      "visible": jsonData.step_3.sub_steps[0].input_fields.fein_no.visible  
    };
    let fcc_499_filer_id_no_json = {      
      "required": jsonData.step_3.sub_steps[0].input_fields.fcc_499_filer_id_no.required,
      "visible": jsonData.step_3.sub_steps[0].input_fields.fcc_499_filer_id_no.visible  
    };
    let fcc_499_filer_id_name_json = {      
      "required": jsonData.step_3.sub_steps[0].input_fields.fcc_499_filer_id_name.required,
      "visible": jsonData.step_3.sub_steps[0].input_fields.fcc_499_filer_id_name.visible  
    };
    let tax_exempt_json = {      
      "required": jsonData.step_3.sub_steps[0].checkbox_fields.tax_exempt.required,
      "visible": jsonData.step_3.sub_steps[0].checkbox_fields.tax_exempt.visible  
    };
    let usf_registration_same_json = {      
      "required": jsonData.step_3.sub_steps[0].checkbox_fields.usf_registration_same.required,
      "visible": jsonData.step_3.sub_steps[0].checkbox_fields.usf_registration_same.visible  
    };
    let company_name_for_entity_filer_id_json = {      
      "required": jsonData.step_3.sub_steps[0].checkbox_fields.company_name_for_entity_filer_id.required,
      "visible": jsonData.step_3.sub_steps[0].checkbox_fields.company_name_for_entity_filer_id.visible  
    };

    console.log('usf_registration_number_json',usf_registration_number_json, this.state[keys.usf_registration_number]);
    if(usf_registration_number_json.visible && usf_registration_number_json.required && this.state[keys.usf_registration_number] === ""){
      this.setState({
        errorMessage: "Please enter USF Registration Number.",
      });
      return;
    }
    if(usf_registration_same_json.visible && usf_registration_same_json.required && !this.state[keys.usf_registration_same] && this.state[keys.usf_registration_name] === ""){
      this.setState({
        errorMessage: "Please enter USF Registration Name.",
      });
      return;
    }
    let fein_no = "";
    if(fein_no_json.visible && fein_no_json.required){
      if(tax_exempt_json.visible && tax_exempt_json.required){
        if(this.state[keys.tax_exempt]){
          if(this.state[keys.fein_no] === ""){
            this.setState({
              errorMessage: "Please enter FEIN No.",
            });
            return;
          }
          else{
            fein_no = this.state[keys.fein_no];
          }

        }
        
      }  
    }
    if(fcc_499_filer_id_no_json.visible && fcc_499_filer_id_no_json.required){
      if(!this.state[keys.tax_exempt] && this.state[keys.fcc_499_filer_id_no] === ""){
        this.setState({
          errorMessage: "Please enter FCC 499 Filer ID No.",
        });
        return;
      }
      
    }
    if(fcc_499_filer_id_name_json.visible && fcc_499_filer_id_name_json.required){
      if(company_name_for_entity_filer_id_json.visible && company_name_for_entity_filer_id_json.required && !this.state[keys.company_name_for_entity_filer_id] && this.state[keys.fcc_499_filer_id_name] === ""){
        this.setState({
          errorMessage: "Please enter Name of Entity w/499 Filer ID.",
        });
        return;
      }     
    }
    this.setState({ errorMessage: "", successMessage: "", disabled: true });
    const step3_json = {
      keys: keys,
      values: {
        [keys.usf_registration_number]: this.state[keys.usf_registration_number],
        [keys.usf_registration_name]: this.state[keys.usf_registration_name],
        [keys.fein_no]: fein_no,
        [keys.fcc_499_filer_id_no]: this.state[keys.fcc_499_filer_id_no],
        [keys.fcc_499_filer_id_name]: this.state[keys.fcc_499_filer_id_name],
        [keys.tax_exempt]: this.state[keys.tax_exempt],
        [keys.usf_registration_same]: this.state[keys.usf_registration_same],
        [keys.company_name_for_entity_filer_id]: this.state[keys.company_name_for_entity_filer_id],
      },
    };
    console.log('step_3_data', step3_json);
    this.submitFccTaxDetails(step3_json);
    //this.continue(step3_json);

    

  };

  submitFccTaxDetails = async (taxdetails) => {
    const {
      signup_hash,
      steps_completed,
      steps_allowed
    } = this.props;
    //console.log('client_email',this.props);
    if (signup_hash !== "") {
      const {keys} = this.props.values;
      try {
        let response = await registerFccUsfTaxDetails({          
          token: process.env.REACT_APP_API_TOKEN,
          api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
          signup_hash: signup_hash,
          usf_registration_number: taxdetails.values[keys.usf_registration_number],
          usf_registration_name: taxdetails.values[keys.usf_registration_name],
          fein_no: taxdetails.values[keys.fein_no],
          fcc_499_filer_id_no: taxdetails.values[keys.fcc_499_filer_id_no],
          fcc_499_filer_id_name: taxdetails.values[keys.fcc_499_filer_id_name],
          tax_exempt: taxdetails.values[keys.tax_exempt],
        });
        console.log('submitFccTaxDetails',response);
        if (response.data.status === 404 ||
            response.data.status === 422
        ){
          this.setState({
            errorMessage: 'Something went wrong. Please try again',
            successMessage: "",
            disabled: false,            
          });          
        }
        else if(response.data.status === 200 &&
          response.data.message === "success"){
            if(steps_completed === (steps_allowed - 1)){
              // this.setState({
              //   errorMessage: '',
              //   successMessage: "Successfully submitted data.",
              //   disabled: true,            
              // });
              this.props.successHandler();
            }
            else{
              this.continue(taxdetails);
            } 
            
          }
      } catch (err) {
        this.setState({ errorMessage: "", successMessage: "", disabled: false });        
      }
    }

  };

  handleChangeCheck = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
    console.log(name + " " + value);
  };

  modalHideIns = () => {
    window.$("#instructionsModal").modal("hide");
  };

  render() {
    const { jsonData, mStepsUITitles, mStepsUIDesc, steps_completed, steps_allowed } = this.props;
    const {values, keys} = this.props.values;
    

    return (
      <>
        <HelmetProvider>
          <Helmet>
            <link
              rel="stylesheet"
              href="../../assets/ui_latest/css/dashlite.css?ver=3.0.3"
            />
            <link
              id="skin-default"
              rel="stylesheet"
              href="../../assets/ui_latest/csstheme.css?ver=3.0.3"
            />
          </Helmet>
        </HelmetProvider>
        <div className="nk-block nk-block-lg" style={{ width: "100%" }}>
          <div
            className="card card-bordered"
            style={{
              border: "none",
              paddingTop: "2%",
              paddingLeft: "5%",
              paddingRight: "5%",
            }}
          >
            <form
              className="nk-stepper stepper-init is-alter nk-stepper-s1"
              action="#"
              onSubmit={this.submitForms}
              id="stepper-create-profile"
            >
              <div className="row g-0 col-sep col-sep-md col-sep-xl">
                <div className="col-md-4 col-xl-4">
                  <div className="card-inner" id="registration_div">
                    <ul className="nk-stepper-nav nk-stepper-nav-s1 stepper-nav is-vr">
                    <li className={steps_completed === 0 ? "current" : "done"}>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">
                              {mStepsUITitles[0]}
                            </div>
                            <div className="sub-text">
                              {mStepsUIDesc[0]}
                            </div>
                          </div>
                        </div>
                      </li>
                      {
                        steps_allowed >= 2 ? <li className={steps_completed === 1  ? "current" : steps_completed > 1 && steps_completed <= 3 ? "done" : ""}>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">
                            {mStepsUITitles[1]}
                            </div>
                            <div className="sub-text">
                            {mStepsUIDesc[1]}
                            </div>
                          </div>
                        </div>
                      </li> : null
                      }
                      {
                        steps_allowed >= 3 ? <li className={steps_completed === 2  ? "current" : steps_completed > 2 && steps_completed <= 4 ? "done" : ""}>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">
                            {mStepsUITitles[2]}
                            </div>
                            <div className="sub-text">
                            {mStepsUIDesc[2]}
                            </div>
                          </div>
                        </div>
                      </li> : null
                      }
                      {
                        steps_allowed >= 4 ? <li className={steps_completed === 3  ? "current" : steps_completed > 3 && steps_completed <= 5 ? "done" : ""}>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">
                            {mStepsUITitles[3]}
                            </div>
                            <div className="sub-text">
                            {mStepsUIDesc[3]}
                            </div>
                          </div>
                        </div>
                      </li> : null
                      }
                      {
                        steps_allowed >= 5 ? <li className={steps_completed === 4  ? "current" : steps_completed > 4 && steps_completed <= 6 ? "done" : ""}>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">
                            {mStepsUITitles[4]}
                            </div>
                            <div className="sub-text">
                            {mStepsUIDesc[4]}
                            </div>
                          </div>
                        </div>
                      </li> : null
                      }
                      {
                        steps_allowed >= 6 ? <li className={steps_completed === 5  ? "current" : steps_completed > 5 && steps_completed <= 7 ? "done" : ""}>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">
                            {mStepsUITitles[5]}
                            </div>
                            <div className="sub-text">
                            {mStepsUIDesc[5]}
                            </div>
                          </div>
                        </div>
                      </li> : null
                      }
                      {
                        steps_allowed >= 7 ? <li className={steps_completed === 6  ? "current" : steps_completed > 6 && steps_completed <= 8 ? "done" : ""}>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">
                            {mStepsUITitles[6]}
                            </div>
                            <div className="sub-text">
                            {mStepsUIDesc[6]}
                            </div>
                          </div>
                        </div>
                      </li> : null
                      }
                      {steps_allowed >= 8 ? (
                        <li
                          className={
                            steps_completed === 7
                              ? "current"
                              : steps_completed > 7 && steps_completed <= 9
                              ? "done"
                              : ""
                          }
                        >
                          <div className="step-item">
                            <div className="step-text">
                              <div className="lead-text">
                                {mStepsUITitles[7]}
                              </div>
                              <div className="sub-text">{mStepsUIDesc[7]}</div>
                            </div>
                          </div>
                        </li>
                      ) : null}
                      {/* <li>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">Account Password</div>
                            <div className="sub-text">
                              Account password to access the portal
                            </div>
                          </div>
                        </div>
                      </li> */}
                      {/* <li>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">Account Password</div>
                            <div className="sub-text">
                              Account password to access the portal
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">Preview Details</div>
                            <div className="sub-text">Review and Submit</div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">Completed</div>
                            <div className="sub-text">You are done.</div>
                          </div>
                        </div>
                      </li> */}
                    </ul>
                  </div>
                </div>
                <div className="col-md-8 col-xl-8">
                  <div className="card-inner">
                    <div className="nk-stepper-content">
                      <div className="nk-stepper-steps stepper-steps">
                        {this.state.errorMessage !== "" ? (
                          <div
                            className="example-alert"
                            style={{ marginBottom: "20px" }}
                          >
                            <div className="alert alert-pro alert-danger">
                              <div className="alert-text">
                                <h4>Error</h4>
                                <p>{this.state.errorMessage}</p>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div className="nk-stepper-step done"></div>
                        <div className="nk-stepper-step done"></div>
                        <div className="nk-stepper-step active">
                          <h4
                            className="title mb-3"
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {jsonData.step_3.sub_steps[0].title}
                          </h4>
                          <p
                            style={{
                              textAlign: "center",
                              marginLeft: "10%",
                              marginRight: "10%",
                            }}
                          >
                            {jsonData.step_3.sub_steps[0].description}
                          </p>
                          <div className="row g-3">
                            {jsonData.step_3.sub_steps[0].input_fields
                              .usf_registration_number.visible ? (
                              <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                  <MyCustomTextField
                                    data={
                                      jsonData.step_3.sub_steps[0].input_fields
                                        .usf_registration_number
                                    }
                                    onChange={this.handleChangeCheck}
                                    defaultValue={`${
                                      values[keys.usf_registration_number]
                                    }`}
                                  />
                                </div>
                              </div>
                            ) : null}
                            {jsonData.step_3.sub_steps[0].input_fields
                              .usf_registration_name.visible &&
                            !this.state.usf_registration_same ? (
                              <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                  <MyCustomTextField
                                    data={
                                      jsonData.step_3.sub_steps[0].input_fields
                                        .usf_registration_name
                                    }
                                    onChange={this.handleChangeCheck}
                                    defaultValue={`${
                                      values[keys.usf_registration_name]
                                    }`}
                                  />
                                </div>
                              </div>
                            ) : null}
                            {jsonData.step_3.sub_steps[0].checkbox_fields
                              .usf_registration_same.visible ? (
                              <div
                                className="col-12"
                                style={{ marginTop: "1%" }}
                              >
                                <div className="form-group">
                                  <ul className="custom-control-group custom-control-vertical custom-control-stacked w-100">
                                    {jsonData.step_3.sub_steps[0].checkbox_fields.usf_registration_same.data.map(
                                      (result, index) => (
                                        <li
                                          key={`usf_registration_same${index}`}
                                        >
                                          <MyCustomCheckBoxField
                                            data={result}
                                            onChange={this.handleChangeCheck}
                                          />                                          
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </div>
                            ) : null}
                            {jsonData.step_3.sub_steps[0].checkbox_fields
                              .tax_exempt.visible ? (
                              <div className="col-12">
                                <MyCustomCheckBoxField
                                  data={
                                    jsonData.step_3.sub_steps[0].checkbox_fields
                                      .tax_exempt.data[0]
                                  }
                                  onChange={this.handleChangeCheck}
                                  checked={this.state[keys.tax_exempt]}
                                />
                                {/* <label className="form-label">
                                    {
                                      jsonData.step_3.sub_steps[0].checkbox_fields
                                        .tax_exempt.label
                                    }
                                    {jsonData.step_3.sub_steps[0].checkbox_fields
                                      .tax_exempt.required ? (
                                      <sup style={{ color: "red" }}>*</sup>
                                    ) : null}
                                  </label> */}
                                {/* <ul>
                                  {jsonData.step_3.sub_steps[0].checkbox_fields.tax_exempt.data.map(
                                    (result, index) => (
                                      <li key={`tax_exempt${index}`}>
                                        <MyCustomCheckBoxField
                                          data={result}
                                          onChange={this.handleChangeCheck}
                                          checked={this.state[keys.tax_exempt]}
                                        />
                                        <span className="ms-2" />
                                      </li>
                                    )
                                  )}
                                </ul> */}
                              </div>
                            ) : null}
                            {jsonData.step_3.sub_steps[0].input_fields.fein_no
                              .visible && this.state[keys.tax_exempt] ? (
                              <div className="col-12">
                                <div className="form-group">
                                  <MyCustomTextField
                                    data={
                                      jsonData.step_3.sub_steps[0].input_fields
                                        .fein_no
                                    }
                                    onChange={this.handleChangeCheck}
                                    defaultValue={`${values[keys.fein_no]}`}
                                  />
                                </div>
                              </div>
                            ) : null}
                            {jsonData.step_3.sub_steps[0].input_fields
                              .fcc_499_filer_id_no.visible &&
                            !this.state[keys.tax_exempt] ? (
                              <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                  <MyCustomTextField
                                    data={
                                      jsonData.step_3.sub_steps[0].input_fields
                                        .fcc_499_filer_id_no
                                    }
                                    onChange={this.handleChangeCheck}
                                    defaultValue={`${
                                      values[keys.fcc_499_filer_id_no]
                                    }`}
                                  />
                                </div>
                              </div>
                            ) : null}
                            {jsonData.step_3.sub_steps[0].input_fields
                              .fcc_499_filer_id_name.visible &&
                            !this.state[keys.tax_exempt] &&
                            !this.state[
                              keys.company_name_for_entity_filer_id
                            ] ? (
                              <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                  <MyCustomTextField
                                    data={
                                      jsonData.step_3.sub_steps[0].input_fields
                                        .fcc_499_filer_id_name
                                    }
                                    onChange={this.handleChangeCheck}
                                    defaultValue={`${
                                      values[keys.fcc_499_filer_id_name]
                                    }`}
                                  />
                                </div>
                              </div>
                            ) : null}
                            {jsonData.step_3.sub_steps[0].checkbox_fields
                              .company_name_for_entity_filer_id.visible &&
                            !this.state[keys.tax_exempt] ? (
                              <div
                                className="col-12"
                                style={{ marginTop: "1%" }}
                              >
                                <div className="form-group">
                                  <ul className="custom-control-group custom-control-vertical custom-control-stacked w-100">
                                    {jsonData.step_3.sub_steps[0].checkbox_fields.company_name_for_entity_filer_id.data.map(
                                      (result, index) => (
                                        <li
                                          key={`company_name_for_entity_filer_id${index}`}
                                        >
                                          <MyCustomCheckBoxField
                                            data={result}
                                            onChange={this.handleChangeCheck}
                                          />
                                          <span className="ms-2" />
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="nk-stepper-step"></div>
                        <div className="nk-stepper-step"></div>
                        <div className="nk-stepper-step"></div>
                        <div className="nk-stepper-step text-center"></div>
                      </div>
                      <div className="row mt-5 gx-1 gy-1">
                      {jsonData.step_3.sub_steps[0].button_fields.back
                          .visible && steps_completed > 0 ? (
                          <div className="col-lg-6">
                            <MyCustomButton
                              data={
                                jsonData.step_3.sub_steps[0].button_fields.back
                              }
                              onClick={this.back}
                            />
                          </div>
                        ) : null}
                        {jsonData.step_3.sub_steps[0].button_fields.continue
                          .visible && steps_completed < steps_allowed - 1 ? (
                            <div className={steps_completed === 0 ? "col-lg-12" : "col-lg-6"}>
                            <MyCustomButton
                              data={
                                jsonData.step_3.sub_steps[0].button_fields
                                  .continue
                              }
                              //onClick={this.submitForms}
                              disabled={this.state.disabled}
                            />
                          </div>
                        ) : null}
                        {jsonData.step_3.sub_steps[0].button_fields.submit
                          .visible && steps_completed === (steps_allowed - 1)  ? (
                          <div className="col-lg-6">
                            <MyCustomButton
                              data={
                                jsonData.step_3.sub_steps[0].button_fields
                                  .submit
                              }
                              //onClick={this.submitForms}
                              disabled={this.state.disabled}
                            />
                          </div>
                        ) : null}
                      </div>
                      {/* <ul className="nk-stepper-pagination pt-4 gx-4 gy-2 stepper-pagination">                        
                        {jsonData.step_3.sub_steps[0].button_fields.back
                          .visible ? (
                          <li className="step-prev">
                            <MyCustomButton
                              data={
                                jsonData.step_3.sub_steps[0].button_fields.back
                              }
                              onClick={this.back}
                            />
                          </li>
                        ) : null}                        
                        {jsonData.step_3.sub_steps[0].button_fields.continue
                          .visible ? (
                          <li className="step-next">
                            <MyCustomButton
                              data={
                                jsonData.step_3.sub_steps[0].button_fields
                                  .continue
                              }
                              disabled={this.state.disabled}
                              //onClick={this.submitForms}
                            />
                          </li>
                        ) : null}
                      </ul> */}
                      {/* <div
                        className="text-center"
                        style={{ marginTop: "25px" }}
                      >
                        Already have an account ? <a href="">Sign in instead</a>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default Step3;
