export default function TextField(props) {
  return (
    <div className="form-group">
      <label className="form-label">
        {props.data.label}
        {props.data.required ? <sup style={{color:"red"}}>*</sup> : null}
        
      </label>
      <input
        type={props.data.type}
        className={"form-control form-control-lg"}
        id={props.data.id}
        name={props.data.name}
        value={props.value}
        onChange={props.onChange}
        defaultValue={props.defaultValue}
        placeholder={props.data.placeholder}        
        disabled={props.data.disabled}
        required={props.data.required}
      />
    </div>
  );
}
